<template>
  <component-dialog ref="refDialog" dialog-width="medium" :test-id="testId + '-patient-note'">
    <template #title>
      Patientennotiz für
      <span :data-encrypted="currentPatient.firstname" />
      <span :data-encrypted="currentPatient.lastname" />
    </template>

    <template #content>
      <form :data-test="testId + '-patient-note-dialog-form'" @submit.prevent="save">
        <component-editor
          v-model="form.note"
          editor-class="h-52 max-h-[75vh]"
          :encrypted="true"
          :test-id="testId + '-patient-note-dialog-form-note'"
        />
      </form>
    </template>

    <template #actions>
      <component-button
        class="p4umc-primary"
        label="Speichern"
        :disabled="form.processing"
        :test-id="testId + '-patient-note-dialog-save'"
        @click="save()"
      />

      <component-button label="Abbrechen" :test-id="testId + '-patient-note-dialog-close'" @click="close()" />
    </template>

    <template #info-icon>
      <component-info-icon>
        <template #content>
          Zu Ihrem Patienten können Sie beliebige Notizen festhalten. Sie haben überall im MediCheck Zugriff auf die
          Notizen zu Ihrem Patienten.
        </template>
      </component-info-icon>
    </template>
  </component-dialog>
</template>

<script>
  import {ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentEditor from "@components/Editor/Editor.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";

  export default {
    name: "ComponentPatientNoteDialog",

    components: {ComponentInfoIcon, ComponentEditor, ComponentButton, ComponentDialog},

    props: {
      patient: {
        type: Object,
        default: () => {},
      },
    },

    expose: ["open", "close"],

    setup(props, {emit}) {
      const refDialog = ref(null);

      const currentPatient = ref(props.patient);

      const form = useForm({
        note: currentPatient.value?.note,
      });

      watch(currentPatient, (patient) => {
        form.note = patient?.note;
      });

      function open(patient) {
        if (typeof patient !== "undefined") {
          currentPatient.value = patient;
        }
        refDialog.value.open();
      }

      function close() {
        refDialog.value?.close();
      }

      function save() {
        form.patch(route("patients.update", {patient: currentPatient.value.id}), {
          onSuccess: () => {
            close();
          },
        });
      }

      return {
        /** ref */
        refDialog,

        /** const */
        currentPatient,
        form,

        /** function */
        open,
        close,
        save,
      };
    },
  };
</script>
