<template>
  <component-dialog
    ref="refPatientContactsDialog"
    dialog-width="large"
    dialog-type="abort"
    cancel-label="Schließen"
    :test-id="testId + '-patient-attributes'"
  >
    <template #content>
      <patients-patient-contacts-data
        ref="refPatientContactsData"
        within-dialog
        :patient="patient"
        :pending-contact="internalPendingContact"
        :test-id="testId"
      />
    </template>
  </component-dialog>
</template>
<script>
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import {nextTick, ref} from "vue";
  import PatientsPatientContactsData from "@pages/Patients/Components/PatientContactsData.vue";

  export default {
    name: "ComponentPatientContactsDialog",
    components: {
      PatientsPatientContactsData,
      ComponentDialog,
    },
    expose: ["open", "searchInAddressBook"],
    props: {
      patient: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const refPatientContactsDialog = ref(null);
      const refPatientContactsData = ref(null);
      const internalPendingContact = ref(null);

      const open = (pendingContact) => {
        if (typeof pendingContact !== "undefined") {
          internalPendingContact.value = pendingContact;
        }

        refPatientContactsDialog.value.open();
      };

      const searchInAddressBook = (contact, pendingContact) => {
        // set the optional pendingContact (visible in the background of the addressbook)
        if (typeof pendingContact !== "undefined") {
          internalPendingContact.value = pendingContact;
        }
        // open the contacts dialog
        refPatientContactsDialog.value.open();

        // then open the addressbook layer in front of the contacts dialog
        nextTick(() => {
          refPatientContactsData.value.openAddressBook(contact);
        });
      };

      const closeDialog = () => {
        refPatientContactsDialog.value.close();
      };

      return {
        /** ref */
        refPatientContactsDialog,
        refPatientContactsData,

        /** const */
        internalPendingContact,

        /** function */
        closeDialog,
        open,
        searchInAddressBook,
      };
    },
  };
</script>
