<template>
  <patientinput-component-step
    title="Vielen Dank!"
    :back-button="false"
    :forward-button="false"
    :test-id="testId + '-success-patient'"
  >
    <component-alert type="succes" :test-id="testId + '-success-patient-step'">
      Ihre Angaben wurden erfolgreich übermittelt. Sie können dieses Fenster nun schließen.
    </component-alert>

    <section>
      <h2 class="font-bold text-xl mb-3">Was passiert als Nächstes?</h2>

      <component-accordion>
        <component-accordion-item
          title="Erstgespräch"
          init-opened
          :test-id="testId + '-success-patient-step-initial-consultation'"
        >
          <template #icon>
            <div class="h-10 rounded-full border-mcred border-1 aspect-square flex justify-center items-center">
              <img src="@assets/icons/pill.svg" alt="" class="h-10" />
            </div>
          </template>

          <div class="flex flex-col gap-3">
            <p>Bitte vereinbaren Sie für unser erstes Gespräch einen Termin, falls nicht schon geschehen.</p>

            <p>
              Bitte bringen Sie zum Termin alle Medikamente (verordnet, rezeptfrei, pflanzlich, äußerlich anzuwendend,
              Vitamine, Mineralstoffe, Nahrungsergänzungsmittel etc.) mit. Kühlpflichtige Medikamente lassen Sie bitte
              zuhause und notieren einfach den Namen, die Wirkstärke und bis wann das Medikament verwendbar ist.
            </p>

            <p>
              Mehr Informationen und eine praktische Informationsbroschüre können Sie unten per Klick herunterladen.
            </p>

            <a
              href="https://medicheck.pharma4u.de/url-redirect/medicheck-patient-flyer"
              target="_blank"
              class="text-center"
            >
              <component-button
                class="p4umc-primary"
                :test-id="testId + '-success-patient-step-initial-consultation-accordion-item-download-info'"
              >
                Informationsbroschüre herunterladen
              </component-button>
            </a>
          </div>
        </component-accordion-item>

        <component-accordion-item title="Überprüfung" :test-id="testId + '-success-patient-step-review'">
          <template #icon>
            <div class="h-10 rounded-full border-mcred border-1 aspect-square flex justify-center items-center">
              <img src="@assets/icons/pharmacist.svg" alt="" class="h-10" />
            </div>
          </template>

          Wir überprüfen nach dem Erstgespräch sorgfältig alle Ihre Medikamente auf mögliche Risiken. Falls relevante
          Probleme erkannt werden, können wir diese auf Ihren Wunsch hin mit Ihrem Arzt / Ihrer Ärztin besprechen, um
          gemeinsam eine gute Lösung zu finden.
        </component-accordion-item>

        <component-accordion-item
          title="Abschlussgespräch"
          :test-id="testId + '-success-patient-step-initial-final-discussion'"
        >
          <template #icon>
            <div class="h-10 rounded-full border-mcred border-1 aspect-square flex justify-center items-center">
              <img src="@assets/icons/diagnosis.svg" alt="" class="h-10" />
            </div>
          </template>

          Im zweiten Gespräch besprechen wir gemeinsam mit Ihnen in aller Ruhe alle Medikamente und deren richtige
          Anwendung. Wir nehmen uns gern Zeit, Ihre Fragen zu beantworten. Sie erhalten einen aktuellen Medikationsplan
          und wertvolle Empfehlungen für Ihre Gesundheit.
        </component-accordion-item>
      </component-accordion>
    </section>
  </patientinput-component-step>
</template>

<script>
  import ComponentAccordion from "@components/Accordion/Accordion.vue";
  import ComponentAccordionItem from "@components/Accordion/AccordionItem.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";

  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";

  export default {
    name: "PatientinputComponentStepSuccess",

    components: {
      ComponentAccordion,
      ComponentAccordionItem,
      ComponentAlert,
      ComponentButton,
      PatientinputComponentStep,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },
  };
</script>
