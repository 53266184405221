<template>
  <patientinput-component-step title="Fast fertig" :test-id="testId + '-end'">
    <template v-if="form.hasErrors">
      <component-alert type="error" :test-id="testId + '-end-step'">
        <p>Beim Absenden ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.</p>
      </component-alert>
    </template>

    <h2 class="font-semibold text-xl">Wie können wir Sie erreichen?</h2>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <component-input
        v-model="form.contactdata.name"
        type="text"
        autocomplete="name"
        label="Name"
        large
        :test-id="testId + '-end-step-name'"
      />

      <component-input
        v-model="form.contactdata.telephone"
        type="text"
        autocomplete="telephone"
        label="Telefonnummer"
        large
        :test-id="testId + '-end-step-telephone'"
      />

      <component-input
        v-model="form.contactdata.email"
        type="email"
        autocomplete="email"
        label="E-Mail (optional)"
        large
        :test-id="testId + '-end-step-email'"
      />
    </div>

    <template v-if="form.meta.is_patient">
      <component-textarea
        v-model="form.additional"
        large
        label="Möchten Sie sonst noch etwas mitteilen?"
        :maxlength="500"
        :test-id="testId + '-end-step-additional'"
      />
    </template>

    <template v-else>
      <p>Sie können die Checkliste nun absenden.</p>
    </template>

    <template #navigation>
      <component-button
        class="p4umc-primary sm:order-2 flex-1"
        :disabled="!allFieldsValid || form.processing"
        :test-id="testId + '-end-step-processing'"
        @click="$emit('navigate', 'submit')"
      >
        <template v-if="form.processing">
          <div class="flex items-center justify-center">
            <component-spinner class="h-5 w-5" :test-id="testId + '-end-step-processing'" />
          </div>
        </template>

        <template v-else>Absenden</template>
      </component-button>

      <component-button class="flex-1" :test-id="testId + '-end-step-back'" @click="$emit('navigate', '-')">
        Zurück
      </component-button>
    </template>

    <template #bottom>
      <p>Ihre Angaben werden sicher Ende-zu-Ende verschlüsselt übertragen.</p>
    </template>
  </patientinput-component-step>
</template>

<script>
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";

  import PatientinputComponentStep from "@pages/Patientinput/Components/Step.vue";
  import ComponentInput from "../../../../Components/Inputs/Input.vue";
  import {computed} from "vue";

  export default {
    name: "PatientinputComponentStepEnd",

    components: {
      ComponentInput,
      ComponentAlert,
      ComponentSpinner,
      ComponentTextarea,
      PatientinputComponentStep,
      ComponentButton,
    },

    props: {
      form: {
        type: Object,
        required: true,
      },
    },

    emits: ["navigate"],

    setup(props) {
      const allFieldsValid = computed(() => {
        return !!(props.form.contactdata.name && props.form.contactdata.telephone);
      });

      return {
        allFieldsValid,
      };
    },
  };
</script>
