<template>
  <component-card
    title="Dokumente"
    subtitle="Übergreifende Dokumente für diesen Patienten."
    class="rounded-lg border-2 border-gray-200"
    data-test="testId + '-patient-documents-card'"
  >
    <ul class="space-y-2">
      <li>
        <patients-patient-document-row
          attribute-name="releasefromconfidentialityconfirmed"
          :patient="patient"
          :test-id="testId"
        >
          Schweigepflichtentbindung
        </patients-patient-document-row>
      </li>

      <li class="pt-2">
        Einwilligung zur Datennutzung
        <a href="/url-redirect/privacy-consent" target="_blank">(PDF)</a>
      </li>

      <li>
        <patients-patient-document-row attribute-name="datasecurityconfirmed" :patient="patient" :test-id="testId">
          Einwilligung Datenverarbeitung Medikationsanalyse
        </patients-patient-document-row>
      </li>

      <li>
        <patients-patient-document-row
          attribute-name="datasecurityresearchconfirmed"
          :patient="patient"
          :test-id="testId"
        >
          Einwilligung Datennutzung für Forschungszwecke (opt.)
        </patients-patient-document-row>
      </li>

      <li>
        <patients-patient-document-row attribute-name="datasecurityaiconfirmed" :patient="patient" :test-id="testId">
          Einwilligung Datennutzung für Produktverbesserungen und KI-Training (opt.)
        </patients-patient-document-row>
      </li>
    </ul>
  </component-card>
</template>

<script>
  import ComponentCard from "@components/Cards/Card.vue";
  import PatientsPatientDocumentRow from "@pages/Patients/Components/PatientDocumentRow.vue";

  export default {
    name: "PatientsPatientDocuments",

    components: {PatientsPatientDocumentRow, ComponentCard},

    props: {
      patient: {
        type: Object,
        default: () => ({}),
      },
    },

    setup(props) {
      return {};
    },
  };
</script>
