<template>
  <section>
    <h2
      v-if="hasThirdParty('p4u')"
      class="text-2xl font-semibold border-b-1 border-gray-200 pb-6 flex justify-center items-center space-x-4"
    >
      <component-icon class="text-2xl">more_vert</component-icon>
      <span>Nächste Schritte</span>
    </h2>

    <!-- "show" instead of "if" is essential here -->
    <div v-show="hasThirdParty('p4u')" class="mt-6 flex divide-x-2 pb-6 border-b-2">
      <div class="p-4 w-full">
        <h3 class="font-bold text-center">Analyseergebnis</h3>

        <div class="mt-4 flex space-x-6 items-start justify-center">
          <div v-if="record.can_create_record_email" class="text-center w-1/3">
            <component-tooltip tooltip="Ergebnis mit E-Mail an Arzt oder Patient senden">
              <component-icon-button icon="email" class="p4umc-primary text-3xl" @click="openSendMailDialog" />
            </component-tooltip>
            <div class="mt-2">per E-Mail senden</div>
          </div>

          <div class="text-center w-1/3">
            <component-tooltip tooltip="PDF erstellen, speichern oder drucken">
              <component-icon-button icon="print" class="p4umc-primary text-3xl" @click="openPrintPdfDialog()" />
            </component-tooltip>
            <div class="mt-2">PDF / drucken</div>
          </div>

          <div class="text-center w-1/3">
            <component-record-progress
              ref="refRecordProgress"
              :record="record"
              :transfer="transfer"
              :show-easy-com-only-button="true"
              @open:record-case-close-dialog="openRecordCaseCloseDialog"
            />
            <div class="mt-2">Dokumentation</div>
          </div>
        </div>
      </div>

      <div class="p-4 w-full">
        <h3 class="font-bold text-center">Fallfreigabe</h3>

        <div class="mt-4 flex space-x-6 items-start justify-center">
          <div
            v-if="
              sharedata.shared_with_me &&
              sharedata.shared_owners.length &&
              sharedata.shared_owners[0].status !== sharedrecordStatus.revoked
            "
            class="text-center w-1/3"
          >
            <component-tooltip tooltip="Bearbeitung abschließen und an Absender zurücksenden">
              <component-icon-button icon="check" class="p4umc-primary text-3xl" @click="openShareStatusDialog" />
            </component-tooltip>
            <div class="mt-2">Zurücksenden</div>
          </div>
          <div
            v-if="
              (sharedata.shared_with_me && $page.props.can.allow_shared_document_forwarding) ||
              !sharedata.shared_with_me
            "
            class="text-center w-1/3"
          >
            <component-tooltip v-if="sharedata.shared_by_me" tooltip="Variante teilen (wurde bereits geteilt)">
              <component-icon-button icon="share" class="p4umc-primary text-3xl" @click="openShareDialog()" />
            </component-tooltip>

            <component-tooltip v-else tooltip="Variante teilen (wurde bislang noch nicht geteilt)">
              <component-icon-button
                icon="share"
                class="p4umc-flat text-3xl !border-mcred hover:border-mcred"
                @click="openShareDialog()"
              />
            </component-tooltip>
            <div class="mt-2">Variante teilen</div>
          </div>
        </div>
      </div>

      <component-record-send-mail-dialog
        ref="refSendMailDialog"
        :patient="patient"
        :record="record"
        @email-sent="openEmailHasSentDialog"
      />

      <component-record-print-pdf-dialog ref="refPrintPdfDialog" :patient="patient" :record="record" />

      <record-component-email-has-sent-dialog ref="refEmailHasSentDialog" />
    </div>

    <div class="p-4 flex flex-col items-center">
      <template v-if="record.is_pos_record">
        <component-button
          :class="{'p4umc-primary': record.closed === null, 'p4umc-accent': record.closed !== null}"
          :label="record.closed !== null ? 'Dokumente gespeichert' : 'Weiter'"
          @click="triggerRecordProgressEasyCom()"
        />
      </template>

      <template v-else>
        <h3 class="font-bold">Status der Variante</h3>
        <component-record-status :record="record" class="mt-2" inner-class="bottom-8 min-w-24 max-w-32" />
      </template>
    </div>
  </section>
</template>

<script>
  import {computed, inject, ref} from "vue";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentRecordPrintPdfDialog from "@components/Dialogs/RecordPrintPdfDialog.vue";

  import ComponentRecordProgress from "@pages/Records/Components/RecordProgress.vue";
  import ComponentRecordSendMailDialog from "@components/Dialogs/RecordSendMailDialog.vue";
  import ComponentRecordStatus from "@pages/Records/Components/RecordStatus.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import {sharedrecordStatus} from "@pages/Records/Enums/Enums.js";
  import RecordComponentEmailHasSentDialog from "@pages/Records/Components/Result/RecordEmailHasSentDialog.vue";

  export default {
    name: "PagesRecordResultNextSteps",

    components: {
      RecordComponentEmailHasSentDialog,
      ComponentRecordStatus,
      ComponentButton,
      ComponentRecordSendMailDialog,
      ComponentRecordPrintPdfDialog,
      ComponentRecordProgress,
      ComponentIconButton,
      ComponentTooltip,
      ComponentIcon,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
      transfer: {
        type: Object,
        default: null,
      },
      sharedata: {
        type: Object,
        required: true,
      },
    },

    emits: ["open:shareDialog", "open:shareStatusDialog", "open:recordCaseCloseDialog"],

    setup(props, {emit}) {
      const hasThirdParty = inject("$hasThirdParty");
      const refSendMailDialog = ref(null);
      const refPrintPdfDialog = ref(null);
      const refRecordProgress = ref(null);
      const refEmailHasSentDialog = ref(null);

      function openSendMailDialog() {
        refSendMailDialog.value.open();
      }
      function openPrintPdfDialog() {
        refPrintPdfDialog.value.open();
      }

      function openShareStatusDialog() {
        emit("open:shareStatusDialog");
      }

      function openShareDialog() {
        emit("open:shareDialog");
      }

      function openRecordCaseCloseDialog() {
        emit("open:recordCaseCloseDialog");
      }

      function triggerRecordProgressEasyCom() {
        refRecordProgress.value.easyCom();
      }

      const openEmailHasSentDialog = ({email}) => {
        refEmailHasSentDialog.value.open(email);
      };

      return {
        sharedrecordStatus,
        hasThirdParty,
        refSendMailDialog,
        refPrintPdfDialog,
        refRecordProgress,
        refEmailHasSentDialog,
        openSendMailDialog,
        openPrintPdfDialog,
        openShareStatusDialog,
        openShareDialog,
        openRecordCaseCloseDialog,
        triggerRecordProgressEasyCom,
        openEmailHasSentDialog,
      };
    },
  };
</script>
