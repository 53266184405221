<template>
  <component-dialog
    ref="refEditAgainDialog"
    dialog-title="Variante bearbeiten"
    dialog-type="abort"
    :dialog-width="loading ? 'small' : 'medium'"
    @dialog-closed="abortRouter()"
  >
    <template #content>
      <div v-if="loading" class="h-20 flex justify-center items-center">
        <component-spinner class="w-8" />
      </div>

      <template v-else>
        <component-alert v-if="!!record.closed" type="error">
          Sie haben diese Variante bereits als abgeschlossen gekennzeichnet.
        </component-alert>

        <!-- Record not shared to others and not shared with user -->
        <template v-if="allowOverwriteRecord">
          <p>Sie haben zwei Möglichkeiten, diese Variante zu bearbeiten:</p>

          <ol class="list-decimal pl-5 mt-4">
            <li>
              <p>
                <strong class="font-bold">
                  Neue Variante für
                  <div class="inline-block mx-0.5 text-gray-900 text-lg">
                    <span class="font-semibold" :data-encrypted="patient.firstname" />
                    <span class="font-semibold" :data-encrypted="patient.lastname" />
                  </div>
                  anlegen
                </strong>
              </p>

              <p class="leading-snug">
                Sie erstellen eine neue zusätzliche Variante. Die aktuelle Variante inkl. bestehendem Analyseergebnis
                bleibt gespeichert und Ihre Bearbeitung wird in die neue Variante übernommen.
              </p>

              <p class="mt-2">
                <component-button class="p4umc-primary" label="Neue Variante anlegen" @click="duplicateRecord()" />
              </p>
            </li>

            <li class="mt-8">
              <p>
                <strong class="font-bold">
                  Aktuelle Variante für
                  <div class="inline-block mx-0.5 text-gray-900 text-lg">
                    <span class="font-semibold" :data-encrypted="patient.firstname" />
                    <span class="font-semibold" :data-encrypted="patient.lastname" />
                  </div>
                  überschreiben
                </strong>
              </p>

              <p class="leading-snug">
                Dadurch haben Sie die Möglichkeit, die aktuelle Variante anzupassen. Das Analyseergebnis der bestehenden
                Variante inkl. Ihrer Bearbeitung steht Ihnen anschließend nicht mehr zur Verfügung.
              </p>

              <p class="mt-2">
                <component-button
                  class="p4umc-primary"
                  label="Analyseergebnis überschreiben"
                  @click="openConfirmRecordOverwriteDialog()"
                />
              </p>
            </li>
          </ol>
        </template>

        <!-- Record shared to others or shared with current user -->
        <template v-else>
          <p v-if="sharedata.cannot_edit_again_reason === 'sharedByMe'">
            Da Sie diese Variante bereits über die Fallfreigabe mit einer anderen Person geteilt haben, können Sie nur
            eine neue Variante anlegen. Die aktuelle Variante inkl. bestehendem Analyseergebnis bleibt gespeichert und
            kann jederzeit erneut aufgerufen werden.
          </p>

          <p v-else-if="sharedata.cannot_edit_again_reason === sharedrecordStatus.active">
            Da diese Variante mit Ihnen geteilt wurde, können Sie nur eine neue Variante anlegen. Die aktuelle Variante
            inkl. bestehendem Analyseergebnis bleibt gespeichert und kann jederzeit erneut aufgerufen werden.
          </p>

          <p v-else-if="sharedata.cannot_edit_again_reason === sharedrecordStatus.completed">
            Da Sie diese geteilte Variante bereits an den Absender zurückgesendet haben, können Sie nur eine neue
            Variante anlegen. Die aktuelle Variante inkl. bestehendem Analyseergebnis wird gespeichert und kann
            jederzeit über die Patientenakte aufgerufen werden.
          </p>

          <p v-else>
            Sie können eine neue Variante anlegen, um diese weiterzubearbeiten. Die aktuelle Variante inkl. bestehendem
            Analyseergebnis wird gespeichert und kann jederzeit erneut aufgerufen werden.
          </p>
        </template>
      </template>
    </template>

    <template #actions>
      <component-button
        v-if="!allowOverwriteRecord"
        class="p4umc-primary"
        label="Neue Variante anlegen"
        :disabled="loading"
        @click="duplicateRecord()"
      />
      <component-button label="Abbrechen" @click="close()" />
    </template>
  </component-dialog>

  <component-dialog
    ref="refConfirmRecordOverwriteDialog"
    dialog-title="Analyseergebnis überschreiben?"
    @primary-button-clicked="overwriteRecord()"
    @cancel-button-clicked="cancelOverwriteRecord()"
  >
    <template #content>Dabei wird das Analyseergebnis der aktuellen Variante unwiderruflich gelöscht.</template>
  </component-dialog>
</template>

<script>
  import {inject, onMounted, reactive, ref, toRefs} from "vue";
  import {router} from "@inertiajs/vue3";

  import {sharedrecordStatus} from "@pages/Records/Enums/Enums.js";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "RecordComponentEditAgainDialog",

    components: {ComponentSpinner, ComponentAlert, ComponentButton, ComponentDialog},

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
      sharedata: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const refEditAgainDialog = ref(null);
      const refConfirmRecordOverwriteDialog = ref(null);
      const allowOverwriteRecord = ref(false);
      const broadcast = inject("$broadcast");

      const state = reactive({
        loading: false,
      });

      onMounted(() => {
        if (props.sharedata.cannot_edit_again_reason === null) {
          // not a shared record, so always allow overwrite
          allowOverwriteRecord.value = true;
        }

        if ((props.sharedata.shared_owners ?? []).length === 1) {
          if (props.sharedata.shared_owners[0].copied_record_id !== props.record.id) {
            // this record is already one of my variants from the
            // record which was originally shared with me
            allowOverwriteRecord.value = true;
          }
        }
      });

      const open = () => {
        state.loading = false;
        refEditAgainDialog.value.open();
      };

      const close = () => {
        refEditAgainDialog.value.close();
      };

      const performRecordAction = (action) => {
        state.loading = true;

        router.put(
          route("records.update", {patient: props.patient.id, record: props.record.id}),
          {
            action: action,
          },
          {
            preserveScroll: action === "reset",
            preserveState: false,
            onSuccess: () => {
              broadcast.record.postMessage(props.record.id, {
                action: action, // "duplicate" or "reset"
              });
            },
            onError: () => {
              state.loading = false;
            },
          },
        );
      };

      const duplicateRecord = () => {
        performRecordAction("duplicate");
      };

      const openConfirmRecordOverwriteDialog = () => {
        refConfirmRecordOverwriteDialog.value.open();
        close();
      };

      const overwriteRecord = () => {
        open();
        performRecordAction("reset");
      };

      const cancelOverwriteRecord = () => {
        abortRouter();
        open();
      };

      const abortRouter = () => {
        router.cancel();
      };

      return {
        refEditAgainDialog,
        refConfirmRecordOverwriteDialog,
        ...toRefs(state),
        sharedrecordStatus,
        allowOverwriteRecord,
        open,
        close,
        duplicateRecord,
        openConfirmRecordOverwriteDialog,
        overwriteRecord,
        cancelOverwriteRecord,
        abortRouter,
      };
    },
  };
</script>
