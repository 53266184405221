<template>
  <component-dialog ref="refCryptDialog" dialog-width="medium" :test-id="testId">
    <template #title>Accounts verknüpfen</template>

    <template #content>
      <div class="print:hidden">
        <p class="mt-4">
          Um nach der Buchung des MediCheck Connect Pro Ihren Account über die Warenwirtschaft (MediCheck Connect Core)
          und über die pharma4u (MediCheck Online Pro) zu verknüpfen, rufen Sie den Verknüpfungslink nach erfolgreichem
          Login in den MediCheck Online Pro auf.
        </p>

        <h5 class="text-lg mt-4">Woher bekomme ich den Verknüpfungslink?</h5>

        <ul class="list-decimal mt-4 ml-8">
          <li>Melden Sie sich hier aus Ihrem MediCheck (pharma4u-Account) ab.</li>
          <li>Gehen Sie in Ihre Warenwirtschaft.</li>

          <li>
            Starten Sie über Ihre Warenwirtschaft den MediCheck Connect Core, indem Sie eine bereits existierende
            Medikationsanalyse öffnen.
          </li>

          <li>
            Öffnen Sie rechts oben über das Symbol
            <span class="whitespace-nowrap">
              „
              <component-icon class="relative top-[3px] inline-block">account_circle</component-icon>
              “
            </span>
            das Menü für die persönlichen Einstellungen.
          </li>

          <li>
            Klicken Sie dort auf „Accounts verknüpfen“, um den Verknüpfungsprozess zu starten. Im Anschluss erhalten Sie
            den hier benötigten Verknüpfungslink.
          </li>

          <li>
            Kopieren Sie den Verknüpfungslink und melden Sie sich aus dem MediCheck Connect Core in der Warenwirtschaft
            ab.
          </li>
        </ul>

        <h5 class="text-lg mt-4">Was mache ich mit dem Verknüpfungslink?</h5>

        <!-- NOTE: <ol> "start" attribute!-->
        <ol start="6" class="list-disc mt-4 ml-8">
          <li>Melden Sie sich über Ihren pharma4u-Zugang erneut hier im MediCheck an.</li>

          <li>
            Fügen Sie den Verknüpfungslink oben in Ihre Browser-Zeile ein und bestätigen Sie mit der „Enter“-Taste.
          </li>
        </ol>

        <p class="mt-4">
          Achten Sie darauf, dass Sie sich hier (in diesem Browser-Fenster) aus Ihrem MediCheck (pharma4u-Account) ab-
          und wieder neu anmelden, bevor Sie den Verknüpfungslink hier im Browser eingeben, wenn Sie diese Seite hier
          auf dem gleichen Computer geöffnet haben, auf dem auch Ihre Warenwirtschaftsanwendung läuft.
        </p>

        <div class="-mb-4 mt-4 flex justify-between">
          <component-button
            class="p4umc-flat"
            label="zurück"
            :test-id="testId + '-dialog-back'"
            @click="posIntegration.alreadyUsingPos = null"
          />

          <component-button
            class="p4umc-primary"
            label="Abmelden"
            :test-id="testId + '-dialog-logout'"
            @click="logout"
          />
        </div>
      </div>

      <layout-print-recovery-code :recovery-code="privacy.getRecoveryCode()" :test-id="testId + '-dialog'" />
    </template>

    <template #actions />
  </component-dialog>
</template>

<script>
  import {ref} from "vue";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  import LayoutPrintRecoveryCode from "@components/Layout/Components/PrintRecoveryCode.vue";

  export default {
    name: "CryptDialogsPos",

    components: {ComponentIcon, ComponentButton, ComponentDialog, LayoutPrintRecoveryCode},

    props: {
      privacy: {
        type: Object,
        required: true,
        default: () => {},
      },
      posIntegration: {
        type: Object,
        required: true,
        default: () => {},
      },
      logout: {
        type: Function,
        required: true,
        default: () => {},
      },
    },

    setup() {
      const refCryptDialog = ref(null);

      const open = () => {
        refCryptDialog.value.open();
      };

      const close = () => {
        refCryptDialog.value.close();
      };

      return {
        /** ref */
        refCryptDialog,

        /** function */
        open,
        close,
      };
    },
  };
</script>
