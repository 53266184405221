<template>
  <ul>
    <li class="flex items-baseline space-x-4">
      <component-dosage-input
        :id="'specialDosage' + dataHashId"
        :key="'specialDosage' + dataHashId"
        :name="'specialDosage' + dataHashId"
        :value="currentValue"
        :as-fraction="currentValueAsFraction"
        @input="handleInput"
        @is-invalid="$emit('isInvalid', $event)"
      />

      <div class="pt-2 text-sm truncate">
        {{ unit }}
      </div>

      <template v-if="type === 'specialWeeks'">
        <component-select v-model="weekday" class="!w-28" :options="daysNumber" @change="handleWeekDay" />
      </template>

      <template v-if="type === 'onceAtMonth'">
        <div class="w-16">
          <component-input
            v-model="dayOfMonth"
            :validation="validDayOfMonth"
            input-alignment="text-center"
            @input="handleDayOfMonth"
          />
        </div>

        <div class="text-sm">. Tag des Monats</div>
      </template>

      <template v-if="!['multipleHours', 'specialWeeks', 'onceAtMonth', 'specialMonth'].includes(type)">
        <component-time-select :value="time" @selected-time="handleTimeInput" />
      </template>
    </li>
  </ul>
</template>

<script>
  import {computed, nextTick, onBeforeMount, reactive, ref} from "vue";

  import ComponentDosageInput from "@components/Inputs/DosageInput.vue";
  import ComponentTimeSelect from "@components/TimeSelect.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentInput from "@components/Inputs/Input.vue";

  import {daysNumber} from "@enums/medicationEnums.js";

  export default {
    name: "MedicationComponentDetailDialogSingleDosageInput",

    components: {ComponentInput, ComponentSelect, ComponentTimeSelect, ComponentDosageInput},

    props: {
      dataHashId: {
        type: String,
        required: true,
        default: "",
      },
      dosageForm: {
        type: Object,
        required: true,
        default: () => {},
      },
      type: {
        type: String,
        required: true,
        default: "",
      },
      intervalValue: {
        type: [String, Number],
        required: true,
        default: 0,
      },
    },

    emits: ["isInvalid", "change", "isValid"],

    setup(props, {emit}) {
      const currentValue = ref(null);
      const currentValueAsFraction = ref(null);
      const unit = ref(null);
      const time = ref(null);
      const weekday = ref(null);
      const dayOfMonth = ref(null);

      const dataList = reactive([
        {
          dayofmonth: null,
          month: null,
          time: null,
          value: 1,
          value_as_fraction: false,
          weekday: null,
        },
      ]);

      const validDayOfMonth = computed(() => {
        if (
          dayOfMonth.value === null ||
          dayOfMonth.value === "" ||
          (!isNaN(Number.parseInt(dayOfMonth.value)) && dayOfMonth.value >= 1 && dayOfMonth.value <= 31)
        ) {
          return null;
        } else {
          return "Bitte eine Zahl eingeben!";
        }
      });

      onBeforeMount(() => {
        if (props.dosageForm.dosagealternative && props.dosageForm.dosagealternative.dosagealternativedatas) {
          dataList[0] = props.dosageForm.dosagealternative.dosagealternativedatas[0];
        }

        unit.value = props.dosageForm.selected_unit ?? "";
        time.value = dataList[0].time ?? null;
        currentValue.value = dataList[0].value ?? null;
        currentValueAsFraction.value = dataList[0].value_as_fraction ?? false;
        weekday.value = dataList[0].weekday ?? null;
        dayOfMonth.value = dataList[0].dayofmonth ?? null;

        // immediately emit change event (the null values, which are
        // represented with "1", will always be stored)
        nextTick(() => {
          emit("change", {newData: dataList});
        });
      });

      const handleInput = ({value, as_fraction, id}) => {
        dataList[0].value = value;
        dataList[0].value_as_fraction = as_fraction;

        emit("isValid", id);
        emit("change", {newData: dataList});
      };

      const handleWeekDay = () => {
        dataList[0].weekday = weekday.value;

        emit("change", {newData: dataList});
      };

      const handleDayOfMonth = () => {
        dataList[0].dayofmonth = dayOfMonth.value;

        emit("change", {newData: dataList});
      };

      const handleTimeInput = (newTime) => {
        dataList[0].time = newTime.time;

        emit("change", {newData: dataList});
      };

      return {
        daysNumber,
        currentValue,
        currentValueAsFraction,
        unit,
        time,
        weekday,
        dayOfMonth,
        validDayOfMonth,
        handleInput,
        handleWeekDay,
        handleDayOfMonth,
        handleTimeInput,
      };
    },
  };
</script>
