<template>
  <div
    :class="{'border-b': !showComplianceData}"
    class="accordion-header bg-[linear-gradient(0deg,#fff,#f0f0f0)] flex w-full py-3 text-base text-gray-800 text-left bg-white border-gray-200 cursor-pointer"
    @click="showComplianceData = !showComplianceData"
  >
    <div class="flex items-center font-medium">
      <component-icon v-if="showComplianceData" class="text-gray-900 text-xl">arrow_drop_up</component-icon>
      <component-icon v-else class="text-gray-900 text-xl">arrow_drop_down</component-icon>

      <div class="flex space-x-1 items-center">
        <span>Patientenangaben</span>
        <component-info-icon placement="right">
          <template #content>
            <p>
              z. B. abweichendes Verhalten, Unzufriedenheit etc.
              <br>
              (für automatisierte Adhärenz-Prüfung)
            </p>
          </template>
        </component-info-icon>
      </div>
    </div>
  </div>

  <div v-if="showComplianceData" class="grid grid-cols-12 gap-x-2 gap-y-4">
    <!-- medication fields -->
    <div class="col-span-4 md:col-span-2">
      <component-select
        :id="'usage' + deviantMedicationForm.id"
        ref="usage"
        v-model="deviantMedicationForm.usage"
        label="Art"
        :name="'usage' + deviantMedicationForm.id"
        :disabled="!isEditable"
        :nullable="true"
        :options="medicationUsage"
        @change="updateUsage"
      />
    </div>

    <div class="col-span-4 md:col-span-2 mx-auto">
      <p class="text-gray-500 text-sm">Dosierung</p>
      <div class="flex gap-1 mt-1">
        <component-dosage-input
          :id="'complianceDataDosageMorning' + deviantMedicationForm.id"
          :name="'complianceDataDosageMorning' + deviantMedicationForm.id"
          :value="deviantMedicationForm.dosage?.morning"
          :as-fraction="deviantMedicationForm.dosage?.morning_as_fraction"
          :disabled="!isEditable"
          @input="updateDosage($event, 'morning')"
          @is-invalid="handleInvalidDosage"
        />

        <component-dosage-input
          :id="'complianceDataDosageNoon' + deviantMedicationForm.id"
          :name="'complianceDataDosageNoon' + deviantMedicationForm.id"
          :value="deviantMedicationForm.dosage?.noon"
          :as-fraction="deviantMedicationForm.dosage?.noon_as_fraction"
          :disabled="!isEditable"
          @input="updateDosage($event, 'noon')"
          @is-invalid="handleInvalidDosage"
        />

        <component-dosage-input
          :id="'complianceDataDosageEvening' + deviantMedicationForm.id"
          :name="'complianceDataDosageEvening' + deviantMedicationForm.id"
          :value="deviantMedicationForm.dosage?.evening"
          :as-fraction="deviantMedicationForm.dosage?.evening_as_fraction"
          :disabled="!isEditable"
          @input="updateDosage($event, 'evening')"
          @is-invalid="handleInvalidDosage"
        />

        <component-dosage-input
          :id="'complianceDataDosageNight' + deviantMedicationForm.id"
          :name="'complianceDataDosageNight' + deviantMedicationForm.id"
          :value="deviantMedicationForm.dosage?.night"
          :as-fraction="deviantMedicationForm.dosage?.night_as_fraction"
          :disabled="!isEditable"
          @input="updateDosage($event, 'night')"
          @is-invalid="handleInvalidDosage"
        />
      </div>
    </div>

    <div class="col-span-4 md:col-span-2">
      <component-select
        :id="'dosageUnit' + deviantMedicationForm.id"
        ref="unitfield"
        v-model="currentDosageUnit"
        label="Einheit"
        :data-hash-id="deviantMedicationForm.id"
        :disabled="!isEditable"
        :options="currentDosageUnitList"
        key-value="keyDoe"
        key-name="unit"
        return-type="object"
        @change="updateDosageUnit"
      />
    </div>

    <div class="col-span-6 md:col-span-3">
      <component-select
        :id="'meal' + deviantMedicationForm.id"
        ref="meal"
        v-model="deviantMedicationForm.dosage.meal"
        :name="'meal' + deviantMedicationForm.id"
        :disabled="!isEditable"
        label="Mahlzeit"
        key-name="name"
        :nullable="true"
        :options="medicationMeal"
        @change="updateMeal"
      />
    </div>

    <div class="col-span-6 md:col-span-3">
      <medication-component-indication-display
        show-label
        :indications="deviantMedicationForm.indications"
        :indications-unknown="deviantMedicationForm.indication_unknown"
        :is-active="isEditable"
        :is-discontinued="false"
        :disabled="!isEditable"
        @open-dialog="openIndicationDialog"
      />

      <template v-if="isEditable">
        <medication-component-indication-dialog
          ref="refIndicationMenuDialog"
          :is-detail-dialog="true"
          @form-change="changeForm"
          @close="resetDeviationIndication"
        />
      </template>
    </div>
    <!-- end medication fields -->

    <!-- alternative dosage -->
    <div class="col-start-1 col-span-12 md:col-span-3 lg:col-span-2 h-9 flex items-center">
      <component-checkbox v-model="isAlternativeDosage" :disabled="!isEditable" color="accent">
        Sonderdosierung
      </component-checkbox>
    </div>

    <div class="-mt-4 md:mt-0 col-span-12 md:col-span-9 lg:col-span-10">
      <medication-component-detail-dialog-alternative-dosage-data
        v-show="isAlternativeDosage"
        ref="refAlternativeDosage"
        class="ml-6 md:ml-0"
        :medication-form="deviantMedicationForm"
        :is-editable="isEditable"
        @is-invalid="handleInvalidAlternativeDosageEvent"
        @is-valid="handleValidAlternativeDosageEvent"
        @alternative-dosage-ids-changed="handleAlternativeDosageIdsChanged"
        @change="handleAlternativeDosageTypeChange"
        @init-type="handleAlternativeDosageTypeChange"
        @dosage-change="handleDosageChange"
      />
    </div>
    <!-- end alternative dosage -->

    <!-- satisfaction -->
    <div class="col-start-1 col-span-6 md:col-span-3">
      <component-select
        id="satisfaction"
        ref="satisfaction"
        v-model="additionalInformationForm.satisfaction"
        label="Unzufrieden aufgrund von"
        name="satisfaction"
        :nullable="true"
        :disabled="!isEditable"
        @change="handleSatisfaction"
      >
        <option value="uaw">Unzufrieden wegen UAW / NW</option>
        <option value="effectiveness">Unzufrieden mit Wirksamkeit</option>
        <option value="handling">Unzufrieden mit Handhabung</option>
      </component-select>
    </div>

    <div class="col-span-6 md:col-span-6">
      <p class="text-gray-500 text-sm truncate mb-1">Details zur Unzufriedenheit</p>

      <medication-component-detail-dialog-satisfaction-field
        ref="satisfactionComment"
        :clearable="true"
        :key-fam="medicationForm.value.abdata_key_fam"
        :row-id="1"
        :additional-information-form="additionalInformationForm"
        :disabled="!isEditable"
        @update-satisfaction-value="handleSatisfactionComment"
        @add-symptom="addSymptom"
      />
    </div>
    <!-- end satisfaction -->

    <!-- side effects -->
    <div class="col-start-1 col-span-9">
      <label class="text-gray-500 text-sm">Lagerung / Verfall / Anwendung</label>
      <div class="mt-2 grid gap-x-2 gap-y-4 grid-cols-3">
        <component-checkbox
          v-model="storage"
          color="accent"
          :disabled="!isEditable"
          @change="handleSideEffects($event, 'storage')"
        >
          falsche Lagerung
        </component-checkbox>

        <component-checkbox
          v-model="expired"
          color="accent"
          :disabled="!isEditable"
          @change="handleSideEffects($event, 'expired')"
        >
          FAM verfallen
        </component-checkbox>

        <component-checkbox
          v-model="wrongUse"
          color="accent"
          :disabled="!isEditable"
          @change="handleSideEffects($event, 'wronguse')"
        >
          falsche Anwendung
        </component-checkbox>
      </div>
    </div>
    <!-- end side effects -->

    <!-- dosage Info -->
    <div class="col-start-1 col-span-9">
      <component-textarea
        v-model="deviantMedicationForm.dosage.info"
        label="Hinweise zur abweichenden Anwendung (durch Patient)"
        maxlength="80"
        rows="2"
        :disabled="!isEditable"
        :privacy-guard="privacyErrors.info ?? undefined"
        @input="updateComplianceInfo"
      />
    </div>
    <!-- end dosage info -->

    <!-- compliance -->
    <div class="col-start-1 col-span-12 flex space-x-2 items-center">
      <component-switch
        v-model="additionalInformationForm.compliance"
        color="primary"
        :disabled="!isEditable"
        @input="updateNonCompliance"
      />

      <template v-if="additionalInformationForm.compliance === true">
        <span :class="{'text-gray-500': !isEditable}">
          Relevanz: im Analyseergebnis nicht berücksichtigen (nicht relevant)
        </span>
      </template>

      <template v-else>
        <span :class="{'text-gray-500': !isEditable}">Relevanz: im Analyseergebnis berücksichtigen</span>
      </template>
    </div>
    <!-- end compliance -->
  </div>
</template>

<script>
  import {computed, onBeforeMount, ref, watch} from "vue";
  import {usePage} from "@inertiajs/vue3";
  import {clone, cloneDeep, isEqual, pick} from "lodash";

  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentDosageInput from "@components/Inputs/DosageInput.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentSwitch from "@components/Switch.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";

  import {complianceCheck} from "@pages/Records/Helper/complianceCheck.js";
  import * as dosageHelper from "@pages/Records/Helper/dosageHelper.js";
  import {medicationMeal, medicationUsage} from "@enums/medicationEnums.js";
  import {checkDeviantMedicationDetailDialog} from "@pages/Records/Components/Sections/MedicationBar/Utils/medicationConsistencyCheck.js";

  import MedicationComponentDetailDialogAlternativeDosageData from "@pages/Records/Components/Sections/MedicationBar/Components/DetailDialog/Components/AlternativeDosageData.vue";
  import MedicationComponentDetailDialogSatisfactionField from "@pages/Records/Components/Sections/MedicationBar/Components/DetailDialog/Components/SatisfactionField.vue";
  import MedicationComponentIndicationDialog from "@pages/Records/Components/Sections/MedicationBar/Components/Indications/IndicationDialog.vue";
  import MedicationComponentIndicationDisplay from "@pages/Records/Components/Sections/MedicationBar/Components/Indications/IndicationDisplay.vue";
  import {usePrivacyGuard} from "@utils/Helpers/PrivacyGuard.js";

  export default {
    name: "MedicationComponentDetailDialogNonComplianceData",

    components: {
      ComponentInfoIcon,
      ComponentCheckbox,
      ComponentDosageInput,
      ComponentIcon,
      ComponentSelect,
      ComponentSwitch,
      ComponentTextarea,
      MedicationComponentDetailDialogAlternativeDosageData,
      MedicationComponentDetailDialogSatisfactionField,
      MedicationComponentIndicationDialog,
      MedicationComponentIndicationDisplay,
    },

    props: {
      isEditable: {
        type: Boolean,
        default: false,
      },
      medicationForm: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    emits: ["changeForm", "valid", "invalid", "save"],

    setup(props, {emit}) {
      const alternativeDosageId = "deviantMedicationFormAlternativeDosageValidatorId";

      const page = usePage();
      const {validatePrivacyObject} = usePrivacyGuard();

      const refAlternativeDosage = ref(null);
      const refIndicationMenuDialog = ref(null);

      const showComplianceData = ref(false);
      const isAlternativeDosage = ref(false);
      const invalidDosages = ref([]);

      const storage = ref(false);
      const expired = ref(false);
      const wrongUse = ref(false);

      const deviantMedicationForm = ref(props.medicationForm.value.deviantmedication);
      const additionalInformationForm = ref(props.medicationForm.value.additionalinformation);

      const currentDosageUnit = ref(null);

      const currentDosageUnitList = computed(() => dosageHelper.getDosageUnitList(props.medicationForm.value.core));

      watch(
        () => isAlternativeDosage.value,
        (newValue) => {
          if (newValue === false) {
            emit("valid", alternativeDosageId);
            restoreDefaultDosage();
          } else {
            if (refAlternativeDosage.value) {
              refAlternativeDosage.value.clearDefaultDosage();
            }
          }
        },
      );

      watch(
        () => [
          props.medicationForm.value.dosage,
          deviantMedicationForm.value,
          additionalInformationForm.value.satisfaction,
          additionalInformationForm.value.sideeffects,
        ],
        () => {
          const sortedDeviantMedication = cloneDeep(deviantMedicationForm.value);
          const sortedPropsMedication = cloneDeep(props.medicationForm.value);

          const checkIndicationUnknown =
            deviantMedicationForm.value.indication_unknown === null ||
            deviantMedicationForm.value.indication_unknown === 0 ||
            deviantMedicationForm.value.indication_unknown === props.medicationForm.value.indication_unknown;

          const checkIndications =
            sortedDeviantMedication.indications.length === 0 ||
            isEqual(
              sortedDeviantMedication.indications.map((indication) => indication.name).sort(),
              sortedPropsMedication.indications.map((indication) => indication.name).sort(),
            );

          const checkUsage = isEqualOrNull(deviantMedicationForm.value.usage, props.medicationForm.value.usage);

          const checkMeal = isEqualOrNull(
            deviantMedicationForm.value.dosage.meal,
            props.medicationForm.value.dosage.meal,
          );

          const checkDosageAmount = checkDosage(deviantMedicationForm.value.dosage, props.medicationForm.value.dosage);

          const checkInfoUnitKeyDoe = comparePickedObjects(
            pick(deviantMedicationForm.value.dosage, ["selected_unit", "selected_unit_key_doe", "info"]),
            pick(props.medicationForm.value.dosage, ["selected_unit", "selected_unit_key_doe", "info"]),
          );

          const checkSatisfaction = additionalInformationForm.value.satisfaction === null;

          const checkSideEffects =
            additionalInformationForm.value.sideeffects === null ||
            additionalInformationForm.value.sideeffects.length === 0;

          if (
            additionalInformationForm.value.compliance !== false &&
            (!checkIndications ||
              !checkIndicationUnknown ||
              !checkUsage ||
              !checkMeal ||
              !checkInfoUnitKeyDoe ||
              !checkDosageAmount ||
              !checkSatisfaction ||
              !checkSideEffects)
          ) {
            additionalInformationForm.value.compliance = false;
            updateNonCompliance();
          } else if (
            additionalInformationForm.value.compliance !== true &&
            checkIndications &&
            checkIndicationUnknown &&
            checkUsage &&
            checkMeal &&
            checkInfoUnitKeyDoe &&
            checkDosageAmount &&
            checkSatisfaction &&
            checkSideEffects
          ) {
            additionalInformationForm.value.compliance = true;
            updateNonCompliance();
          }
        },
        {deep: true},
      );

      watch(
        () => props.medicationForm.value.additionalinformation,
        () => {
          additionalInformationForm.value = props.medicationForm.value.additionalinformation;
        },
      );

      watch(
        () => props.medicationForm.value.deviantmedication,
        () => {
          if (
            deviantMedicationForm.value.dosage.selected_unit === null &&
            deviantMedicationForm.value.dosage.selected_unit_key_doe === null &&
            currentDosageUnitList.value.length === 1
          ) {
            deviantMedicationForm.value.dosage.selected_unit = clone(props.medicationForm.value.dosage.selected_unit);

            deviantMedicationForm.value.dosage.selected_unit_key_doe = clone(
              props.medicationForm.value.dosage.selected_unit_key_doe,
            );
          }
        },
        {deep: true},
      );

      onBeforeMount(() => {
        if (
          deviantMedicationForm.value.dosage &&
          deviantMedicationForm.value.dosage.dosagealternative !== null &&
          deviantMedicationForm.value.dosage.dosagealternative?.id !== null
        ) {
          isAlternativeDosage.value = true;
        }

        if (deviantMedicationForm.value && deviantMedicationForm.value.id === null) {
          deviantMedicationForm.value.usage = null;
        }

        if (
          additionalInformationForm.value.sideeffects !== null &&
          additionalInformationForm.value.sideeffects.length > 0
        ) {
          storage.value = additionalInformationForm.value.sideeffects.includes("storage");
          expired.value = additionalInformationForm.value.sideeffects.includes("expired");
          wrongUse.value = additionalInformationForm.value.sideeffects.includes("wronguse");
        }

        showComplianceData.value = checkDeviantMedicationDetailDialog(props.medicationForm.value);

        currentDosageUnit.value = dosageHelper.getCurrentDosageUnit(deviantMedicationForm.value.dosage);
      });

      function comparePickedObjects(obj1, obj2) {
        for (const key in obj1) {
          if (!isEqualOrNull(obj1[key], obj2[key])) {
            return false;
          }
        }

        return true;
      }

      function isEqualOrNull(valueA, valueB) {
        return valueA === null || valueA === valueB;
      }

      const checkDosage = (deviationDosage, propsDosage) => {
        if (
          deviationDosage.morning === null &&
          deviationDosage.noon === null &&
          deviationDosage.evening === null &&
          deviationDosage.night === null
        ) {
          return true;
        }

        const deviationOneLineDosage =
          (deviationDosage.morning?.toString() ?? 0) +
          (deviationDosage.noon?.toString() ?? 0) +
          (deviationDosage.evening?.toString() ?? 0) +
          (deviationDosage.night?.toString() ?? 0);

        const propsOneLineDosage =
          (propsDosage.morning?.toString() ?? 0) +
          (propsDosage.noon?.toString() ?? 0) +
          (propsDosage.evening?.toString() ?? 0) +
          (propsDosage.night?.toString() ?? 0);

        if (deviationOneLineDosage === "0000" || deviationOneLineDosage === propsOneLineDosage) {
          return true;
        }

        return false;
      };

      const handleInvalidDosage = (dosageId) => {
        emit("invalid", dosageId);
      };

      const updateUsage = () => {
        if (deviantMedicationForm.value.usage !== null) {
          deviantMedicationForm.value.usage = Number(deviantMedicationForm.value.usage);
        }

        emit("changeForm", {key: "deviantmedication.usage", newValue: deviantMedicationForm.value.usage});
      };

      const updateDosage = (newValues, type) => {
        const asFraction = type + "_as_fraction";

        dosageHelper.updateDosage(deviantMedicationForm.value.dosage, type, newValues, () => {
          emit("changeForm", {
            key: "deviantmedication.dosage." + type,
            newValue: deviantMedicationForm.value.dosage[type],
          });

          emit("changeForm", {
            key: "deviantmedication.dosage." + asFraction,
            newValue: deviantMedicationForm.value.dosage[asFraction],
          });

          emit("valid", newValues.id);
        });
      };

      const updateDosageUnit = ({keyDoe, unit}) => {
        dosageHelper.updateDosageUnit(deviantMedicationForm.value.dosage, keyDoe, unit, () => {
          emit("changeForm", {
            key: "deviantmedication.dosage.selected_unit",
            newValue: deviantMedicationForm.value.dosage.selected_unit,
          });

          emit("changeForm", {
            key: "deviantmedication.dosage.selected_unit_key_doe",
            newValue: deviantMedicationForm.value.dosage.selected_unit_key_doe,
          });
        });
      };

      const updateMeal = () => {
        emit("changeForm", {key: "deviantmedication.dosage.meal", newValue: deviantMedicationForm.value.dosage.meal});
      };

      const checkErrors = () => {
        if (invalidDosages.value.length > 0) {
          emit("invalid", alternativeDosageId);
        } else {
          emit("valid", alternativeDosageId);
        }
      };

      const handleInvalidAlternativeDosageEvent = (dosageId) => {
        emit("invalid", dosageId);
        checkErrors();
      };

      const handleValidAlternativeDosageEvent = (dosageId) => {
        emit("valid", dosageId);
        checkErrors();
      };

      const handleAlternativeDosageIdsChanged = () => {
        invalidDosages.value = [];
        checkErrors();
      };

      const handleAlternativeDosageTypeChange = () => {
        checkErrors();
      };

      const handleDosageChange = (newDosage) => {
        emit("changeForm", {key: "deviantmedication.dosage", newValue: newDosage});
      };

      const handleSatisfaction = () => {
        emit("changeForm", {
          key: "additionalinformation.satisfaction",
          newValue: additionalInformationForm.value.satisfaction,
        });
      };

      const handleSatisfactionComment = (newSatisfactionComment) => {
        additionalInformationForm.value.satisfactioncomment = newSatisfactionComment;

        emit("changeForm", {
          key: "additionalinformation.satisfactioncomment",
          newValue: additionalInformationForm.value.satisfactioncomment,
        });
      };

      const addSymptom = (newSymptomData) => {
        emit("changeForm", {
          key: "additionalinformation.satisfactionSymptom",
          newValue: newSymptomData,
        });
      };

      const handleSideEffects = (value, type) => {
        if (value) {
          addToList(type);
        } else {
          removeFromList(type);
        }

        const pageData = page.props.medications?.data.find(
          (medication) => medication.id === props.medicationForm.value.id,
        );

        complianceCheck(props.medicationForm.value, pageData, (isCompliance) => {
          if (additionalInformationForm.value.compliance && !isCompliance) {
            additionalInformationForm.value.compliance = false;
            updateNonCompliance();
          }
        });
      };

      const addToList = (sourceName) => {
        if (!additionalInformationForm.value.sideeffects.includes(sourceName)) {
          additionalInformationForm.value.sideeffects.push(sourceName);

          emit("changeForm", {
            key: "additionalinformation.sideeffects",
            newValue: additionalInformationForm.value.sideeffects,
          });
        }
      };

      const removeFromList = (sourceName) => {
        const indexFromList = additionalInformationForm.value.sideeffects.indexOf(sourceName);

        if (indexFromList > -1) {
          additionalInformationForm.value.sideeffects.splice(indexFromList, 1);

          emit("changeForm", {
            key: "additionalinformation.sideeffects",
            newValue: additionalInformationForm.value.sideeffects,
          });
        }
      };

      const updateComplianceInfo = () => {
        emit("changeForm", {key: "deviantmedication.dosage.info", newValue: deviantMedicationForm.value.dosage.info});
      };

      const updateNonCompliance = () => {
        emit("changeForm", {
          key: "additionalinformation.compliance",
          newValue: additionalInformationForm.value.compliance,
        });
      };

      const changeForm = ({key, newValue}) => {
        deviantMedicationForm.value[key] = newValue;

        emit("changeForm", {key: "deviantmedication." + key, newValue: newValue});
      };

      const openIndicationDialog = () => {
        if (props.isEditable) {
          refIndicationMenuDialog.value.open({
            medication: deviantMedicationForm.value,
            medicationKeyFam: props.medicationForm.value.abdata_key_fam,
            medicationHashId: props.medicationForm.value.hashId,
          });
        }
      };

      const restoreDefaultDosage = () => {
        const oldData = page.props.medications?.data.find(
          (medication) => medication.id === props.medicationForm.value.id,
        );

        const dosage = oldData.deviantmedication?.dosage ?? {};

        dosage.dosagealternative = {};

        if (oldData.deviantmedication !== null && oldData.deviantmedication.dosage !== null) {
          dosage.morning = oldData.deviantmedication.dosage?.morning;
          dosage.morning_as_fraction = oldData.deviantmedication.dosage?.morning_as_fraction;

          dosage.noon = oldData.deviantmedication.dosage?.noon;
          dosage.noon_as_fraction = oldData.deviantmedication.dosage?.noon_as_fraction;

          dosage.evening = oldData.deviantmedication.dosage?.evening;
          dosage.evening_as_fraction = oldData.deviantmedication.dosage?.evening_as_fraction;

          dosage.night = oldData.deviantmedication.dosage?.night;
          dosage.night_as_fraction = oldData.deviantmedication.dosage?.night_as_fraction;
        }

        handleDosageChange(dosage);
      };

      const resetDeviationIndication = () => {
        const medication = cloneDeep(
          page.props.medications?.data.find((oldMedication) => oldMedication.id === props.medicationForm.value.id),
        );

        if (medication.deviantmedication !== null) {
          changeForm({
            key: "indications",
            newValue: medication.deviantmedication.indications,
          });

          changeForm({
            key: "indication_unknown",
            newValue: medication.deviantmedication.indication_unknown,
          });
        } else {
          changeForm({key: "indications", newValue: []});
          changeForm({key: "indication_unknown", newValue: 0});
        }
      };

      const privacyErrors = computed(() => {
        return validatePrivacyObject(deviantMedicationForm.value?.dosage, {
          info: "Hinweise zur abweichenden Anwendung",
        });
      });

      return {
        /** enum */
        medicationMeal,
        medicationUsage,

        /** ref */
        refAlternativeDosage,
        refIndicationMenuDialog,

        /** const */
        showComplianceData,
        isAlternativeDosage,
        storage,
        expired,
        wrongUse,
        deviantMedicationForm,
        additionalInformationForm,
        currentDosageUnit,

        /** computed */
        currentDosageUnitList,
        privacyErrors,

        /** function */
        updateUsage,
        updateDosage,
        handleInvalidDosage,
        updateDosageUnit,
        updateMeal,
        handleInvalidAlternativeDosageEvent,
        handleValidAlternativeDosageEvent,
        handleAlternativeDosageIdsChanged,
        handleAlternativeDosageTypeChange,
        handleDosageChange,
        handleSatisfaction,
        handleSatisfactionComment,
        addSymptom,
        handleSideEffects,
        updateComplianceInfo,
        updateNonCompliance,
        changeForm,
        openIndicationDialog,
        resetDeviationIndication,
      };
    },
  };
</script>
