<template>
  <div>
    <div v-if="tight && comments.length > 2" class="text-center mb-2">
      <a href="#" @click.prevent="$emit('showAll')">alle anzeigen</a>
    </div>

    <ul class="space-y-6" :class="{'text-sm': tight}">
      <li v-if="comments.length === 0" class="cursor-default">Bisher keine Kommentare</li>

      <li v-for="(comment, index) in tight ? comments.slice(-2) : comments" v-else :key="index">
        <div
          v-if="isOwnComment(comment)"
          class="relative rounded-xl bg-gray-200 text-right"
          :class="{'p-4': !tight, 'px-2 py-1': tight}"
        >
          <div class="flex justify-between items-center">
            <component-icon-button
              class="p4umc-flat shrink-0"
              icon="delete"
              :data-test="dataTest + '-iconButton-delete'"
              @click="confirmRemove(comment)"
            />
            <div>
              <strong class="font-semibold">
                {{ comment.author + ", " }}
              </strong>
              <span class="text-gray-500 whitespace-nowrap">{{ tight ? comment.updated_at : comment.created_at }}</span>
            </div>
          </div>
          <component-icon class="absolute select-none rotate-[135deg] right-2 -bottom-4 text-4xl text-gray-200">
            signal_cellular_4_bar
          </component-icon>
          <div class="relative" :class="{'mt-4': !tight, 'mt-1': tight}">
            {{ comment.content }}
          </div>
        </div>

        <div v-else class="relative rounded-xl bg-gray-100" :class="{'p-4': !tight, 'px-2 py-1': tight}">
          <div class="flex justify-between items-center">
            <div>
              <strong v-if="!comment.confidential || comment.author?.length > 0" class="font-semibold">
                {{ comment.author + ", " }}
              </strong>
              <strong v-else class="font-semibold italic">Extern hinzugezogener Arzt,&nbsp;</strong>
              <span class="text-gray-500 whitespace-nowrap">{{ tight ? comment.updated_at : comment.created_at }}</span>
            </div>
            <div v-if="comment.acceptance">
              <strong v-if="comment.acceptance === 'accepted'" class="font-bold text-mcgreen">
                Vorschlag akzeptiert
              </strong>
              <strong v-if="comment.acceptance === 'declined'" class="font-bold text-mcred">Vorschlag abgelehnt</strong>
            </div>
          </div>
          <div class="relative z-10" :class="{'mt-4': !tight, 'mt-1': tight}">
            {{ comment.content }}
          </div>
          <component-icon class="absolute select-none -rotate-45 left-2 -bottom-4 text-4xl text-gray-100">
            signal_cellular_4_bar
          </component-icon>
        </div>
      </li>
    </ul>

    <component-confirmation-dialog
      ref="refConfirmRemove"
      title="Kommentar löschen?"
      content="Sind Sie sich sicher, dass Sie diesen Kommentar löschen möchten?"
      :data-test="dataTest + '-confirmDialog'"
      @confirmed="(comment) => remove(comment)"
    />
  </div>
</template>
<script>
  import {ref} from "vue";
  import {router, usePage} from "@inertiajs/vue3";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";

  export default {
    name: "ComponentCommentList",
    components: {ComponentConfirmationDialog, ComponentIcon, ComponentIconButton},
    props: {
      patientId: {
        type: String,
        required: true,
      },
      recordId: {
        type: String,
        required: true,
      },
      comments: {
        type: Array,
        required: true,
      },
      tight: {
        type: Boolean,
        default: false,
      },
      dataTest: {
        type: String,
        default: "commentList",
      },
    },
    emits: ["showAll"],
    setup(props) {
      const pageProps = usePage().props;
      const refConfirmRemove = ref(null);

      function isOwnComment(comment) {
        return comment.user_id === pageProps.client?.users[0]?.id;
      }

      function confirmRemove(comment) {
        refConfirmRemove.value.open(comment);
      }
      function remove(comment) {
        router.delete(
          route("comments.destroy", {patient: props.patientId, record: props.recordId, comment: comment.id}),
          {
            preserveScroll: true,
          },
        );
      }

      return {isOwnComment, refConfirmRemove, confirmRemove, remove};
    },
  };
</script>
