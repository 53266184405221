<template>
  <component-dialog ref="refGfrDialog" dialog-title="(e)GFR berechnen" dialog-width="medium" :is-loading="isLoading">
    <template #content>
      <div class="flex flex-col gap-4">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <div class="flex space-x-1 items-end">
              <label class="text-gray-500" for="gfr-algorithm">Algorithmus</label>
              <component-info-icon id="gfr-algorithm" placement="right-end" class="-mb-0.5">
                <template #content>
                  <div class="flex flex-col gap-3">
                    <template
                      v-if="
                        laborForm.gfr.gfr_algorithm === 'useCkdEpi' ||
                          laborForm.gfr.gfr_algorithm === 'useCkdEpiBlackEthnic'
                      "
                    >
                      <p>
                        Die CKD-EPI-Formel schätzt die GFR im Grenzbereich einer beginnenden Niereninsuffizienz genauer
                        als die MDRD-Formel. Berechnung erfolgt anhand idealisierter KOF.
                      </p>

                      <p>
                        CAVE: Die Formel besitzt eine eingeschränkte Aussagekraft bei Kindern und Jugendlichen, sehr
                        hohem Alter, starkem Über- oder Untergewicht, extremer Muskelmasse, Skelettmuskelerkrankungen.
                      </p>

                      <p>
                        <strong class="font-semibold">Berechnung:</strong>
                        <br>
                        <i>
                          GFR = 141 × min(SKr[mg/dl] / κ, 1)
                          <sup>α</sup>
                          × max(SKr[mg/dl] / κ, 1)
                          <sup>-1,209</sup>
                          × 0,993
                          <sup>Alter[Jahre]</sup>
                        </i>
                      </p>

                      <ul class="list-disc pl-2 ml-2 space-y-1">
                        <li>Geschlechtsabhängiger Faktor k: 0,7 (Frau) bzw. 0,9 (Mann)</li>
                        <li>Geschlechtsabhängiger Faktor α: -0,329 (Frau) bzw. -0,411 (Mann)</li>
                        <li>weitere Korrekturfaktoren: 1,018 (Frau); 1,159 (schwarze Hautfarbe)</li>
                      </ul>
                    </template>

                    <template v-else-if="laborForm.gfr.gfr_algorithm === 'useMayoClinic'">
                      <p>Gut geeignet wenn eine genaue Diagnose noch nicht bekannt ist.</p>

                      <p>
                        <strong class="font-semibold">Berechnung:</strong>
                        <br>
                        <i>
                          GFR = exp(1,911 + 5,249 / SKr[mg/dl] - 2,114 / SKr[mg/dl]
                          <sup>2</sup>
                          - 0,00686 × Alter[Jahre] - GF)
                        </i>
                      </p>

                      <ul class="list-disc pl-2 ml-2 space-y-1">
                        <li>Geschlechtsfaktor GF: 0,205 (Frau); 0 (Mann)</li>
                      </ul>

                      <p>
                        Wenn der Serumkreatininwert &#060; 0,8 mg/dl beträgt, wird das Serumkreatinin auf 0,8 mg/dl
                        festgelegt. Daher ändert sich die berechnete GFR bei niedrigem Serumkreatinin im Online-Rechner
                        nicht mehr.
                      </p>
                    </template>

                    <template
                      v-else-if="
                        laborForm.gfr.gfr_algorithm === 'useMdrd' ||
                          laborForm.gfr.gfr_algorithm === 'useMdrdBlackEthnic'
                      "
                    >
                      <p>
                        CAVE: Die MDRD-Formel gilt nur für Personen im Alter von 18 bis 70 Jahren und ist für
                        verschiedene Personengruppen ungeeignet (Nierentransplantierte, Dialysepatienten, Schwangere,
                        Diabetes-Patienten, die Insulin spritzen, Patienten mit weiteren schweren Krankheiten, Personen
                        mit großer Muskelmasse oder Unterernährung). Wählen Sie in den genannten Fällen eine andere
                        Formel z.B CKD-EPI. Es existieren verschiedene Versionen der Formel, wobei die folgende die
                        gebräuchlichste ist.
                      </p>

                      <p>
                        <strong class="font-semibold">Berechnung:</strong>
                        <br>
                        <i>
                          GFR = 186 × SKr[mg/dl]
                          <sup>-1,154</sup>
                          × Alter[Jahre]
                          <sup>-0,203</sup>
                        </i>
                      </p>

                      <ul class="list-disc pl-2 ml-2 space-y-1">
                        <li>Korrekturfaktoren: 0,742 (Frau), 1,21 (schwarze Hautfarbe)</li>
                      </ul>
                    </template>

                    <template v-else-if="laborForm.gfr.gfr_algorithm === 'useCockcroftGault'">
                      <p>
                        CAVE: Formel wurde aufgrund einer eingeschränkten Fallzahl entwickelt, dies führt zu einer
                        Beeinträchtigung der Validität.
                      </p>

                      <p>
                        <strong class="font-semibold">Berechnung:</strong>
                        <br>
                        <i>GFR = ((140 − Alter[Jahre]) / SKr[mg/dl]) × (Körpergewicht[kg]) / 72)</i>
                      </p>

                      <ul class="list-disc pl-2 ml-2 space-y-1">
                        <li>Korrekturfaktor: 0,85 (Frau)</li>
                      </ul>
                    </template>

                    <template v-else>Keine Berechnung.</template>
                  </div>
                </template>
              </component-info-icon>
            </div>

            <component-select v-model="laborForm.gfr.gfr_algorithm" :options="patientGfralgorithm" />
          </div>
        </div>

        <div v-if="laborForm.gfr.gfr_algorithm?.length > 0" class="grid grid-cols-2 gap-4">
          <template v-if="laborForm.gfr.gfr_algorithm === 'useCockcroftGault'">
            <component-input
              v-model="laborForm.weight.value"
              label="Gewicht in kg"
              :helper-text="bmiHelperText"
              :validation="laborForm.errors.weight"
            />

            <div />
          </template>

          <component-input
            v-model="laborForm.creatinine.value"
            label="Kreatinin"
            :helper-text="creatinineReferenceText"
            :validation="laborForm.errors.creatinine"
          />

          <component-select
            label="Einheit (opt.):"
            :model-value="laborForm.creatinine.mc_unit_id"
            :options="laborForm.creatinine.core.unitList"
            key-value="id"
            key-name="unit"
            return-type="object"
            @change="handleCreatinineUnitChange"
          />
        </div>

        <div>
          <h2 class="mb-2 text-base">Ergebnis</h2>

          <component-alert v-if="laborForm.errors.gfr" type="error">
            <p>{{ laborForm.errors.gfr }}</p>
          </component-alert>

          <component-alert v-if="gfrWeightOrBmiError" type="error">
            <p>
              Laut Ihren Patientenangaben weist Ihr(e) Patient(in) einen BMI >30 kg/m² und/oder ein Körpergewicht von
              >120 kg auf. Die von Ihnen gewählte Formel ist zur korrekten Berechnung der Nierenfunktion bei Vorliegen
              dieser Körperkonstitution ungeeignet. Aufgrund dessen ist eine Berechnung der (e)GFR mit der
              <button class="text-mcred" @click="laborForm.gfr.gfr_algorithm = 'useCkdEpi'">CKD-EPI-Formel</button>
              empfehlenswert. Mehr Informationen zur korrekten Berechnung der Nierenfunktionsleistung finden Sie
              <a target="_blank" href="https://medicheck.pharma4u.de/url-redirect/gfr-calculation">hier</a>
              .
            </p>
          </component-alert>

          <component-alert v-if="gender === 'diverse'" type="warning">
            <p>
              Die Berechnung ist mit dem für den Patienten hinterlegten Geschlecht "divers" nicht möglich, es wird daher
              das Geschlecht "weiblich" verwendet.
            </p>
          </component-alert>

          <div class="grid grid-cols-2 gap-4 bg-gray-100 p-2 rounded">
            <component-fake-input :value="gfrValue" label="(e)GFR in ml/min" :helper-text="gfrReferenceText" />

            <component-fake-input class="mt-6" :value="laborbardeviationValues[laborForm.gfr.deviation] ?? ''" />
          </div>
        </div>
      </div>
    </template>

    <template #actions>
      <component-button class="p4umc-primary" label="Übernehmen & Speichern" :disabled="hasError" @click="saveGfr" />

      <component-button label="Abbrechen" @click="closeDialog" />
    </template>
  </component-dialog>
</template>

<script>
  import {computed, inject, reactive, ref, toRefs, watch} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";
  import {cloneDeep} from "lodash";

  import Gfr from "@utils/Gfr.js";
  import NumberHelper from "@utils/Helpers/NumberHelper.js";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentFakeInput from "@components/Inputs/FakeInput.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSelect from "@components/Selects/Select.vue";

  import {laborbardeviationValues, patientGfralgorithm} from "@pages/Records/Components/Sections/LaborBar/enums.js";
  import {
    getDeviationFromValue,
    getReferenceText,
    getReferenceValue,
  } from "@pages/Records/Components/Sections/LaborBar/Utils/laborvalue.js";

  export default {
    name: "LaborTableGfrCalculator",

    components: {
      ComponentAlert,
      ComponentButton,
      ComponentDialog,
      ComponentFakeInput,
      ComponentInfoIcon,
      ComponentInput,
      ComponentSelect,
    },

    setup() {
      const page = usePage();

      const privacy = inject("$privacy");
      const setIsMassUpdateProcessing = inject("setIsMassUpdateProcessing");

      const refGfrDialog = ref(null);

      const isLoading = ref(false);
      const state = reactive({
        gender: null,
        gfrWeightOrBmiError: false,
      });

      const laborForm = useForm({
        weight: {},
        bmi: {},
        creatinine: {},
        gfr: {
          gfr_algorithm: "",
        },
        size: {},
      });

      const bmiReferenceValue = computed(() => getReferenceValue(cloneDeep(laborForm.bmi), state.gender));
      const creatinineReferenceValue = computed(() => getReferenceValue(cloneDeep(laborForm.creatinine), state.gender));
      const gfrReferenceValue = computed(() => getReferenceValue(cloneDeep(laborForm.gfr), state.gender));

      const bmiHelperText = computed(() => {
        if (laborForm.bmi.value <= 0) {
          return null;
        }

        let deviation = "";

        if (laborForm.bmi.deviation === "lowered") {
          deviation = " (↓)";
        } else if (laborForm.bmi.deviation === "raised") {
          deviation = " (↑)";
        }

        return "BMI: " + laborForm.bmi.value + " kg/m²" + deviation;
      });

      const creatinineReferenceText = computed(() => {
        return creatinineReferenceValue.value && laborForm.creatinine.value
          ? getReferenceText(creatinineReferenceValue.value)
          : null;
      });

      const gfrReferenceText = computed(() => {
        return gfrReferenceValue.value && laborForm.gfr.value ? getReferenceText(gfrReferenceValue.value) : null;
      });

      const gfrValue = computed(() => {
        return !laborForm.errors.gfr && laborForm.gfr.gfr_algorithm?.length > 0 ? laborForm.gfr.value : "–";
      });

      const hasError = computed(
        () =>
          state.gfrWeightOrBmiError ||
          laborForm.errors.bmi ||
          laborForm.errors.gfr ||
          laborForm.errors.size ||
          laborForm.errors.weight ||
          laborForm.errors.creatinine,
      );

      const open = (laborValues) => {
        laborForm.weight = cloneDeep(laborValues.weight) ?? {};
        laborForm.bmi = cloneDeep(laborValues.bmi) ?? {};
        laborForm.creatinine = cloneDeep(laborValues.creatinine) ?? {};
        laborForm.gfr = cloneDeep(laborValues.gfr) ?? {};
        laborForm.size = cloneDeep(laborValues.size) ?? {};

        laborForm.gfr.gfr_algorithm = page.props.record.gfralgorithm ?? null;

        state.gender = page.props.patient.gender;

        refGfrDialog.value.open();
      };

      watch(
        () => laborForm.gfr.gfr_algorithm,
        () => {
          calculateGfr();
        },
      );

      watch(
        () => laborForm.weight.value,
        () => {
          laborForm.weight.mc_unit_id = laborForm.weight.core.unitList[0].id;
          laborForm.weight.unit = laborForm.weight.core.unitList[0].unit;

          if (!laborForm.weight.value) {
            return;
          }

          if (laborForm.weight.value >= 400) {
            laborForm.errors.weight = "Bitte ein sinnvolles Gewicht eingeben.";
            return;
          } else {
            laborForm.errors.weight = null;
          }

          calculateBmi();
          calculateGfr();
        },
      );

      watch(
        () => laborForm.creatinine,
        () => {
          if (!laborForm.creatinine.unit) {
            laborForm.creatinine.mc_unit_id = laborForm.creatinine.core.unitList[0].id;
            laborForm.creatinine.unit = laborForm.creatinine.core.unitList[0].unit;
          }

          if (creatinineReferenceValue.value && laborForm.creatinine.value) {
            laborForm.creatinine.deviation = getDeviationFromValue(
              laborForm.creatinine.value,
              creatinineReferenceValue.value.low,
              creatinineReferenceValue.value.high,
            );
          }

          calculateGfr();
        },
        {deep: true},
      );

      const handleCreatinineUnitChange = (newCreatinineUnit) => {
        laborForm.creatinine.value = null;
        laborForm.creatinine.deviation = "normal";

        laborForm.creatinine.mc_unit_id = newCreatinineUnit.id;
        laborForm.creatinine.unit = newCreatinineUnit.unit;
      };

      const calculateBmi = () => {
        if (laborForm.weight.value === null || laborForm.size.value === null) {
          return;
        }

        const weightAsNumber = NumberHelper.formatStringAsFloat(laborForm.weight.value);
        const sizeAsNumber = NumberHelper.formatStringAsFloat(laborForm.size.value);

        laborForm.bmi.value = Math.round(weightAsNumber / (sizeAsNumber * sizeAsNumber)).toString();
        laborForm.bmi.mc_unit_id = laborForm.bmi.core.unitList[0].id;
        laborForm.bmi.unit = laborForm.bmi.core.unitList[0].unit;

        laborForm.bmi.deviation = getDeviationFromValue(
          NumberHelper.formatStringAsFloat(laborForm.bmi.value),
          bmiReferenceValue.value.low,
          bmiReferenceValue.value.high,
        );
      };

      const calculateGfr = () => {
        laborForm.errors.gfr = null;
        state.gfrWeightOrBmiError = false;

        laborForm.gfr.value = ""; // must be string!!
        laborForm.gfr.type = laborForm.gfr.core.type;
        laborForm.gfr.mc_laborvalue_id = laborForm.gfr.core.mc_laborvalue_id;
        laborForm.gfr.mc_laborvalue_key = laborForm.gfr.core.mc_laborvalue_key;
        laborForm.gfr.mc_unit_id = laborForm.gfr.core.unitList[0].id;
        laborForm.gfr.unit = laborForm.gfr.core.unitList[0].unit;
        laborForm.gfr.deviation = null;

        if (!laborForm.gfr.gfr_algorithm || laborForm.gfr.gfr_algorithm === "") {
          return;
        } else if (
          laborForm.gfr.gfr_algorithm === "useCockcroftGault" &&
          (laborForm.weight.value > 120 || laborForm.bmi.value > 30)
        ) {
          state.gfrWeightOrBmiError = true;
          return;
        }

        privacy.decryptPatient(page.props.patient).then((decryptedPatient) => {
          const now = new Date();
          const birthdate = new Date(decryptedPatient.birthdate);
          let age = now.getFullYear() - birthdate.getFullYear();
          const m = now.getMonth() - birthdate.getMonth();
          if (m < 0 || (m === 0 && now.getDate() < birthdate.getDate())) {
            age--;
          }

          let creatinine = NumberHelper.formatStringAsFloat(laborForm.creatinine.value);

          if (laborForm.creatinine.mc_unit_id === 14) {
            creatinine = creatinine * 0.0113;
          }

          const gfrCalculator = new Gfr();
          const newGfrValue = gfrCalculator
            .setGender(state.gender)
            .setAge(age)
            .setWeight(NumberHelper.formatStringAsFloat(laborForm.weight.value))
            .setCreatinine(creatinine)
            .getGfr(laborForm.gfr.gfr_algorithm);

          if (!newGfrValue) {
            laborForm.errors.gfr = "Fehlende Werte für die Berechnung.";
            return null;
          }

          laborForm.gfr.value = newGfrValue.toString();

          if (gfrReferenceValue.value && newGfrValue) {
            laborForm.gfr.deviation = getDeviationFromValue(
              newGfrValue,
              gfrReferenceValue.value.low,
              gfrReferenceValue.value.high,
            );
          }
        });
      };

      const saveGfr = async () => {
        laborForm.put(
          route("laborvalues.mass-update-or-create", {
            patient: page.props.patient.id,
            record: page.props.record.id,
          }),
          {
            preserveScroll: true,
            preserveState: true,
            only: ["flash", "errors", "record", "laborvalues", "diseases"],
            onBefore: () => {
              setIsMassUpdateProcessing("laborValue", true);
              isLoading.value = true;
            },
            onFinish: () => {
              isLoading.value = false;
              setIsMassUpdateProcessing("laborValue", false);
              closeDialog();
            },
          },
        );
      };

      const closeDialog = () => {
        refGfrDialog.value.close();
      };

      return {
        /** enum */
        patientGfralgorithm,
        laborbardeviationValues,

        /** ref */
        refGfrDialog,

        /** const */
        isLoading,
        ...toRefs(state),
        laborForm,

        /** computed */
        bmiHelperText,
        creatinineReferenceText,
        gfrReferenceText,
        gfrValue,
        hasError,

        /** function */
        open,
        handleCreatinineUnitChange,
        saveGfr,
        closeDialog,
      };
    },
  };
</script>
