<template>
  <div class="flex items-start">
    <button
      v-if="patient[attributeName]"
      class="mr-2 rounded-full"
      :data-test="testId + '-document-row-' + attributeName + '-toggle-circle'"
      @click="save(false)"
    >
      <component-icon class="text-2xl text-mcgreen">check_circle</component-icon>
    </button>

    <button
      v-else
      class="mr-2 group/todo rounded-full"
      :data-test="testId + '-document-row-' + attributeName + '-toggle-circle'"
      @click="save(true)"
    >
      <component-icon class="group-hover/todo:hidden text-2xl text-gray-600">radio_button_unchecked</component-icon>

      <component-icon class="hidden group-hover/todo:block text-2xl text-gray-600">check_circle_outline</component-icon>
    </button>

    <div>
      <div>
        <slot />
      </div>

      <div class="text-xs">
        <span v-if="patient[attributeName]">am {{ patient[attributeName] }} Uhr</span>

        <a
          v-else
          href="#"
          :data-test="testId + '-document-row-' + attributeName + '-toggle-link'"
          @click.prevent="save(true)"
        >
          als unterzeichnet kennzeichnen
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import {router} from "@inertiajs/vue3";

  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "PatientsPatientDocumentRow",

    components: {ComponentIcon},

    props: {
      patient: {
        type: Object,
        required: true,
      },
      attributeName: {
        type: String,
        required: true,
      },
      testId: {
        type: String,
        default: "",
      },
    },

    setup(props) {
      function save(value) {
        router.patch(
          route("patients.update", {patient: props.patient.id}),
          {[props.attributeName]: value},
          {
            preserveScroll: true,
          },
        );
      }

      return {
        /** function */
        save,
      };
    },
  };
</script>
