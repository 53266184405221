<template>
  <footer class="bottom-0 w-full py-3 bg-white shadow-[0_10px_15px_8px_rgba(0,0,0,0.3)] flex md:items-center px-4">
    <div class="flex flex-1 justify-between sm:flex-row gap-2 sm:gap-0 flex-wrap">
      <div class="flex items-center">
        <template v-if="isDevelop">
          <div
            class="-mx-2 px-2 -my-1 py-1 bg-red-500 text-yellow-400 font-bold cursor-pointer rounded"
            :data-test="testId + '-footer-show-ce-logo-button'"
            @click="enableMedicalCertification"
          >
            Dev / Test System (
            <span v-text="environment" />
            )
          </div>
        </template>

        <template v-else>
          <template v-if="showCeLogo">
            <div class="flex items-center" :data-test="testId + '-footer-ce-logo'">
              <img alt="CeLogo" src="/resources/assets/images/ce-with-number.png" class="h-5 ml-2 mr-1 mt-1.5" />

              <span class="text-gray-500">{{ productName }}</span>

              <img
                alt="MedicalDeviceSymbol"
                src="/resources/assets/images/MedicalDeviceSymbol-20221027-121211.png"
                class="h-6 mx-0.5"
              />
            </div>
          </template>
        </template>
      </div>

      <div class="flex items-center">
        <a
          class="text-sm md:text-base"
          href="https://www.abda.de/fuer-apotheker/arzneimittelkommission/berichtsbogen-formulare"
          target="_blank"
          :data-test="testId + '-footer-uaw-amk-link'"
        >
          UAW an AMK melden
        </a>

        <div class="w-px h-5 bg-gray-400 mx-2" />

        <a class="text-sm md:text-base" :data-test="testId + '-footer-labeling-button'" @click="openLabellingDialog">
          Kennzeichnung
        </a>

        <div class="w-px h-5 bg-gray-400 mx-2" />

        <a
          class="text-sm md:text-base"
          href="https://www.pharma4u.de/allgemein/impressum"
          target="_blank"
          :data-test="testId + '-footer-imprint-link'"
        >
          Impressum
        </a>
      </div>

      <div class="flex items-center">
        <span class="text-gray-500 pr-1">{{ versionName }} {{ version }}</span>
        <component-process-saved :seconds-to-hide-date="10" />
      </div>
    </div>

    <component-dialog
      ref="refDialog"
      dialog-title="Kennzeichnung"
      dialog-type="information"
      dialog-width="medium"
      :test-id="testId + '-footer-labeling'"
    >
      <template #content>
        <h4 class="text-md leading-6 font-medium">{{ distributorLabel }}</h4>

        <div class="flex mt-1">
          <p>
            pharma4u GmbH
            <br />
            Apothekerhaus
            <br />
            Carl-Mannich-Straße 26
            <br />
            65760 Eschborn
          </p>
        </div>

        <h4 class="mt-8 text-md leading-6 font-medium">Hersteller und UDI</h4>

        <div class="flex flex-col sm:flex-row gap-8">
          <div class="flex mt-2">
            <img alt="iso" class="mt-2 h-16" src="/resources/assets/images/iso_grs_7000_3082.png" />

            <p class="self-center ml-3">
              Johner Medical GmbH,
              <br />
              Niddastr. 91,
              <br />
              60329 Frankfurt
            </p>
          </div>

          <div class="flex">
            <img
              alt="MedicalDeviceSymbol"
              class="h-16 -ml-1"
              src="/resources/assets/images/MedicalDeviceSymbol-20221027-121211.png"
            />

            <p class="ml-3 mt-2">
              UDI:
              <span>{{ udi }}</span>
            </p>
          </div>
        </div>

        <div v-if="manuals !== null" class="mt-8">
          <p v-if="license === 'unknown' || license === 'online-pro' || license === 'connect-pro'">
            <a target="_blank" :href="manuals.online.target_url">{{ manuals.online.link_text }}</a>
          </p>

          <p v-if="license === 'connect-core' || license === 'connect-pro'">
            <a target="_blank" :href="manuals.connect.target_url">{{ manuals.connect.link_text }}</a>
          </p>
        </div>
        <div v-else class="mt-8">
          <component-spinner class="w-8" />
        </div>

        <h4 class="mt-8 text-md leading-6 font-medium">Meldungen von Vorkommnissen MediCheck</h4>

        <div class="flex mt-1">
          <p>
            Fehler oder Vorkommnisse, die in Zusammenhang mit der Nutzung der Software MediCheck auftreten, melden Sie
            bitte an die Adresse

            <!-- prettier-ignore -->
            <span>
              <a
                class="focus:ring-0"
                href="mailto:meldung@wir-machen-amts.de"
                :data-test="testId + '-footer-labeling-dialog-email-amts'"
              >
                meldung@wir-machen-amts.de
              </a>.
            </span>
          </p>
        </div>
      </template>
    </component-dialog>
  </footer>
</template>

<script>
  import {computed, inject, ref} from "vue";
  import {usePage} from "@inertiajs/vue3";

  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentProcessSaved from "@components/ProcessSaved.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "LayoutFooter",

    components: {ComponentSpinner, ComponentProcessSaved, ComponentDialog},

    setup() {
      const page = usePage();
      const axios = inject("$axios");
      const hasThirdParty = inject("$hasThirdParty");

      const refDialog = ref(null);
      const medicalCertification = ref(false);

      const environment = computed(() => page.props.environmentConfig.environment);
      const isDevelop = computed(() => page.props.environmentConfig.isDevelop && medicalCertification.value === false);
      const showCeLogo = computed(() => !page.props.can?.is_education);
      const thirdPartyIsOnlyP4u = computed(() => page.props.thirdPartyIsOnlyP4u);
      const udi = computed(() => page.props.udi);
      const version = computed(() => page.props.environmentConfig.version);
      const manuals = ref(null);

      const distributorLabel = computed(() => {
        if (thirdPartyIsOnlyP4u.value) return "Vertreiber/Händler und Lizenzgeber";
        return "Betreiber";
      });

      const productName = computed(() => {
        if (hasThirdParty("nident")) return "MediCheck Connect";
        return "MediCheck Online";
      });

      const versionName = computed(() => {
        if (!page.props.thirdPartys) {
          return "Version";
        }

        const hasPharma4uThirdParty = hasThirdParty("p4u");
        const hasNidentThirdParty = hasThirdParty("nident");

        if (hasPharma4uThirdParty && hasNidentThirdParty) {
          return "Pro-Version"; // Connect Pro
        } else if (hasNidentThirdParty) {
          return "Core-Version"; // Connect Core
        } else if (hasPharma4uThirdParty) {
          return "Pro-Version"; // Online Pro
        }

        return "Quick-Version"; // Connect Quick
      });

      const license = computed(() => {
        // not authenticated
        if (!page.props.thirdPartys) {
          return "unknown";
        }

        if (hasThirdParty("p4u") && hasThirdParty("nident")) {
          return "connect-pro";
        }

        if (hasThirdParty("p4u") && !hasThirdParty("nident")) {
          return "online-pro";
        }

        return "connect-core";
      });

      const enableMedicalCertification = () => {
        medicalCertification.value = true;

        window.setTimeout(() => {
          medicalCertification.value = false;
        }, 15000);
      };

      const openLabellingDialog = () => {
        if (refDialog.value) {
          refDialog.value.open();
        }

        if (manuals.value === null) {
          Promise.all([
            axios.get(route("url-redirect", {identifier: "manual-medicheck"})),
            axios.get(route("url-redirect", {identifier: "manual-medicheck-wawi"})),
          ])
            .then((responses) => {
              manuals.value = {
                online: responses[0].data,
                connect: responses[1].data,
              };
            })
            .catch((error) => {
              console.error(error);
            });
        }
      };

      return {
        /** ref */
        refDialog,

        /** computed */
        environment,
        isDevelop,
        showCeLogo,
        udi,
        version,
        versionName,
        distributorLabel,
        productName,
        license,
        manuals,

        /** function */
        enableMedicalCertification,
        openLabellingDialog,
      };
    },
  };
</script>
