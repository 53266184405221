<template>
  <component-dialog
    ref="refDialog"
    dialog-width="medium"
    :dialog-title="dialogTitle"
    :is-loading="isLoading"
    :test-id="testId + '-record-share'"
  >
    <template #content>
      <component-tabs :active-tab="tabTitles[activeTab]" @click="tabChanged">
        <component-tab :title="tabTitles.direct" :test-id="testId + '-record-share-dialog-direct-share'">
          <div v-if="form.wasSuccessful" class="mt-6 space-y-4">
            <p>
              Erfolgreich geteilt mit:
              <br />
              <strong>{{ form.email }}</strong>
            </p>

            <p v-if="form.notify_recipient">
              Der Empfänger wird per E-Mail über Ihre Freigabe benachrichtigt und kann die geteilte Variante dort über
              einen Link öffnen bzw. bearbeiten.
            </p>

            <p v-else>
              Der Empfänger wird
              <em>nicht</em>
              per E-Mail benachrichtigt.
            </p>
          </div>

          <div v-else class="mt-6 grid grid-cols-3 gap-4">
            <component-input
              v-model="form.email"
              class="col-start-1 col-span-2"
              label="E-Mail-Adresse des pharma4u-Accounts"
              :validation="form.errors.email"
              :test-id="testId + '-record-share-dialog-direct-share-tab-email'"
            />

            <component-input
              v-model="form.aliasname"
              class="col-start-1 col-span-2"
              label="Alias-Name / Kurznotiz (max. 30 Zeichen)"
              maxlength="30"
              :validation="form.errors.aliasname"
              :privacy-guard="privacyErrors.aliasname"
              :test-id="testId + '-record-share-dialog-direct-share-tab-alias-name'"
            >
              <template #privacy-guard>
                <component-privacy-guard type="strict" :validation="privacyErrors.aliasname" />
              </template>
            </component-input>

            <div class="col-span-3">
              <component-textarea
                v-model="form.info"
                label="Weiteres zum Patienten"
                :validation="form.errors.info"
                :privacy-guard="privacyErrors.info"
                :test-id="testId + '-record-share-dialog-direct-share-tab-info'"
              >
                <template #privacy-guard>
                  <component-privacy-guard type="strict" :validation="privacyErrors.info" />
                </template>
              </component-textarea>
            </div>

            <component-checkbox
              v-model="form.notify_recipient"
              color="primary"
              class="col-span-3"
              :test-id="testId + '-record-share-dialog-direct-share-tab-notify-recipient'"
            >
              Empfänger per E-Mail benachrichtigten
            </component-checkbox>

            <div v-if="form.errors.server_side" class="text-mcred col-span-3">{{ form.errors.server_side }}</div>
          </div>
        </component-tab>

        <component-tab
          v-if="$page.props.can.allow_shared_document_forwarding"
          :title="tabTitles.link"
          :test-id="testId + '-record-share-dialog-share-link'"
        >
          <div v-if="form.wasSuccessful" class="mt-6 space-y-4">
            <p>
              Die Seminar- / Linkfreigabe wurde erfolgreich erstellt. Bitte kopieren Sie sich den untenstehenden Link
              und senden Sie ihn an Ihren Kollegen / Teilnehmern.
            </p>

            <div class="flex items-center flex-wrap border-b border-gray-300 pb-6 justify-between">
              <div class="flex items-center">
                <strong v-text="shareLinkUrl" />
                <textarea ref="refShareLinkUrl" style="width: 0px; height: 0px; opacity: 0" v-text="shareLinkUrl" />
              </div>

              <div>
                <component-button
                  class="p4umc-primary"
                  label="Link kopieren"
                  :icon-button="true"
                  :test-id="testId + '-record-share-dialog-share-link-tab-copy-to-clipboard'"
                  @click="copyToClipboard()"
                >
                  <component-icon v-if="copyingShareLinkUrl">content_paste</component-icon>
                  <component-icon v-else>content_copy</component-icon>
                </component-button>
              </div>
            </div>

            <p>
              Wenn der Empfänger diesen Link aufruft, wird diese Variante umgehend freigegeben, so als hätten Sie eine
              direkte Fallfreigabe erstellt.
            </p>
          </div>

          <div v-else class="mt-6 space-y-4">
            <p>
              Bei einer Seminar- / Linkfreigabe erhalten Sie einen Link, den Sie ihren Kollegen / Teilnehmern bspw. per
              E-Mail zukommen lassen. Mit dem Aufruf dieses Links erhält ein Berechtigter Zugriff auf diese Variante.
            </p>

            <div class="grid grid-cols-3 gap-4">
              <component-input
                v-model="form.aliasname"
                class="col-start-1 col-span-2"
                label="Alias-Name / Kurznotiz (max. 30 Zeichen)"
                maxlength="30"
                :validation="form.errors.aliasname"
                :privacy-guard="privacyErrors.aliasname"
                :test-id="testId + '-record-share-dialog-share-link-tab-alias-name'"
              >
                <template #privacy-guard>
                  <component-privacy-guard type="strict" :validation="privacyErrors.aliasname" />
                </template>
              </component-input>

              <div class="col-span-3">
                <component-textarea
                  v-model="form.info"
                  label="Weiteres zum Patienten"
                  :validation="form.errors.info"
                  :privacy-guard="privacyErrors.info"
                  :test-id="testId + '-record-share-dialog-share-link-tab-info'"
                >
                  <template #privacy-guard>
                    <component-privacy-guard type="strict" :validation="privacyErrors.info" />
                  </template>
                </component-textarea>
              </div>

              <div v-if="form.errors.server_side" class="text-mcred col-span-3">{{ form.errors.server_side }}</div>
            </div>
          </div>
        </component-tab>

        <component-tab
          v-if="$page.props.can.allow_expert_service"
          :title="tabTitles.expert"
          :test-id="testId + '-record-share-dialog-expert-service'"
        >
          <div v-if="form.wasSuccessful || alreadySharedWithExpertService" class="mt-6 space-y-4">
            <p>Ihre Variante wurde erfolgreich mit unserem Expertenservice geteilt.</p>
            <div>
              <h6 class="font-semibold">Wie geht es weiter?</h6>
              <p class="mt-2">
                Ihr Fall wird innerhalb von ca. drei Werktagen von einem Experten der pharma4u geprüft und an Sie
                zurückgesendet. Einzelne Kommentare können Sie bereits vorab im Fall einsehen. Bei Rückfragen werden wir
                mit Ihnen Kontakt aufnehmen.
              </p>
            </div>
            <div v-if="alreadySharedWithExpertService" class="mt-2">
              <h6 class="font-semibold">Möchten Sie den Fall erneu senden?</h6>
              <p class="mt-2">
                Wenn Sie den Fall z. B. aufgrund von Änderungen am Analyseergebnis erneut an unseren Expertenservice
                senden möchten, müssen Sie zunächst den bereits geteilten Fall widerrufen, bevor Sie den Fall erneut
                teilen können.
              </p>
            </div>
          </div>

          <div v-else class="mt-6 space-y-4">
            <p>Holen Sie eine zweite Meinung ein: unser Expertenservice hilft Ihnen bei kniffeligen Fragen.</p>

            <h6 class="font-semibold">Sind alle Angaben vorhanden?</h6>
            <div class="grid grid-cols-3 gap-4">
              <component-checkbox
                v-model="hasPulseAndBloodpressure"
                :disabled="
                  hasPulseAndBloodpressure && hasMainMedicalComplaints && hasKnownIndications && hasPrioritisedResult
                "
                color="primary"
                class="col-span-3"
                :test-id="testId + '-record-share-dialog-expert-tab-has-pulse-and-bloodpressure'"
              >
                Blutdruck / Puls vorhanden *
              </component-checkbox>

              <component-checkbox
                v-model="hasMainMedicalComplaints"
                :disabled="
                  hasPulseAndBloodpressure && hasMainMedicalComplaints && hasKnownIndications && hasPrioritisedResult
                "
                color="primary"
                class="col-span-3"
                :test-id="testId + '-record-share-dialog-expert-tab-has-main-medical-complaints'"
              >
                Hauptbeschwerden eingetragen *
              </component-checkbox>

              <component-checkbox
                v-model="hasKnownIndications"
                :disabled="
                  hasPulseAndBloodpressure && hasMainMedicalComplaints && hasKnownIndications && hasPrioritisedResult
                "
                color="primary"
                class="col-span-3"
                :test-id="testId + '-record-share-dialog-expert-tab-has-known-indications'"
              >
                Bekannte Indikationen eingetragen *
              </component-checkbox>

              <component-checkbox
                v-model="hasPrioritisedResult"
                :disabled="
                  hasPulseAndBloodpressure && hasMainMedicalComplaints && hasKnownIndications && hasPrioritisedResult
                "
                color="primary"
                class="col-span-3"
                :test-id="testId + '-record-share-dialog-expert-tab-has-prioritised-result'"
              >
                Ergebnis ist priorisiert (relevante Checkergebnisse markiert) *
              </component-checkbox>
            </div>

            <div
              v-if="hasPulseAndBloodpressure && hasMainMedicalComplaints && hasKnownIndications && hasPrioritisedResult"
              class="pt-4"
            >
              <h6 class="font-semibold">Weitere Informationen zum Patienten</h6>
              <div class="grid grid-cols-3 gap-4">
                <component-input
                  v-model="form.aliasname"
                  class="col-start-1 col-span-2"
                  label="Alias-Name / Kurznotiz*"
                  maxlength="30"
                  :validation="form.errors.aliasname"
                  :privacy-guard="privacyErrors.aliasname"
                  :test-id="testId + '-record-share-dialog-expert-tab-alias-name'"
                >
                  <template #privacy-guard>
                    <component-privacy-guard type="strict" :validation="privacyErrors.aliasname" />
                  </template>
                </component-input>

                <div class="col-span-3">
                  <component-textarea
                    v-model="form.info"
                    label="Weiteres zum Patienten"
                    :validation="form.errors.info"
                    :privacy-guard="privacyErrors.info"
                    :test-id="testId + '-record-share-dialog-expert-tab-info'"
                  >
                    <template #privacy-guard>
                      <component-privacy-guard type="strict" :validation="privacyErrors.info" />
                    </template>
                  </component-textarea>
                </div>
              </div>

              <h6 class="mt-4 font-semibold">Kontaktdaten</h6>
              <div class="grid grid-cols-4 gap-4">
                <component-input
                  v-model="form.meta.contact_name"
                  class="col-span-2"
                  label="Name Bearbeiter*"
                  :validation="form.errors['meta.contact_name']"
                  :test-id="testId + '-record-share-dialog-expert-tab-meta-contact-name'"
                />
                <component-input
                  v-model="form.meta.contact_company"
                  class="col-span-2"
                  label="Apothekenname*"
                  :validation="form.errors['meta.contact_company']"
                  :test-id="testId + '-record-share-dialog-expert-tab-meta-contact-company'"
                />
                <component-input
                  v-model="form.meta.contact_email"
                  class="col-span-2"
                  label="E-Mail*"
                  :validation="form.errors['meta.contact_email']"
                  :test-id="testId + '-record-share-dialog-expert-tab-meta-contact-email'"
                />
                <component-input
                  v-model="form.meta.contact_telephone"
                  class="col-span-2"
                  label="Telefon*"
                  :validation="form.errors['meta.contact_telephone']"
                  :test-id="testId + '-record-share-dialog-expert-tab-meta-contact-telephone'"
                />
                <component-textarea
                  v-model="form.meta.contact_information"
                  class="col-span-4"
                  label="Weitere Informationen zur Erreichbarkeit bei Rückfragen"
                  :validation="form.errors['meta.contact_information']"
                  :privacy-guard="privacyErrors.contact_information ?? undefined"
                  :test-id="testId + '-record-share-dialog-expert-tab-meta-contact-information'"
                />
              </div>
            </div>

            <div class="text-sm text-right">* Pflichtangaben</div>

            <div v-if="form.errors.server_side" class="text-mcred col-span-3">{{ form.errors.server_side }}</div>
          </div>
        </component-tab>
      </component-tabs>
    </template>

    <template #actions>
      <template v-if="!form.wasSuccessful">
        <component-button
          v-if="activeTab === 'direct'"
          class="p4umc-primary"
          label="Freigeben"
          :disabled="form.processing || privacyErrors.aliasname || privacyErrors.info"
          :test-id="testId + '-record-share-dialog-save'"
          @click="save()"
        />

        <component-button
          v-else-if="activeTab === 'link'"
          class="p4umc-primary"
          label="Link erstellen"
          :disabled="form.processing || privacyErrors.aliasname || privacyErrors.info"
          :test-id="testId + '-record-share-dialog-save'"
          @click="save()"
        />

        <component-button
          v-else-if="activeTab === 'expert'"
          class="p4umc-primary"
          label="Freigeben"
          :disabled="
            form.processing ||
            privacyErrors.aliasname ||
            privacyErrors.info ||
            !hasPulseAndBloodpressure ||
            !hasMainMedicalComplaints ||
            !hasKnownIndications ||
            !hasPrioritisedResult
          "
          :test-id="testId + '-record-share-dialog-save'"
          @click="save()"
        />
      </template>

      <component-button
        :label="form.wasSuccessful ? 'Schließen' : 'Abbrechen'"
        :test-id="testId + '-record-share-dialog-close'"
        @click="close()"
      />
    </template>
  </component-dialog>
</template>

<script>
  import {computed, inject, onMounted, ref} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentTab from "@components/Tab/Tab.vue";
  import ComponentTabs from "@components/Tab/Tabs.vue";
  import ComponentTextarea from "@components/Textarea/Textarea.vue";

  import {pulseKey, bloodpressureKey} from "@pages/Records/Components/Sections/LaborBar/enums.js";
  import {recordNotifications, sharedrecordStatus} from "@pages/Records/Enums/Enums.js";
  import {usePrivacyGuard} from "@utils/Helpers/PrivacyGuard.js";
  import ComponentPrivacyGuard from "@components/Alerts/PrivacyGuard.vue";

  export default {
    name: "ComponentRecordShareDialog",

    components: {
      ComponentPrivacyGuard,
      ComponentButton,
      ComponentCheckbox,
      ComponentDialog,
      ComponentIcon,
      ComponentInput,
      ComponentTab,
      ComponentTabs,
      ComponentTextarea,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: [Object, null],
        default: null,
      },
    },

    expose: ["open", "close"],

    setup(props) {
      const privacy = inject("$privacy");
      const pageProps = usePage().props;
      const refDialog = ref(null);
      const refShareLinkUrl = ref(null);
      const {validatePrivacy} = usePrivacyGuard();

      const currentPatient = ref(props.patient);
      const currentRecord = ref(props.record);
      const isLoading = ref(false);
      const shareLinkUrl = ref(null);
      const copyingShareLinkUrl = ref(false);

      const activeTab = ref("direct");

      // checklist for expertservice
      const hasPulseAndBloodpressure = ref(false);
      const hasMainMedicalComplaints = ref(false);
      const hasKnownIndications = ref(false);
      const hasPrioritisedResult = ref(false);

      const tabTitles = {
        direct: "Direktfreigabe",
        link: "Linkfreigabe",
        expert: "Expertenservice",
      };

      let form = useForm({
        email: "",
        notify_recipient: true,
        info: null,
        aliasname: "",
        meta: {
          contact_name:
            currentRecord.value?.username ??
            ((pageProps?.client?.first_name ?? " ") + " " + (pageProps?.client?.last_name ?? " ")).trim() ??
            null,
          contact_company: pageProps?.client?.company ?? null,
          contact_email: pageProps?.client?.email ?? null,
          contact_telephone: pageProps?.client?.telephone ?? null,
          contact_information: null,
        },
      });

      onMounted(() => {
        privacy.decryptPatient(props.patient).then((decryptedPatient) => {
          currentPatient.value = decryptedPatient;
        });
      });

      const dialogTitle = computed(() => {
        if (activeTab.value === "direct") {
          if (form.wasSuccessful) {
            return "Variante wurde geteilt";
          } else if (form.errors.length) {
            return "Variante konnte nicht geteilt werden";
          }
          return "Mit wem möchten Sie diese Variante teilen?";
        } else if (activeTab.value === "link") {
          if (form.wasSuccessful) {
            return "Seminar- / Linkfreigabe wurde erstellt";
          } else if (form.errors.length) {
            return "Seminar - / Linkfreigabe konnte nicht erstellt werden";
          }
          return "Seminar- / Linkfreigabe";
        } else if (activeTab.value === "expert") {
          if (form.wasSuccessful) {
            return "Variante wurde mit unserem Expertenservice geteilt";
          } else if (form.errors.length) {
            return "Variante konnte nicht an unseren Expertenservice weitergeleitet werden";
          }
          return "Fragen Sie unseren Expertenservice";
        }

        return "";
      });

      const expertServiceRecipientMail = computed(
        () => import.meta.env?.VITE_EXPERT_SERVICE_RECIPIENT_MAIL ?? "medicheck-expertenteam@pharma4u.de",
      );

      const alreadySharedWithExpertService = computed(() => {
        return (currentRecord.value?.notifications ?? []).some((notification) => {
          return (
            notification.type === recordNotifications.sharedByMe &&
            notification.status === sharedrecordStatus.active &&
            notification.partner_email === expertServiceRecipientMail.value
          );
        });
      });

      const privacyErrors = computed(() => {
        return {
          aliasname: validatePrivacy(form.aliasname, "Alias-Name"),
          info: validatePrivacy(form.info, "Text"),
          contact_information: validatePrivacy(form.meta.contact_information, "Kontaktinformationen"),
        };
      });

      const tabChanged = (newActiveTabTitle) => {
        form.clearErrors();
        form.wasSuccessful = false;

        hasPulseAndBloodpressure.value =
          (currentRecord.value?.laborvalues ?? []).some(
            (laborvalue) => laborvalue.mc_laborvalue_key === bloodpressureKey,
          ) && (currentRecord.value?.laborvalues ?? []).some((laborvalue) => laborvalue.mc_laborvalue_key === pulseKey);
        hasKnownIndications.value = false;
        hasMainMedicalComplaints.value = false;
        hasPrioritisedResult.value = false;

        // normalize internal "activeTab" according to title string
        switch (newActiveTabTitle) {
          case tabTitles.direct:
            activeTab.value = "direct";
            break;
          case tabTitles.link:
            activeTab.value = "link";
            break;
          case tabTitles.expert:
            activeTab.value = "expert";
            break;
        }
      };

      function open(record) {
        activeTab.value = "direct";
        // set defaults
        form.email = "";
        form.notify_recipient = true;
        form.aliasname = props.patient?.aliasname ?? "";

        if (typeof record !== "undefined") {
          currentRecord.value = record;
        }

        privacy.decryptValue(currentRecord.value.info).then((plaintext) => {
          if (plaintext !== null) {
            form.info = plaintext;
          }
        });

        // set initial value if record contains bloodpressure and pulse
        hasPulseAndBloodpressure.value =
          (currentRecord.value?.laborvalues ?? []).some(
            (laborvalue) => laborvalue.mc_laborvalue_key === bloodpressureKey,
          ) && (currentRecord.value?.laborvalues ?? []).some((laborvalue) => laborvalue.mc_laborvalue_key === pulseKey);

        // set initial value if record contains username
        if (currentRecord.value?.username) {
          form.meta.contact_name = currentRecord.value?.username;
        }

        refDialog.value.open();
      }

      function close() {
        form.reset();
        form.clearErrors();
        form.wasSuccessful = false;
        refDialog.value.close();
      }

      function save() {
        isLoading.value = true;
        form.clearErrors();

        if (activeTab.value === "direct") {
          // store shared record
          form
            .transform((data) => {
              delete data.meta;
              return data;
            })
            .post(
              route("sharedrecords.store", {
                patient: props.patient.id,
                record: currentRecord.value.id,
              }),
              {
                preserveScroll: true,
                onFinish: () => {
                  isLoading.value = false;
                },
                onError: (error) => {
                  if (error.exception) {
                    console.error(error.exception);
                  }
                  form.setError(
                    "server_side",
                    "Bei der Verarbeitung ist leider ein Fehler aufgetreten. Bitte prüfen Sie die E-Mail-Adresse des Empfängers.",
                  );
                },
              },
            );
        } else if (activeTab.value === "link") {
          // store share link
          form
            .transform((data) => {
              delete data.email;
              delete data.notify_recipient;
              delete data.meta;
              return data;
            })
            .post(
              route("sharelinks.store", {
                patient: props.patient.id,
                record: currentRecord.value.id,
              }),
              {
                preserveScroll: true,
                onFinish: () => {
                  isLoading.value = false;
                },
                onSuccess: (response) => {
                  shareLinkUrl.value = response.props.flash.payload;
                  form.wasSuccessful = true;
                },
                onError: (error) => {
                  if (error.exception) {
                    console.error(error.exception);
                  }

                  form.setError("server_side", "Leider ist ein Fehler aufgetreten.");
                },
              },
            );
        } else if (activeTab.value === "expert") {
          // store expert service
          form
            .transform((data) => ({
              ...data,
              ...{
                type: "expert",
                email: expertServiceRecipientMail.value,
                notify_recipient: true,
                isExpert: true,
              },
            }))
            .post(
              route("sharedrecords.store", {
                patient: props.patient.id,
                record: currentRecord.value.id,
              }),
              {
                preserveScroll: true,
                onFinish: () => {
                  isLoading.value = false;
                },
                onError: (error) => {
                  if (error.url) {
                    // that's not an error but the only method receive a
                    // response url without throwing the inertia form
                    // helper away and using a plain ajax request 🤯
                    shareLinkUrl.value = error.url;
                    form.wasSuccessful = true;
                  } else {
                    if (error.exception) {
                      // Well, that's an error.
                      console.error(error.exception);
                    }

                    form.setError("server_side", "Leider ist ein Fehler aufgetreten.");
                  }
                },
              },
            );
        }
      }

      function copyToClipboard() {
        copyingShareLinkUrl.value = true;

        setTimeout(() => {
          copyingShareLinkUrl.value = false;
        }, 1000);

        // Copy link to clipboard
        navigator.clipboard.writeText(refShareLinkUrl.value.value).catch((err) => {
          console.error("Fehler beim Kopieren des Textes: ", err);
        });
      }

      return {
        /** ref */
        refDialog,
        refShareLinkUrl,

        /** const */
        activeTab,
        alreadySharedWithExpertService,
        copyingShareLinkUrl,
        currentPatient,
        privacyErrors,
        expertServiceRecipientMail,
        form,
        hasKnownIndications,
        hasMainMedicalComplaints,
        hasPrioritisedResult,
        hasPulseAndBloodpressure,
        isLoading,
        shareLinkUrl,
        tabTitles,

        /** computed */
        dialogTitle,

        /** function */
        tabChanged,
        open,
        close,
        save,
        copyToClipboard,
      };
    },
  };
</script>
