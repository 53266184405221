<template>
  <section class="flex flex-col sm:flex-row w-full sm:space-x-8 print:hidden">
    <component-tabs class="w-full" :active-tab="activeTab" @click="tabChanged">
      <component-tab title="Arztadresse">
        <div class="flex items-baseline justify-between">
          <h4 class="px-2 py-1 mt-1.5 cursor-default">An:</h4>
          <component-button tabindex="-1" label="Kontakt wählen" @click="openPatientContactSelectDialog()" />
        </div>

        <component-editor
          v-model="form.doctoraddress"
          :encrypted="true"
          :hide-toolbar="true"
          :enable-resize="false"
          editor-class="min-h-40 h-full"
          :disabled="form.processing && lastUpdatedField === 'doctoraddress'"
          @blur="save('doctoraddress')"
        />
      </component-tab>

      <component-tab title="Patientenadresse">
        <div class="flex items-baseline justify-between">
          <h4 class="px-2 py-1 mt-1.5 cursor-default">An:</h4>
          <component-button
            v-if="patientAddress"
            tabindex="-1"
            label="Patientenanschrift einfügen"
            @click="insertPatientAddress()"
          />
        </div>

        <component-editor
          v-model="form.patientaddress"
          :encrypted="true"
          :hide-toolbar="true"
          :enable-resize="false"
          editor-class="min-h-40 h-full"
          :disabled="form.processing && lastUpdatedField === 'patientaddress'"
          @blur="save('patientaddress')"
        />
      </component-tab>
    </component-tabs>

    <div class="w-full mt-14">
      <div class="flex items-baseline justify-between">
        <h4 class="px-2 py-1 mt-1.5 cursor-default">Von:</h4>
      </div>

      <component-editor
        v-model="form.useraddress"
        :encrypted="true"
        :hide-toolbar="true"
        :enable-resize="false"
        editor-class="min-h-40 h-full"
        :disabled="form.processing && lastUpdatedField === 'useraddress'"
        @blur="save('useraddress')"
      />
    </div>
  </section>

  <!-- print -->
  <section class="w-full !mt-0 pb-8 hidden space-x-8 print:flex print:flex-row">
    <div class="min-h-[4cm] bg-gray-200 p-3 w-full border-gray-300 border-b-2 border-t-2">
      An:
      <div class="hidden pt-1 [.print-doc_&]:print:block" data-format="html" :data-encrypted="form.doctoraddress" />
      <div
        class="hidden pt-1 [.print-patient_&]:print:block"
        data-format="html"
        :data-encrypted="form.patientaddress"
      />
    </div>

    <div class="min-h-[4cm] bg-gray-200 p-3 w-full border-gray-300 border-b-2 border-t-2">
      Von:
      <div class="pt-1" data-format="html" :data-encrypted="form.useraddress" />
    </div>
  </section>

  <component-patient-contact-select-dialog
    ref="refPatientAddressBookDialog"
    :patient="patient"
    :test-id="testId + '-patient-sidebar'"
    @contact-selected="insertPatientContactAddress"
  />
</template>

<script>
  import {computed, inject, ref} from "vue";
  import {useForm, usePage} from "@inertiajs/vue3";

  import ComponentTabs from "@components/Tab/Tabs.vue";
  import ComponentTab from "@components/Tab/Tab.vue";
  import ComponentEditor from "@components/Editor/Editor.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentPatientContactSelectDialog from "@components/Dialogs/PatientContactSelectDialog.vue";

  export default {
    name: "PagesRecordResultAddresses",

    components: {ComponentPatientContactSelectDialog, ComponentButton, ComponentEditor, ComponentTab, ComponentTabs},

    props: {
      /** @var Object patient is an already decrypted (!) patient */
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
      transfer: {
        type: Object,
        default: null,
      },
    },

    setup(props) {
      const privacy = inject("$privacy");

      const activeTab = ref("Arztadresse");
      const lastUpdatedField = ref(null);

      const refPatientAddressBookDialog = ref(null);

      const patientAddress = computed(() => {
        return props.patient.patient_attributes.find((attribute) => attribute.type === "patientAddress");
      });

      const form = useForm({
        useraddress: initUserAddress(),
        doctoraddress: initDoctorAddress(),
        patientaddress: initPatientAddress(),
      });

      const tabChanged = (newActiveTab) => {
        activeTab.value = newActiveTab;
      };

      function save(field) {
        if (form.isDirty) {
          lastUpdatedField.value = field;
          form
            .transform((data) => {
              switch (field) {
                case "useraddress":
                  return {useraddress: data.useraddress};
                case "doctoraddress":
                  return {doctoraddress: data.doctoraddress};
                case "patientaddress":
                  return {patientaddress: data.patientaddress};
              }
            })
            .patch(route("records.update", {patient: props.patient.id, record: props.record.id}), {});
        }
      }

      function getAddressText(
        title,
        userName,
        firstName,
        lastName,
        company,
        addressSupplement,
        street,
        zipCode,
        cityName,
        phoneNumber,
        eMail,
        faxNumber,
      ) {
        let rows = [];

        // title, firstName, lastName, company
        let nameRow;
        if (userName) {
          nameRow = userName;
        } else {
          nameRow = ((title ?? "") + " " + (firstName ?? "") + " " + (lastName ?? "")).trim();
        }
        if (company) {
          // add comma before company, if we have a name
          nameRow += nameRow === "" ? company : ", " + company;
        }
        if (nameRow) {
          rows.push(nameRow);
        }

        // addressSupplement
        if (addressSupplement) {
          rows.push(addressSupplement);
        }

        // street
        if (street) {
          rows.push(street);
        }

        // zipCode, cityName
        if (zipCode || cityName) {
          rows.push(((zipCode ?? "") + " " + (cityName ?? "")).trim());
        }

        // phoneNumber
        if (phoneNumber) {
          rows.push("Telefon: " + phoneNumber);
        }

        // eMail
        if (eMail) {
          rows.push("E-Mail: " + eMail);
        }

        // faxNumber
        if (faxNumber) {
          rows.push("Fax: " + faxNumber);
        }

        return rows.length ? "<p>" + rows.join("<br>") + "</p>" : null;
      }

      function initUserAddress() {
        // use already stored value
        if (props.record.useraddress) {
          return props.record.useraddress;
        } else if (props.patient.is_fake) {
          return null;
        } else if (
          props.transfer?.pharmacy &&
          (props.transfer?.pharmacy?.first_name ||
            props.transfer?.pharmacy?.last_name ||
            props.transfer?.pharmacy?.company_name)
        ) {
          // get from wawi via transfer.data.pharmacy
          const pharmacy = props.transfer?.pharmacy;
          return getAddressText(
            null,
            null,
            pharmacy?.first_name,
            pharmacy?.last_name,
            pharmacy?.company_name,
            null,
            pharmacy?.street_address,
            pharmacy?.postal_code,
            pharmacy?.city,
            pharmacy?.telephone,
            pharmacy?.email,
            null,
          );
        } else {
          // get from p4uApi via $page.props.client
          const client = usePage().props.client;
          return getAddressText(
            null,
            props.record.username,
            client?.first_name,
            client?.last_name,
            client?.company,
            null,
            client?.address,
            client?.zip,
            client?.city,
            client?.telephone,
            client?.email,
            client?.fax,
          );
        }
      }
      function initDoctorAddress() {
        // use already stored value
        if (props.record.doctoraddress) {
          return props.record.doctoraddress;
        } else if (props.patient.is_fake || props.record.shared_with_me) {
          return null;
        } else if (
          props.transfer?.doctor &&
          (props.transfer?.doctor?.first_name ||
            props.transfer?.doctor?.last_name ||
            props.transfer?.doctor?.company_name)
        ) {
          const doctor = props.transfer?.doctor;
          return getAddressText(
            null,
            null,
            doctor.first_name,
            doctor.last_name,
            doctor.company_name,
            null,
            doctor.street_address,
            doctor.postal_code,
            doctor.city,
            doctor.telephone,
            doctor.email,
            null,
          );
        }
      }

      function initPatientAddress() {
        // use already stored value
        if (props.record.patientaddress) {
          return props.record.patientaddress;
        } else if (props.patient.is_fake || props.record.shared_with_me) {
          return null;
        } else if (props.patient?.firstname || props.patient?.lastname) {
          // at least use name of patient
          return "<p>" + props.patient.firstname + " " + props.patient.lastname + "</p>";
        }
      }

      function openPatientContactSelectDialog() {
        refPatientAddressBookDialog.value.open();
      }

      function insertPatientContactAddress(contact) {
        const addressText = getAddressText(
          contact.title,
          null,
          contact.firstname,
          contact.lastname,
          contact.company,
          null,
          contact.street,
          contact.zip,
          contact.city,
          contact.telephone,
          contact.email,
          contact.fax,
        );

        privacy.encryptValue(addressText).then((res) => {
          form.doctoraddress = res;
          save("doctoraddress");
        });
      }

      async function insertPatientAddress() {
        const patient = props.patient;

        let decryptedPatientAddress = null;
        if (patientAddress.value) {
          decryptedPatientAddress = await privacy.decryptPatientAttribute(patientAddress.value);
        }

        const addressText = getAddressText(
          patient.title,
          null,
          patient.firstname,
          patient.lastname,
          null,
          decryptedPatientAddress?.payload?.supplement,
          decryptedPatientAddress?.payload?.street,
          decryptedPatientAddress?.payload?.zip,
          decryptedPatientAddress?.payload?.city,
          decryptedPatientAddress?.payload?.telephone,
          decryptedPatientAddress?.payload?.email,
          null,
        );

        privacy.encryptValue(addressText).then((res) => {
          form.patientaddress = res;
          save("patientaddress");
        });
      }

      return {
        /* const */
        activeTab,
        form,
        lastUpdatedField,
        refPatientAddressBookDialog,

        /* computed */
        patientAddress,

        /* functions */
        save,
        tabChanged,
        openPatientContactSelectDialog,
        insertPatientContactAddress,
        insertPatientAddress,
      };
    },
  };
</script>
