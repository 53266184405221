<template>
  <div>
    <dashboard-widget :test-id="testId + '-first-steps'">
      <template #title>
        <inertia-link
          :href="$route('documents.index')"
          class="group hover:no-underline flex items-baseline space-x-3"
          :data-test="testId + '-first-step-widget-route-to-document-index-inertia-link-icon-button'"
        >
          <component-icon class="p-2 bg-mcblue group-hover:bg-mcblue-450 rounded-full text-white font-normal">
            question_mark
          </component-icon>

          <span class="text-gray-700 group-hover:text-gray-900">Erste Schritte</span>
        </inertia-link>
      </template>

      <template #default>
        <div
          class="grid grid-cols-1 gap-2 lg:gap-12"
          :class="{'lg:grid-cols-3': $page.props.can.create_records, 'lg:grid-cols-2': !$page.props.can.create_records}"
        >
          <p>
            Neueinsteigern empfehlen wir einen Blick in unsere

            <template v-if="hasThirdParty('nident')">
              <!-- Gebrauchsanweisung Connect -->
              <a
                href="https://medicheck.pharma4u.de/url-redirect/manual-medicheck-wawi"
                class="font-semibold"
                target="_blank"
                :data-test="testId + '-first-step-widget-merchandise-management-link'"
              >
                Gebrauchsanweisung MediCheck Connect
              </a>
              (PDF-Datei).
            </template>

            <template v-else-if="hasThirdParty('p4u')">
              <!-- Gebrauchsanweisung Online Pro (2 Versionen, kurz und lang) -->
              <a
                href="https://medicheck.pharma4u.de/url-redirect/short-manual-medicheck"
                class="font-semibold"
                target="_blank"
                :data-test="testId + '-first-step-widget-instructions-link-short'"
              >
                Kurzanleitung MediCheck Online Pro
              </a>
              oder die
              <a
                href="https://medicheck.pharma4u.de/url-redirect/manual-medicheck"
                class="font-semibold"
                target="_blank"
                :data-test="testId + '-first-step-widget-instructions-link'"
              >
                Gebrauchsanweisung MediCheck Online Pro
              </a>
              (PDF-Dateien).
            </template>
          </p>

          <p>
            Weitere hilfereiche Dokumente finden Sie in unseren

            <inertia-link
              class="font-semibold"
              :href="$route('documents.index')"
              :data-test="testId + '-first-step-widget-route-to-document-index-inertia-link-text'"
            >
              Arbeitshilfen
            </inertia-link>

            (jederzeit im Hauptmenü unter „mehr“).
          </p>

          <p v-if="$page.props.can.create_records">
            Wenn Sie den MediCheck selbst erkunden möchten, bieten sich unsere

            <a
              class="font-semibold"
              :data-test="testId + '-first-step-widget-open-demo-data-dialog-button'"
              @click.prevent="openDemoDataDialog()"
            >
              Beispielfälle
            </a>

            an.
          </p>
        </div>
      </template>
    </dashboard-widget>

    <component-demodata-dialog ref="refDemoDataDialog" :test-id="testId + '-first-step-widget'" />
  </div>
</template>

<script>
  import {inject, ref} from "vue";
  import {Link as InertiaLink} from "@inertiajs/vue3";

  import ComponentDemodataDialog from "@components/Dialogs/DemodataDialog/DemodataDialog.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  import DashboardWidget from "./Widget.vue";

  export default {
    name: "DashboardFirstStepsWidgetWide",

    components: {ComponentDemodataDialog, DashboardWidget, ComponentIcon, InertiaLink},

    setup() {
      const hasThirdParty = inject("$hasThirdParty");

      const refDemoDataDialog = ref(null);

      const openDemoDataDialog = () => {
        refDemoDataDialog.value.open();
      };

      return {
        /** inject */
        hasThirdParty,

        /** ref */
        refDemoDataDialog,

        /** function */
        openDemoDataDialog,
      };
    },
  };
</script>
