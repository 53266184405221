<template>
  <div :class="['animate-pulse bg-gray-300 rounded-xl print:hidden', classNames, $attrs.class]" />
</template>

<script>
  export default {
    name: "SkeletonLoader",

    props: {
      classNames: {
        type: String,
        default: "",
      },
    },
  };
</script>
