<template>
  <div>
    <component-alert v-if="type === 'strict'" :type="validation ? 'error' : 'info'">
      <component-icon class="inline-block">lock</component-icon>
      {{ validation ?? "Bitte keine personenbezogenen Daten eingeben." }}
    </component-alert>
    <div v-else :class="{'text-gray-500': !validation, 'text-mcred': validation}">
      <component-icon class="inline-block">lock</component-icon>
      {{ validation ?? "Bitte keine personenbezogenen Daten eingeben." }}
    </div>
  </div>
</template>
<script>
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  export default {
    name: "ComponentPrivacyGuard",
    components: {ComponentIcon, ComponentAlert},
    props: {
      type: {
        type: String,
        default: "default", // 'strict'
      },
      validation: {
        type: String,
        default: null,
      },
    },
  };
</script>
