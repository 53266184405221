<template>
  <li class="grid grid-cols-12 gap-x-6 p-2 border-gray-300 animate-pulse">
    <div class="h-[75px] col-span-2">
      <skeleton-loader class-names="m-1 w-1/2 h-[17px]" />
      <skeleton-loader class-names="m-1 w-full h-[35px]" />
    </div>

    <div class="h-[75px] col-span-3">
      <skeleton-loader class-names="m-1 w-1/2 h-[17px]" />
      <skeleton-loader class-names="m-1 w-full h-[35px]" />
    </div>

    <div class="h-[75px] col-span-2">
      <skeleton-loader class-names="m-1 w-1/2 h-[17px]" />
      <skeleton-loader class-names="m-1 w-full h-[35px]" />
    </div>

    <div class="h-[75px] col-span-3">
      <skeleton-loader class-names="m-1 w-1/2 h-[17px]" />
      <skeleton-loader class-names="m-1 w-full h-[35px]" />
    </div>

    <div class="h-[75px] col-span-2">
      <skeleton-loader class-names="m-1 w-1/2 h-[17px]" />
      <skeleton-loader class-names="m-1 w-full h-[35px]" />
    </div>
  </li>
</template>

<script>
  import SkeletonLoader from "@components/SkeletonLoader.vue";

  export default {
    name: "LaborValueSkeletonRowMainValues",

    components: {SkeletonLoader: SkeletonLoader},
  };
</script>
