<template>
  <template v-if="decryptedPatient">
    <component-breadcrumb
      class="mt-2 print:hidden"
      :links="[
        {
          label: 'Patienten',
          url: patient.is_fake ? $route('patients.index', {filter: 'shared-with-me'}) : $route('patients.index'),
        },
        {
          encryptedLabel: [decryptedPatient.firstname, decryptedPatient.lastname],
          url: $route('patients.show', {patient: decryptedPatient.id}),
        },
        {
          label: 'Eingabe',
          subLabel: {
            component: 'component-record-label',
            props: {
              record: record,
            },
          },
          url: $route('records.edit', {patient: decryptedPatient.id, record: record.id}),
        },
        {
          label: 'Analyse',
        },
      ]"
    />

    <section
      class="relative mt-6 flex items-start space-x-6 print:space-x-0 print:mt-0"
      :class="{
        'print-archive': !resultConfig.showCommunicationDoc && !resultConfig.showCommunicationPatient,
        'print-patient': resultConfig.showCommunicationPatient,
        'print-doc': resultConfig.showCommunicationDoc,
      }"
    >
      <div class="print:hidden sticky top-20 z-10">
        <inertia-deferred :data="['transfer']">
          <template #fallback />
          <component-patient-sidebar :open="false" :patient="decryptedPatient" :record="record" :transfer="transfer" />
        </inertia-deferred>
      </div>

      <article class="flex-1 space-y-6">
        <!-- article::main -->
        <div
          class="w-10/12 sm:w-11/12 max-w-screen-xl p-4 bg-white shadow-sm space-y-8 print:w-full print:max-w-none print:p-0 print:shadow-none"
        >
          <div class="flex space-x-1 justify-end print:hidden">
            <span class="text-xs m-auto mr-2">
              Gespeichert:
              <span class="text-xs" v-text="record.updated_at_datetime" />
            </span>

            <inertia-deferred data="transfer">
              <template #fallback>
                <skeleton-loader class="size-8" />
                <skeleton-loader class="size-8" />
                <skeleton-loader class="size-8" />
              </template>
              <component-record-progress
                :record="record"
                :transfer="transfer"
                :is-on-result-page="true"
                @open:record-case-close-dialog="openRecordCaseCloseDialog"
              />
            </inertia-deferred>
          </div>

          <inertia-deferred data="sharedata">
            <template #fallback><skeleton-loader class="w-full h-32" /></template>
            <pages-record-result-head :record="record" :sharedata="sharedata">/></pages-record-result-head>
          </inertia-deferred>

          <inertia-deferred data="transfer">
            <template #fallback>
              <div class="flex space-x-8">
                <skeleton-loader class="w-full h-60" />
                <skeleton-loader class="w-full h-60" />
              </div>
            </template>
            <pages-record-result-addresses :patient="decryptedPatient" :record="record" :transfer="transfer" />
          </inertia-deferred>

          <inertia-deferred data="sharedata">
            <template #fallback />
            <pages-record-general-share-status
              v-if="hasThirdParty('p4u')"
              :patient="decryptedPatient"
              :record="record"
              :sharedata="sharedata"
              @open:share-status-dialog="openShareStatusDialog()"
            />
          </inertia-deferred>

          <inertia-deferred :data="['symptoms', 'diseases', 'laborvalues']">
            <template #fallback><pages-record-result-patient-data skeleton /></template>
            <pages-record-result-patient-data
              :patient="decryptedPatient"
              :record="record"
              :symptoms="symptoms.data"
              :diseases="diseases.data"
              :laborvalues="laborvalues.data"
            />
          </inertia-deferred>

          <inertia-deferred data="medications">
            <template #fallback><pages-record-result-medication-table skeleton /></template>
            <pages-record-result-medication-table :medications="medications.data" />
          </inertia-deferred>

          <pages-record-result-list-filter />

          <pages-record-result-summary-for-print :record="record" />

          <inertia-deferred data="checks">
            <template #fallback>
              <div class="flex space-x-8">
                <skeleton-loader class="w-full h-60 bg-mcgreen-100" />
                <skeleton-loader class="w-full h-40 bg-mcred-100" />
              </div>
            </template>

            <pages-record-result-overview
              :greenlights="greenlights"
              :symptomatic-redlights="symptomaticRedlights"
              :prognostic-redlights="prognosticRedlights"
              :non-compliance-redlights="nonComplianceRedlights"
              :prevention-redlights="preventionRedlights"
            />

            <pages-record-result-symptomatic-results
              :patient-id="decryptedPatient.id"
              :record-id="record.id"
              :symptoms="record.symptoms"
              :symptomatic-redlights="symptomaticRedlights"
              @open:share-dialog="openShareDialog()"
            />

            <pages-record-result-prognostic-results
              :patient-id="decryptedPatient.id"
              :record-id="record.id"
              :prognostic-redlights="prognosticRedlights"
              @open:share-dialog="openShareDialog()"
            />

            <pages-record-result-non-compliance-results
              :patient-id="decryptedPatient.id"
              :record-id="record.id"
              :non-compliance-redlights="nonComplianceRedlights"
              @open:share-dialog="openShareDialog()"
            />

            <pages-record-result-prevention-results
              :patient-id="decryptedPatient.id"
              :record-id="record.id"
              :prevention-redlights="preventionRedlights"
              @open:share-dialog="openShareDialog()"
            />
          </inertia-deferred>

          <pages-record-result-summary :record="record" @open:share-dialog="openShareDialog()" />

          <pages-record-result-signature :record="record" />
        </div>

        <div class="w-10/12 sm:w-11/12 max-w-screen-xl p-4 bg-white shadow-sm space-y-16 print:hidden">
          <inertia-deferred :data="['transfer', 'sharedata']">
            <template #fallback>
              <skeleton-loader class="w-full h-40" />
            </template>
            <pages-record-result-next-steps
              :patient="decryptedPatient"
              :record="record"
              :transfer="transfer"
              :sharedata="sharedata"
              @open:share-dialog="openShareDialog()"
              @open:share-status-dialog="openShareStatusDialog()"
              @open:record-case-close-dialog="openRecordCaseCloseDialog"
            />
          </inertia-deferred>
        </div>
      </article>

      <component-record-share-dialog ref="refShareDialog" :record="record" :patient="decryptedPatient" />
      <inertia-deferred :data="['sharedata', 'notifications']">
        <template #fallback />
        <component-record-share-status-dialog
          ref="refShareStatusDialog"
          :patient="decryptedPatient"
          :record="record"
          :sharedata="sharedata"
          :notifications="notifications?.data"
        />
      </inertia-deferred>

      <inertia-deferred data="transfer">
        <template #fallback />
        <component-record-case-close-dialog
          ref="refRecordCaseCloseDialog"
          :patient="decryptedPatient"
          :record="record"
          :transfer="transfer"
        />
      </inertia-deferred>
      <component-record-invalidated-dialog :record="record" />
    </section>
  </template>
</template>

<script>
  import {Deferred as InertiaDeferred} from "@inertiajs/vue3";
  import {computed, inject, onMounted, ref, provide, watch, nextTick, onBeforeUnmount} from "vue";
  import {router, useForm, usePage} from "@inertiajs/vue3";
  import {debounce, clone} from "lodash";

  import ComponentBreadcrumb from "@components/Breadcrumb/Breadcrumb.vue";
  import ComponentPatientSidebar from "@components/Sidebars/PatientSidebar.vue";
  import ComponentRecordCaseCloseDialog from "@components/Dialogs/RecordCaseCloseDialog.vue";
  import ComponentRecordInvalidatedDialog from "@components/Dialogs/RecordInvalidatedDialog.vue";

  import ComponentRecordProgress from "@pages/Records/Components/RecordProgress.vue";
  import ComponentRecordShareDialog from "@components/Dialogs/RecordShareDialog.vue";
  import ComponentRecordShareStatusDialog from "@components/Dialogs/RecordShareStatusDialog.vue";
  import PagesRecordResultAddresses from "@pages/Records/Components/Result/Addresses.vue";
  import PagesRecordResultHead from "@pages/Records/Components/Result/Head.vue";
  import PagesRecordResultListFilter from "@pages/Records/Components/Result/ResultList/Filter.vue";
  import PagesRecordResultMedicationTable from "@pages/Records/Components/Result/MedicationTable.vue";
  import PagesRecordResultNextSteps from "@pages/Records/Components/Result/NextSteps.vue";
  import PagesRecordResultNonComplianceResults from "@pages/Records/Components/Result/NonComplianceResults.vue";
  import PagesRecordResultOverview from "@pages/Records/Components/Result/Overview.vue";
  import PagesRecordResultPatientData from "@pages/Records/Components/Result/PatientData.vue";
  import PagesRecordResultPreventionResults from "@pages/Records/Components/Result/PreventionResults.vue";
  import PagesRecordResultPrognosticResults from "@pages/Records/Components/Result/PrognosticResults.vue";
  import PagesRecordGeneralShareStatus from "@pages/Records/Components/General/ShareStatus.vue";
  import PagesRecordResultSignature from "@pages/Records/Components/Result/Signature.vue";
  import PagesRecordResultSummary from "@pages/Records/Components/Result/ResultSummary.vue";
  import PagesRecordResultSummaryForPrint from "@pages/Records/Components/Result/ResultSummaryForPrint.vue";
  import PagesRecordResultSymptomaticResults from "@pages/Records/Components/Result/SymptomaticResults.vue";
  import {analysisTypeGroupedByCheckId} from "@pages/Records/Components/Result/Enums.js";
  import hasThirdParty from "@utils/Helpers/HasThirdParty.js";
  import SkeletonLoader from "@components/SkeletonLoader.vue";

  export default {
    name: "PagesRecordShow",

    components: {
      SkeletonLoader: SkeletonLoader,
      InertiaDeferred,
      ComponentBreadcrumb,
      ComponentPatientSidebar,
      ComponentRecordCaseCloseDialog,
      ComponentRecordInvalidatedDialog,
      ComponentRecordProgress,
      ComponentRecordShareDialog,
      ComponentRecordShareStatusDialog,
      PagesRecordResultAddresses,
      PagesRecordResultHead,
      PagesRecordResultListFilter,
      PagesRecordResultMedicationTable,
      PagesRecordResultNextSteps,
      PagesRecordResultNonComplianceResults,
      PagesRecordResultOverview,
      PagesRecordResultPatientData,
      PagesRecordResultPreventionResults,
      PagesRecordResultPrognosticResults,
      PagesRecordGeneralShareStatus,
      PagesRecordResultSignature,
      PagesRecordResultSummary,
      PagesRecordResultSummaryForPrint,
      PagesRecordResultSymptomaticResults,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
      medications: {
        type: Object,
        required: true,
      },
      symptoms: {
        type: Object,
        required: true,
      },
      diseases: {
        type: Object,
        required: true,
      },
      laborvalues: {
        type: Object,
        required: true,
      },
      checks: {
        type: Object,
        required: true,
      },
      transfer: {
        type: Object,
        default: null,
      },
      sharedata: {
        type: Object,
        required: true,
      },
      notifications: {
        type: Object,
        required: true,
      },
      comments: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const privacy = inject("$privacy");
      const broadcast = inject("$broadcast");
      const pageProps = usePage().props;

      // "replace" props.patient with a decrypted patient object
      const decryptedPatient = ref(null);
      const scrollPosition = ref(null);
      const stashedResultConfig = ref(null);
      const refShareDialog = ref(null);
      const triggerCloseClaim = ref(null);
      const refShareStatusDialog = ref(null);
      const refRecordCaseCloseDialog = ref(null);
      const comments = ref([]);
      const form = useForm({});
      const resultConfig = ref(
        props.record?.metadata?.resultconfig || {
          analysistype: props.record.analysistype ?? "2a",
          veryhighrisk: true,
          highrisk: true,
          midrisk: true,
          lowrisk: true,
          showNoncomplianceAndPrevention: true,
          showCommunicationDoc: false,
          showCommunicationPatient: false,
          defaultSetting: true,
        },
      );

      watch(
        () => props.comments,
        (newValue) => {
          comments.value = newValue?.data ?? [];
        },
      );

      onMounted(async () => {
        decryptedPatient.value = await privacy.decryptPatient(props.patient);

        broadcast.patient.onMessage(props.patient.id, handleBroadcastMessage);
        broadcast.record.onMessage(props.record.id, handleBroadcastMessage);
      });

      onBeforeUnmount(() => {
        broadcast.patient.offMessage(props.patient.id, handleBroadcastMessage);
        broadcast.record.offMessage(props.record.id, handleBroadcastMessage);
      });

      const sumCommunicationDoc = computed(() => {
        return (props?.checks?.data ?? []).reduce((totalSum, check) => {
          return (
            totalSum +
            check.claims.reduce((sum, claim) => {
              return sum + (claim.type === "redlight" && claim?.communicationdoc ? 1 : 0);
            }, 0)
          );
        }, 0);
      });

      const sumCommunicationPatient = computed(() => {
        return (props?.checks?.data ?? []).reduce((totalSum, check) => {
          return (
            totalSum +
            check.claims.reduce((sum, claim) => {
              return sum + (claim.type === "redlight" && claim?.communicationpatient ? 1 : 0);
            }, 0)
          );
        }, 0);
      });

      const greenlights = computed(() => {
        return (props?.checks?.data ?? []).filter(
          (check) => check.contains_redlight_claim === false && _filterCheckByResultConfig(check),
        );
      });

      const symptomaticRedlights = computed(() => {
        return (
          (props?.checks?.data ?? [])
            .filter(
              (check) =>
                check.contains_redlight_claim === true &&
                (check.group === "symptomatic" || check.group === "mixed") &&
                _filterCheckByResultConfig(check),
            )
            // filter the nested checks according to resultConfig
            .map((check) => {
              return {
                ...check,
                claims: _filterClaimsByResultConfig(check.claims),
              };
            })
            // now filter checks without any claims left
            .filter((check) => check.claims.length > 0)
        );
      });

      const prognosticRedlights = computed(() => {
        return (
          (props?.checks?.data ?? [])
            .filter(
              (check) =>
                check.contains_redlight_claim === true &&
                (check.group === "prognostic" || check.group === "mixed") &&
                _filterCheckByResultConfig(check),
            )
            // filter the nested checks according to resultConfig
            .map((check) => {
              return {
                ...check,
                claims: _filterClaimsByResultConfig(check.claims),
              };
            })
            // now filter checks without any claims left
            .filter((check) => check.claims.length > 0)
        );
      });

      const nonComplianceRedlights = computed(() => {
        // early filter according to resultConfig
        if (!resultConfig.value.showNoncomplianceAndPrevention) {
          return [];
        }

        return (
          (props?.checks?.data ?? [])
            .filter(
              (check) =>
                check.contains_redlight_claim === true &&
                check.group === "non_compliance" &&
                _filterCheckByResultConfig(check),
            )
            // filter the nested checks according to resultConfig
            .map((check) => {
              return {
                ...check,
                claims: _filterClaimsByResultConfig(check.claims),
              };
            })
            // now filter checks without any claims left
            .filter((check) => check.claims.length > 0)
        );
      });

      const preventionRedlights = computed(() => {
        // early filter according to resultConfig
        if (!resultConfig.value.showNoncomplianceAndPrevention) {
          return [];
        }

        return (
          (props?.checks?.data ?? [])
            .filter(
              (check) =>
                check.contains_redlight_claim === true &&
                check.group === "prevention" &&
                _filterCheckByResultConfig(check),
            )
            // filter the nested checks according to resultConfig
            .map((check) => {
              return {
                ...check,
                claims: _filterClaimsByResultConfig(check.claims),
              };
            })
            // now filter checks without any claims left
            .filter((check) => check.claims.length > 0)
        );
      });

      if (
        !(
          pageProps?.client?.users
            .find((user) => user.id === privacy.getCurrentUser()?.id)
            ?.configurations.find((configuration) => configuration.name === "doNotCloseClaimsAutomatically")?.amount ??
          false
        )
      ) {
        // if the user HAS NOT the doNotCloseClaimsAutomatically
        // configuration set to true, we provide a method to
        // close all claim siblings
        provide("closeClaimSiblings", (openClaim) => {
          triggerCloseClaim.value = openClaim;
        });
      }

      watch(
        resultConfig,
        (newValue) => {
          // re-activate other filters when communication filter is active
          if (newValue.showCommunicationDoc || newValue.showCommunicationPatient) {
            resultConfig.value.veryhighrisk = true;
            resultConfig.value.highrisk = true;
            resultConfig.value.midrisk = true;
            resultConfig.value.lowrisk = true;
            resultConfig.value.showNoncomplianceAndPrevention = true;
          }

          // set default setting
          resultConfig.value.defaultSetting =
            newValue.analysistype === (props.record.analysistype ?? "2a") &&
            newValue.veryhighrisk &&
            newValue.highrisk &&
            newValue.midrisk &&
            newValue.lowrisk &&
            newValue.showNoncomplianceAndPrevention &&
            !newValue.showCommunicationDoc &&
            !newValue.showCommunicationPatient;

          if (stashedResultConfig.value === null) {
            // save every change if not simulating the result config
            save();
          }
        },
        {deep: true},
      );

      const handleBroadcastMessage = (data) => {
        switch (data?.action) {
          case "refreshAfterReload.patient":
            privacy.decryptPatient(props.patient).then((decrypted) => {
              decryptedPatient.value = decrypted;
            });
            break;
          case "reload.record":
            router.reload();
            break;
        }
      };

      const save = debounce(function () {
        form
          .transform(() => {
            return {
              metadata: {
                resultconfig: resultConfig.value,
              },
            };
          })
          .patch(route("records.update", {patient: props.record.patient_id, record: props.record.id}), {
            preserveScroll: true,
            headers: {
              // avoid updating the edited_at values when just changing the filter
              "Mc-Silent-Update": true,
            },
          });
      }, 2000);

      function _filterCheckByResultConfig(check) {
        return analysisTypeGroupedByCheckId[resultConfig.value.analysistype].indexOf(check.mc_check_id) >= 0;
      }

      function _filterClaimsByResultConfig(claims) {
        return claims.filter((claim) => {
          if (!resultConfig.value.veryhighrisk && claim.class === "red") {
            return false;
          }
          if (!resultConfig.value.highrisk && claim.class === "orange") {
            return false;
          }
          if (!resultConfig.value.midrisk && claim.class === "yellow") {
            return false;
          }
          if (!resultConfig.value.lowrisk && (claim.class === "gray" || claim.class === "grey")) {
            return false;
          }

          if (
            resultConfig.value.showCommunicationDoc &&
            resultConfig.value.showCommunicationDoc &&
            !claim.communicationdoc &&
            !claim.communicationpatient
          ) {
            return false;
          }

          if (
            resultConfig.value.showCommunicationDoc &&
            !claim.communicationdoc &&
            !resultConfig.value.showCommunicationPatient
          ) {
            return false;
          }
          if (
            resultConfig.value.showCommunicationPatient &&
            !claim.communicationpatient &&
            !resultConfig.value.showCommunicationDoc
          ) {
            return false;
          }

          return true;
        });
      }

      function openShareDialog() {
        refShareDialog.value.open();
      }

      function openShareStatusDialog() {
        refShareStatusDialog.value.open();
      }

      function openRecordCaseCloseDialog() {
        refRecordCaseCloseDialog.value.open();
      }

      provide("sumCommunicationDoc", sumCommunicationDoc);

      provide("comments", comments);

      watch(
        () => props.sharedata,
        (newValue) => {
          if (typeof newValue !== "undefined") {
            // provide sharedWithMe instead of passing it through to ClaimInteractionChat component
            provide("sharedWithMe", props.sharedata.shared_with_me);
          }
        },
        {immediate: true},
      );

      provide("sumCommunicationPatient", sumCommunicationPatient);

      provide("resultConfig", resultConfig);

      provide("resetDefaultResultConfig", () => {
        resultConfig.value.analysistype = props.record.analysistype ?? "2a";
        resultConfig.value.veryhighrisk = true;
        resultConfig.value.highrisk = true;
        resultConfig.value.midrisk = true;
        resultConfig.value.lowrisk = true;
        resultConfig.value.showNoncomplianceAndPrevention = true;
        resultConfig.value.showCommunicationDoc = false;
        resultConfig.value.showCommunicationPatient = false;
        resultConfig.value.defaultSetting = true;
      });

      provide("simulateResultConfig", (key, value) => {
        if (stashedResultConfig.value === null) {
          stashedResultConfig.value = clone(resultConfig.value);
          // save the scroll position once (on the first call)
          if (scrollPosition.value === null) {
            scrollPosition.value = window.scrollY;
          }
        }
        resultConfig.value[key] = value;
      });

      provide("restoreResultConfig", () => {
        if (stashedResultConfig.value !== null) {
          resultConfig.value = clone(stashedResultConfig.value);
          nextTick(() => {
            // restore scroll position
            if (scrollPosition.value !== null) {
              window.scrollTo(0, scrollPosition.value);
              scrollPosition.value = null;
            }
            stashedResultConfig.value = null;
          });
        }
      });

      provide("triggerCloseClaim", triggerCloseClaim);

      return {
        hasThirdParty,
        decryptedPatient,
        greenlights,
        symptomaticRedlights,
        prognosticRedlights,
        nonComplianceRedlights,
        preventionRedlights,
        resultConfig,
        refShareDialog,
        openShareDialog,
        refShareStatusDialog,
        openShareStatusDialog,
        refRecordCaseCloseDialog,
        openRecordCaseCloseDialog,
      };
    },
  };
</script>
