<template>
  <div v-if="diseases.length" class="py-2 sm:grid sm:grid-cols-4 sm:gap-4">
    <dt class="font-semibold text-gray-900">Erkrankungen / Allergien / Lebensumstände</dt>
    <dd class="mt-1 text-gray-700 sm:col-span-3 sm:mt-0">
      <ul>
        <!-- @formatter:off -->
        <li v-for="disease in diseases" :key="disease.id" class="inline after:content-[',_'] last:after:content-['']">
          <span>{{ disease.disease }}</span>
          <component-guideline-button
            v-if="hasThirdParty('p4u')"
            class="print:hidden"
            :guidelines="disease.guidelines"
          />
        </li>
        <!-- @formatter:on -->
      </ul>
    </dd>
  </div>
</template>

<script>
  import ComponentGuidelineButton from "@components/Buttons/GuidelineButton.vue";
  import hasThirdParty from "@utils/Helpers/HasThirdParty.js";

  export default {
    name: "PagesRecordResultPatientDataDiseases",
    components: {ComponentGuidelineButton},
    props: {
      diseases: {
        type: Object,
        required: true,
      },
    },
    setup() {
      return {hasThirdParty};
    },
  };
</script>
