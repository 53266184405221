<template>
  <div class="mt-5">
    <div class="flex flex-col gap-3" @click="setFocus">
      <h3 class="text-lg leading-6 font-medium text-gray-700">BMP-Datenmatrix scannen</h3>

      <component-alert
        v-if="error.type && error.text"
        :type="error.type"
        class="!mb-0"
        :test-id="testId + '-barcode-scanner-tab'"
      >
        {{ error.text }}
      </component-alert>

      <p>Bitte jetzt die Datenmatrix mit einem apothekenüblichen Barcodescanner einlesen.</p>

      <div class="flex justify-center">
        <img src="@assets/icons/barcodescanner.svg" alt="Barcodescanner" />
      </div>
    </div>

    <input
      ref="refInput"
      class="w-0 h-0 focus:outline-none focus:ring-0 border-none"
      :data-test="testId + '-barcode-scanner-tab-input'"
      @input="processTextInput"
    />
  </div>
</template>

<script>
  import {onMounted, onUpdated, ref} from "vue";
  import {debounce} from "lodash";

  import ComponentAlert from "@components/Alerts/Alert.vue";

  export default {
    name: "ComponentBmpImportDialogBarcodeScannerTab",

    components: {ComponentAlert},

    props: {
      error: {
        type: Object,
        required: true,
      },
    },

    emits: ["input"],
    expose: ["setFocus"],

    setup(props, {emit}) {
      const refInput = ref(null);

      onMounted(() => {
        setFocus();
      });

      onUpdated(() => {
        setFocus();
      });

      const processTextInput = debounce((event) => {
        emit("input", event.target.value);
        refInput.value.value = "";
        setFocus();
      }, 1000);

      const setFocus = () => {
        setTimeout(() => {
          if (refInput.value) {
            refInput.value.focus();
          }
        }, 50);
      };

      return {
        /** ref */
        refInput,

        /** function */
        processTextInput,
        setFocus,
      };
    },
  };
</script>
