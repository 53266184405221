<template>
  <div class="text-sm">
    <div class="flex items-center space-x-2">
      <h6 class="text-sm font-semibold">Dokumentation nach PCNE</h6>
      <component-info-icon>
        <template #content>
          <p class="max-w-sm">
            Dokumentation modifiziert nach PCNE =
            <a href="https://www.pcne.org/working-groups/2/drug-related-problem-classification" target="_blank">
              Pharmaceutical Care Network Europe.
            </a>
            <br />
            Bietet die Möglichkeit zur codierten Dokumentation der Medikationsanalyse nach europ. Standard
          </p>
        </template>
      </component-info-icon>
    </div>

    <ul class="mt-2 flex flex-col space-y-4">
      <li>
        <component-select
          v-model="form.problem_code"
          :nullable="true"
          placeholder-text=""
          :options="pcneOptions['problems']"
          :label="'Problem' + (form.problem_code ? ' (' + form.problem_code + ')' : '')"
          key-value="code"
          key-name="text"
          :data-test="dataTest + '-select-problemCode'"
        />
      </li>

      <li>
        <component-select
          v-model="form.cause_code"
          :nullable="true"
          placeholder-text=""
          :options="pcneOptions['causes']"
          :label="'Grund' + (form.cause_code ? ' (' + form.cause_code + ')' : '')"
          key-value="code"
          key-name="text"
          :data-test="dataTest + '-select-causeCode'"
        />
      </li>

      <li>
        <div class="text-gray-500">Welche FAM?</div>
        <component-dropdown ref="refFamDropdown" class="h-9 flex items-baseline w-full text-left focus:ring-0 group">
          <template #button>
            <div
              class="w-full border-b-1 border-gray-200 group-focus:border-mcred pr-2 py-2 flex items-center justify-between truncate"
            >
              <div v-if="form.pcnefams && form.pcnefams.length > 0" class="truncate">
                {{
                  form.pcnefams
                    .map((pcnefam) => {
                      return medications.find((medication) => medication.abdata_key_fam === pcnefam.abdata_key_fam)
                        ?.fam;
                    })
                    .join(", ")
                }}
              </div>
              <div v-else />
              <component-icon class="text-xl text-gray-500">expand_more</component-icon>
            </div>
          </template>
          <template #dropdown>
            <ul class="p-2 w-full bg-white shadow-sm max-h-56 overflow-y-auto overscroll-contain">
              <li class="text-center w-60">
                <component-button
                  class="p4umc-primary p4umc-flat"
                  label="OK / Schließen"
                  :data-test="dataTest + '-button-famDropdown'"
                  @click="refFamDropdown.close()"
                />
              </li>
              <li v-for="(medication, index) in medications" :key="'medication-' + index" class="p-2">
                <label class="cursor-pointer flex space-x-2 items-center">
                  <input
                    v-model="form.pcnefams"
                    class="text-mcred"
                    type="checkbox"
                    :value="
                      form?.pcnefams?.find((pcnefam) => pcnefam.abdata_key_fam === medication.abdata_key_fam) ?? {
                        abdata_key_fam: medication.abdata_key_fam,
                      }
                    "
                    :data-test="dataTest + '-input-pcnefams'"
                  />
                  <span>{{ medication.fam }}</span>
                </label>
              </li>
            </ul>
          </template>
        </component-dropdown>
      </li>

      <li>
        <component-select
          v-model="form.communication_code"
          :nullable="true"
          placeholder-text=""
          :options="pcneOptions['communications']"
          :label="'Kommunikation' + (form.communication_code ? ' (' + form.communication_code + ')' : '')"
          key-value="code"
          key-name="text"
          :data-test="dataTest + '-select-communicationCode'"
        />
      </li>

      <li v-if="form.communication_code !== null && form.communication_code !== ''">
        <component-select
          v-model="form.intervention_code"
          :nullable="true"
          placeholder-text=""
          :options="pcneOptions['interventions']"
          :label="'Plan' + (form.intervention_code ? ' (' + form.intervention_code + ')' : '')"
          key-value="code"
          key-name="text"
          :data-test="dataTest + '-select-interventionCode'"
        />

        <component-input
          v-if="
            form.intervention_text !== null || (form.intervention_code !== null && form.intervention_code !== 'I4.2')
          "
          v-model="form.intervention_text"
          :hide-clear="true"
          placeholder="ggf. Erläuterung"
          :data-test="dataTest + '-input-interventionText'"
        />
      </li>

      <li v-if="form.intervention_code !== null && form.intervention_code !== ''">
        <component-select
          v-model="form.acceptance_code"
          :nullable="true"
          placeholder-text=""
          :options="pcneOptions['acceptances']"
          :label="'Akzeptanz' + (form.acceptance_code ? ' (' + form.acceptance_code + ')' : '')"
          key-value="code"
          key-name="text"
          :data-test="dataTest + '-select-acceptanceCode'"
        />

        <component-input
          v-if="form.acceptance_text !== null || form.acceptance_code === 'A2.3'"
          v-model="form.acceptance_text"
          :hide-clear="true"
          placeholder="ggf. anderer Grund"
          :data-test="dataTest + '-input-acceptanceText'"
        />
      </li>

      <li v-if="form.acceptance_code !== null && form.acceptance_code !== ''">
        <component-select
          v-model="form.status_code"
          :nullable="true"
          placeholder-text=""
          :options="pcneOptions['statuses']"
          :label="'Status' + (form.status_code ? ' (' + form.status_code + ')' : '')"
          key-value="code"
          key-name="text"
          :data-test="dataTest + '-select-statusCode'"
        />

        <component-input
          v-if="form.status_text !== null || (form.status_code !== null && form.status_code !== '')"
          v-model="form.status_text"
          :hide-clear="true"
          placeholder="ggf. Erläuterung"
          :data-test="dataTest + '-input-statusText'"
        />
      </li>
    </ul>
  </div>
</template>

<script>
  import {computed, ref, watch} from "vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import {pcneStructure} from "@pages/Records/Components/Result/Enums.js";
  import ComponentSelect from "@components/Selects/Select.vue";
  import {useForm, usePage} from "@inertiajs/vue3";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentDropdown from "@components/Dropdown/Dropdown.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import {debounce} from "lodash";

  export default {
    name: "PagesRecordResultListClaimInteractionDocumentation",
    components: {
      ComponentIcon,
      ComponentDropdown,
      ComponentButton,
      ComponentInput,
      ComponentSelect,
      ComponentInfoIcon,
    },
    props: {
      mcCheckId: {
        type: Number,
        required: true,
      },
      claim: {
        type: Object,
        required: true,
      },
      dataTest: {
        type: String,
        default: "claimInteractionDocumentation",
      },
    },
    setup(props) {
      const pageProps = usePage().props;
      const refFamDropdown = ref(null);

      const medications = computed(() => {
        return (pageProps?.medications?.data ?? [])
          .filter((medication) => medication.abdata_key_fam !== null && medication.abdata_key_fam !== 0)
          .map((medication) => {
            return {abdata_key_fam: medication.abdata_key_fam, fam: medication.fam};
          });
      });

      const form = useForm({
        problem_code: props.claim?.pcne?.problem_code ?? defaultProblemCode(),
        cause_code: props.claim?.pcne?.cause_code ?? defaultCauseCode(),
        communication_code: props.claim?.pcne?.communication_code ?? null,
        intervention_code: props.claim?.pcne?.intervention_code ?? null,
        intervention_text: props.claim?.pcne?.intervention_text ?? null,
        acceptance_code: props.claim?.pcne?.acceptance_code ?? null,
        acceptance_text: props.claim?.pcne?.acceptance_text ?? null,
        status_code: props.claim?.pcne?.status_code ?? null,
        status_text: props.claim?.pcne?.status_text ?? null,
        pcnefams: props.claim?.pcne?.pcnefams ?? [],
      });

      watch(
        () => [
          form.problem_code,
          form.cause_code,
          form.communication_code,
          form.intervention_code,
          form.intervention_text,
          form.acceptance_code,
          form.acceptance_text,
          form.status_code,
          form.status_text,
          form.pcnefams,
        ],
        debounce(function () {
          save();
        }, 1000),
        {flush: "post"},
      );

      function save() {
        // update
        if (props.claim?.pcne?.id) {
          form.put(
            route("pcnes.update", {
              patient: pageProps.patient.id,
              record: pageProps.record.id,
              check: props.claim.check_id,
              claim: props.claim.id,
              pcne: props.claim.pcne.id,
            }),
            {preserveScroll: true, preserveState: true},
          );
        }
        // create
        else {
          form.post(
            route("pcnes.store", {
              patient: pageProps.patient.id,
              record: pageProps.record.id,
              check: props.claim.check_id,
              claim: props.claim.id,
            }),
            {preserveScroll: true, preserveState: true},
          );
        }
      }

      const pcneOptions = computed(() => {
        return pcneStructure[props.claim?.pcne?.version || "9.0"];
      });

      function defaultProblemCode() {
        return (
          pcneStructure[props.claim?.pcne?.version || "9.0"].defaultValues.find(
            (value) => value.mcCheckId === props.mcCheckId,
          )?.pCode || null
        );
      }

      function defaultCauseCode() {
        return (
          pcneStructure[props.claim?.pcne?.version || "9.0"].defaultValues.find(
            (value) => value.mcCheckId === props.mcCheckId,
          )?.cCode || null
        );
      }

      return {form, pcneOptions, medications, refFamDropdown};
    },
  };
</script>
