import {usePage} from "@inertiajs/vue3";
import {inject, ref, toValue} from "vue";

export const usePrivacyGuard = () => {
  const privacy = inject("$privacy");
  const currentPatient = ref(null);

  privacy.decryptPatient(usePage().props.patient).then((patient) => {
    currentPatient.value = patient;
  });

  const validatePrivacy = (checkString, label = null, patient) => {
    if (typeof patient === "undefined") {
      patient = currentPatient.value;
    }

    checkString = toValue(checkString);

    const match = checkPrivacyLeaks(checkString, patient, false);

    if (match === null) {
      return null;
    }

    return label !== null
      ? `${label} enthält personenbezogene Daten („${match}“)`
      : `Enthält personenbezogene Daten („${match}“)`;
  };

  const validatePrivacyObject = (dataObj, fieldsObj, patient) => {
    let errors = {};
    dataObj = toValue(dataObj);

    Object.keys(fieldsObj).forEach((key) => {
      errors[key] = validatePrivacy(dataObj[key] ?? null, fieldsObj[key], patient);
    });

    return errors;
  };

  return {validatePrivacy, validatePrivacyObject};
};

export const checkPrivacyLeaks = (checkString, patient, strict = false) => {
  const words = [
    patient?.firstname ?? null,
    patient?.lastname ?? null,
    patient?.email ?? null,
    patient?.birthdate_formatted ?? null,
    patient?.birthdate?.substr(0, 4) ?? null,
    patient?.insurancenumber ?? null,
  ].filter((word) => word !== null);

  let regex;

  for (const word of words) {
    if (strict) {
      // Loop through each word and check if it is SOMEWHERE in the check string.
      // This mode will find "test" within the word "Attest".
      if (word !== null && word.trim().length > 0 && word !== "N." && checkString.includes(word)) {
        return word;
      }
    } else {
      // The normal mode will recognise word boundaries, so
      // this mode won't find "test" within the word "Attest".
      regex = new RegExp(`\\b${word}\\b`, "i");
      if (regex.test(checkString)) {
        return word;
      }
    }
  }

  return null;
};
