import axios from "axios";
import Cookies from "js-cookie";

export const pdfRouter = {
  /**
   * Source urls
   */
  // must be 127.0.0.1 to avoid CSRF Token errors
  pdfBaseUrl: import.meta.env.MC_PDF_URL,

  decodeXmlPath: "/api/v2/bmp/decode-xml",
  patientinputDecodeXmlPath: "/api/v2/patientinput/decode-xml",
  bmpManager: "/api/v2/bmp/bmp-manager",

  /**
   * Axios cancel function
   */
  cancelDom: null,

  /**
   * @private
   * @return {string}
   */
  getTokenValue() {
    return Cookies.get("mc_token");
  },

  /**
   * @private
   * @param {Object} response
   * @param {Object} callbacks
   */
  successResponse(response, callbacks) {
    if (response.data && typeof callbacks.onSuccess === "function") callbacks.onSuccess(response.data);
    if (typeof callbacks.onFinish === "function") callbacks.onFinish();

    this.cancelDom = null;
  },

  /**
   * @private
   * @param {string} error
   * @param {Object} callbacks
   */
  errorResponse(error, callbacks) {
    if (typeof callbacks.onError === "function") callbacks.onError(error);
    if (typeof callbacks.onFinish === "function") callbacks.onFinish();
  },

  /**
   * @public
   * @param {string} url
   * @param {Object} data
   * @param {Object} callbacks
   * @param {Object} config
   */
  post(url, data, callbacks, config = {}) {
    if (this.cancelDom) {
      this.cancelDom.abort();
    }

    this.cancelDom = new AbortController();

    let headers = {};
    if (typeof this.getTokenValue() !== "undefined") {
      headers["Authorization"] = `Bearer ${this.getTokenValue()}`;
    }

    axios
      .request({
        url: this.pdfBaseUrl + url,
        method: "post",
        data: data,
        signal: this.cancelDom.signal,
        preserveScroll: true,
        headers: headers,
        ...config,
      })
      .then((response) => {
        this.successResponse(response, callbacks);
      })
      .catch((error) => {
        this.errorResponse(error, callbacks);
      });
  },

  /**
   *
   * @param {Object} data
   * @param {Object} callbacks
   */
  decodeXml(data, callbacks) {
    return this.post(this.decodeXmlPath, data, callbacks);
  },

  /**
   *
   * @param {Object} data
   * @param {Object} callbacks
   */
  patientinputDecodeXml(data, callbacks) {
    return this.post(this.patientinputDecodeXmlPath, data, callbacks);
  },

  /**
   *
   * @param {Object} data
   * @param {Object} callbacks
   * @param {Object} config
   * @param {Object} options
   */
  initializeBmpManager(data, callbacks, config = {}, options = {}) {
    let url = this.bmpManager;

    const params = Object.entries(options).filter(([key, value]) => value === true);
    if (params.length) {
      url += "?" + params.map(([key, value]) => `${key}=${value}`).join("&");
    }

    return this.post(url, data, callbacks, config);
  },
};
