<template>
  <div>
    <h5 v-if="!layer" class="text-2xl">Offene Aufgaben</h5>

    <ul v-if="pendingItems.length" ref="refDraggable" class="mt-4 space-y-2">
      <li v-for="todo in pendingItems" :key="todo.id" :drag-item="todo.id" class="flex items-baseline">
        <component-tooltip
          tooltip="Zeile verschieben"
          :force-hidden="isDragging"
          custom-class-label="flex items-center"
        >
          <component-icon class="text-gray-900 text-xl mt-0.5" drag-handle>drag_handle</component-icon>
        </component-tooltip>

        <component-todo-list-todo-item
          class="flex-1"
          :layer="layer"
          :todo="todo"
          :patient-id="patient.id"
          :test-id="testId + '-todo-list'"
        />
      </li>
    </ul>

    <div v-else class="mt-1 flex items-center space-x-2 text-gray-500">
      <component-icon>thumb_up_off_alt</component-icon>
      <span>Keine offenen Aufgaben</span>
    </div>

    <form @submit.prevent="save()">
      <div class="mt-4 flex items-center space-x-4">
        <component-input
          v-model="form.label"
          placeholder="Neue Aufgabe hinzufügen"
          :hide-clear="true"
          :test-id="testId + '-todo-list-label'"
        />

        <div>
          <component-spinner v-if="form.processing" class="w-8 h-8" :test-id="testId + '-todo-list'" />

          <component-icon-button v-else icon="add" class="p4umc-primary" :test-id="testId + '-todo-list-add-item'" />
        </div>
      </div>
    </form>

    <template v-if="completed.length">
      <button
        class="w-full mt-8 flex items-center justify-between font-semibold text-gray-500 hover:text-gray-900"
        :data-test="testId + '-todo-list-completed'"
        @click="expanded = !expanded"
      >
        <span class="flex items-center">
          <component-icon class="text-base" :class="{'rotate-180': expanded}">arrow_drop_down</component-icon>
          <span class="ml-1 text-sm">erledigte Aufgaben</span>
        </span>

        <span ref="refCounterBadge">
          <component-category-badge :label="completed.length" color="gray" />
        </span>
      </button>

      <ul v-if="expanded" class="mt-4 space-y-2">
        <li v-for="todo in completed" :key="todo.id">
          <component-todo-list-todo-item
            :layer="layer"
            :todo="todo"
            :patient-id="patient.id"
            :test-id="testId + '-todo-list-completed'"
          />
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
  import {computed, ref, watch, nextTick, onMounted} from "vue";
  import {useForm} from "@inertiajs/vue3";
  import Sortable from "sortablejs";
  import {debounce} from "lodash";

  import ComponentCategoryBadge from "@components/Badges/CategoryBadge.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentTodoListTodoItem from "@components/Lists/TodoList/TodoItem.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "ComponentTodoList",

    components: {
      ComponentCategoryBadge,
      ComponentIcon,
      ComponentIconButton,
      ComponentInput,
      ComponentSpinner,
      ComponentTodoListTodoItem,
      ComponentTooltip,
    },
    props: {
      patient: {
        type: Object,
        required: true,
      },
      /**
       * layer: embedded in layer
       */
      layer: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const refCounterBadge = ref(null);
      const expanded = ref(false);

      const pendingItems = ref((props.patient?.todos ?? []).filter((todo) => todo.completed_at === null));

      const refDraggable = ref(null);
      const isDragging = ref(false);

      const sorting = useForm({});
      const sortable = ref(null);
      const sortCancelToken = ref(null);

      const form = useForm({
        label: null,
      });

      onMounted(() => {
        initSortable();
      });
      const initSortable = () => {
        if (sortable.value !== null) {
          return; // already initialized
        }
        if (refDraggable.value === null) {
          return; // refDraggable is not available yet
        }
        sortable.value = Sortable.create(refDraggable.value, {
          handle: "[drag-handle]",
          draggable: "[drag-item]",
          direction: "vertical",
          ghostClass: "sortable-ghost",
          dataIdAttr: "drag-item", // requires `drag-item="{{item.id}}"`
          store: {
            /**
             * Save the order of elements. Called onEnd (when the item is dropped).
             * @param {Sortable} sortable
             */
            set: debounce((sortable) => {
              if (sortCancelToken.value) {
                sortCancelToken.value.cancel();
              }

              const newSortOrder = sortable.toArray();

              // change order in our local object immediately
              // to keep reactivity
              pendingItems.value = pendingItems.value.sort((a, b) => {
                return newSortOrder.indexOf(a.id) - newSortOrder.indexOf(b.id);
              });

              // save new order
              sorting
                .transform((data) => ({
                  ...data,
                  ...{order: newSortOrder},
                }))
                .post(route("todos.sort", {patient: props.patient.id}), {
                  preserveScroll: true,
                  onCancelToken: (cancelToken) => (sortCancelToken.value = cancelToken),
                });
            }, 1000),
          },
          onStart: (evt) => {
            isDragging.value = true;
          },
          onEnd: (evt) => {
            isDragging.value = false;
          },
        });
      };

      const completed = computed(() => {
        return (props.patient?.todos ?? []).filter((todo) => todo.completed_at !== null).reverse();
      });

      watch(
        () => props.patient.todos,
        (newValue) => {
          pendingItems.value = (newValue ?? []).filter((todo) => todo.completed_at === null);
        },
        {deep: true},
      );

      watch(
        () => completed.value,
        (newValue, oldValue) => {
          if (newValue.length === 1 && oldValue.length === 0) {
            if (expanded.value === false) {
              expanded.value = true;
            }
            nextTick(() => {
              refCounterBadge.value?.classList?.add("animate-bounce", "[animation-iteration-count:1!important]"); //  []");
            });
          } else {
            refCounterBadge.value?.classList?.remove("animate-bounce", "[animation-iteration-count:1!important]"); //  [animation-iteration-count:1!important]");
          }
        },
      );

      function save() {
        form.post(route("todos.store", {patient: props.patient.id}), {
          preserveScroll: true,
          onSuccess: () => {
            form.reset();
            initSortable();
          },
        });
      }

      return {
        /** ref */
        refCounterBadge,
        refDraggable,

        /** const */
        isDragging,
        expanded,
        pendingItems,
        form,

        /** computed */
        completed,

        /** function */
        save,
      };
    },
  };
</script>
