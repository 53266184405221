<template>
  <li class="grid grid-cols-12 gap-x-2 pb-2 border-b-1 border-gray-300 animate-pulse">
    <skeleton-loader class-names="m-1 col-span-5 h-[27px]" />
    <skeleton-loader class-names="m-1 w-8 h-[27px]" />
    <skeleton-loader class-names="m-1 col-span-12 h-[14px]" />
    <skeleton-loader class-names="m-1 col-span-3 h-[27px]" />
    <skeleton-loader class-names="m-1 col-span-2 h-[27px]" />
    <skeleton-loader class-names="m-1 col-span-2 h-[27px]" />
    <skeleton-loader class-names="m-1 col-span-2 h-[27px]" />
    <skeleton-loader class-names="m-1 col-span-2 h-[27px]" />
    <skeleton-loader class-names="m-1 col-span-1 h-[27px]" />
  </li>
</template>

<script>
  import SkeletonLoader from "@components/SkeletonLoader.vue";

  export default {
    name: "MedicationSkeletonRow",
    components: {SkeletonLoader: SkeletonLoader},
  };
</script>
