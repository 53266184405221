<template>
  <div v-if="laborvalues.length" class="py-2 sm:grid sm:grid-cols-4 sm:gap-4">
    <dt class="font-semibold text-gray-900">Labor- / Vitalwerte</dt>
    <dd class="mt-1 text-gray-700 sm:col-span-3 sm:mt-0">
      <ul>
        <!-- @formatter:off -->
        <template v-for="laborvalue in laborvalues" :key="laborvalue.id">
          <li
            v-if="laborvalue.value || (laborvalue.deviation !== null && laborvalue.deviation !== 'normal')"
            class="inline after:content-[',_'] last:after:content-['']"
          >
            <span class="font-semibold">{{ laborvalue.type }}</span>
            <template v-if="laborvalue.deviation">
              <component-tooltip v-if="laborvalue.deviation === 'lowered'" tooltip="erniedrigt" :force-inline="true">
                (↓)
              </component-tooltip>
              <component-tooltip v-else-if="laborvalue.deviation === 'raised'" tooltip="erhöht" :force-inline="true">
                (↑)
              </component-tooltip>
              <component-tooltip v-else-if="laborvalue.deviation === 'normal'" tooltip="normal" :force-inline="true">
                (norm.)
              </component-tooltip>
            </template>
            <span v-if="laborvalue.value">{{ laborvalue.value }} {{ laborvalue.unit }}</span>
            <span v-if="date">
              {{ date }}
            </span>
          </li>
        </template>
        <!-- @formatter:on -->
      </ul>
    </dd>
  </div>
</template>

<script>
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import {computed} from "vue";

  export default {
    name: "PagesRecordResultPatientDataLaborvalues",
    components: {ComponentTooltip},
    props: {
      laborvalues: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const date = computed(() => {
        // if (props.laborvalue) {
        //   let date = new Date(props.laborvalue);
        //   if (isNaN(date.getTime())) {
        //     return null;
        //   }
        //
        //   return date.toLocaleDateString("de-DE", {dateStyle: "medium"});
        // }
        return null;
      });

      return {date};
    },
  };
</script>
