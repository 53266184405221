<template>
  <component-dialog
    ref="refDialog"
    dialog-title="E-Mail senden (verschlüsselt)"
    :dialog-width="showMailForm ? 'large' : 'medium'"
    :is-loading="isLoading"
    @dialog-closed="restoreResultConfig()"
  >
    <template #content>
      <section v-if="!showMailForm">
        <component-patient-release-confidentiality :patient="patient" />

        <div class="border-t-1 mt-4 pt-4">
          <ul class="space-y-3">
            <li>
              <label class="flex items-center space-x-2">
                <input v-model="type" type="radio" class="text-mcred" value="doc" />
                <div>Editierbare Vorlage für E-Mail an Arzt</div>
              </label>
            </li>

            <li>
              <label class="flex items-center space-x-2">
                <input v-model="type" type="radio" class="text-mcred" value="patient" />
                <div>Editierbare Vorlage für E-Mail an Patient</div>
              </label>
            </li>
          </ul>
        </div>
      </section>
      <section v-else class="grid grid-cols-3 gap-8">
        <component-input
          v-model="form.recipient_mail"
          :validation="form.errors.recipient_mail"
          label="Empfänger E-Mail"
          type="email"
        />

        <component-input
          v-model="form.sender_mail"
          :validation="form.errors.sender_mail"
          label="Absender E-Mail"
          type="email"
        />

        <component-checkbox v-model="form.carbon_copy" color="primary">Kopie an Absender</component-checkbox>

        <component-input
          v-model="form.subject"
          :validation="form.errors.subject"
          :privacy-guard="privacyErrors.subject"
          label="Betreff"
          class="col-span-3"
        >
          <template v-if="type === 'doc'" #privacy-guard>
            <component-privacy-guard type="strict" :validation="privacyErrors.subject" />
          </template>
        </component-input>

        <component-input
          v-model="form.password"
          :validation="form.errors.password"
          label="PDF-Passwort (erstellen)"
          type="password"
        />

        <component-input
          v-model="form.password_confirmation"
          :validation="form.errors.password_validation"
          label="PDF-Passwort Wdh."
          type="password"
        />

        <component-editor
          :ref="`email-dialog-${type}`"
          class="col-start-1 col-span-3"
          label="Text der E-Mail"
          editor-class="min-h-48"
          :privacy-guard="privacyErrors.body"
          @initialized="(e) => handleMailBodyUpdate(e)"
          @input="(e) => handleMailBodyUpdate(e)"
          @update:model-value="(e) => handleMailBodyUpdate(e)"
        >
          <p>
            {{ recipientName }},
            <br />
            <br />
          </p>

          <p v-if="type === 'patient'">
            Sie haben uns in der Apotheke um eine Medikationsanalyse gebeten. Eine Zusammenfassung unserer Empfehlungen
            inkl. möglicher Lösungsvorschläge haben wir im Anhang passwortgeschützt beigefügt.
            <br />
            Das Passwort zum Öffnen des PDF-Anhangs teilen wir Ihnen auf folgende Weise mit …
            <br />
            <br />
          </p>

          <p v-else>
            {{ patientName }} hat uns in der Apotheke um eine Medikationsanalyse gebeten, wobei Probleme / Symptome in
            Verbindung mit der Arzneimitteltherapie vermutet wurden.
            <br />
            Eine Zusammenfassung inkl. möglicher Lösungsvorschläge haben wir im Anhang passwortgeschützt beigefügt.
            <br />
            Eine unterschriebene Datenschutzerklärung sowie eine unterschriebene Schweigepflichtentbindung für den
            Dialog mit Ihnen liegen vor.
            <br />
            <br />
            Sie können leicht direkt auf elektronischem Weg antworten: Klicken Sie im PDF-Dokument auf den jeweiligen
            Button „Rückmeldung geben" und übermitteln Sie dadurch Ihre Rückmeldungen digital an uns zurück.
            <br />
            <br />
            Wir bitten um zeitnahe Rückmeldung - Danke.
            <br />
            <br />
            Das Passwort zum Öffnen des PDF-Anhangs teilen wir Ihnen auf folgende Weise mit ...
            <br />
            <br />
          </p>

          <p>
            Mit besten Grüßen
            <br />
            <br />
            {{ senderName }}
            <br />
            <br />
            {{ senderCompany }}
            <br />
            {{ senderCity }}
          </p>

          <template v-if="type === 'doc'" #privacy-guard>
            <component-privacy-guard type="strict" :validation="privacyErrors.body" />
          </template>
        </component-editor>
      </section>
    </template>

    <template #actions>
      <template v-if="!showMailForm">
        <component-button class="p4umc-primary" label="Öffnen" @click="write()" />
        <component-button label="Abbrechen" @click="close()" />
      </template>
      <template v-else>
        <component-button
          class="p4umc-primary"
          label="PDF erstellen und senden"
          :disabled="
            form.processing ||
            pendingAjaxRequests ||
            Object.values(privacyErrors).filter((value) => value !== null).length
          "
          @click="send()"
        >
          <component-spinner v-if="pendingAjaxRequests" class="h-4 ml-2 mr-1" />
        </component-button>
        <component-button label="PDF Vorschau" :disabled="pendingAjaxRequests > 0" @click="preview()">
          <component-spinner v-if="pendingAjaxRequests" class="h-4 ml-2 mr-1" />
        </component-button>
        <component-button class="p4umc-flat p4umc-primary" label="Abbrechen" @click="close()" />
      </template>
    </template>

    <template v-if="showMailForm" #info-icon>
      <component-info-icon>
        <template #content>
          Hinweise:
          <br />
          - Nur die „an {{ type === "patient" ? "Patient" : "Arzt" }}“ markierten Ergebnisse werden in den PDF-Anhang
          der Mail übermittelt.
          <br />
          - Vergeben Sie hier (unten) selbst ein Passwort zur Verschlüsselung der PDF-Datei, das dem Empfänger bekannt
          ist.
          <br />
          - Teilen Sie das Passwort auf einem anderen Weg mit (Telefon, persönlich, etc.).
          <br />
          - Datenschutz: Einverständnis des Patienten sollte vorliegen, am besten schriftlich:
          <a href="/static/Einwilligung_Datenschutz.pdf" target="_blank" title="Einverständniserklärung">
            "Einverständniserklärung".
          </a>
          <br />
          - Die Beachtung datenschutzrechtlicher Bestimmungen obliegt der Apotheke / dem Apotheker.
        </template>
      </component-info-icon>
    </template>
  </component-dialog>
</template>

<script>
  import {computed, inject, nextTick, onMounted, ref, watch} from "vue";
  import {router, useForm, usePage} from "@inertiajs/vue3";

  import axios from "axios";

  import readDom from "@utils/Helpers/ReadDom.js";
  import {usePrivacyGuard} from "@utils/Helpers/PrivacyGuard.js";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentEditor from "@components/Editor/Editor.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentPatientReleaseConfidentiality from "@components/Dialogs/PatientReleaseConfidentiality/PatientReleaseConfidentiality.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentPrivacyGuard from "@components/Alerts/PrivacyGuard.vue";

  export default {
    name: "ComponentRecordSendMailDialog",

    components: {
      ComponentPrivacyGuard,
      ComponentSpinner,
      ComponentButton,
      ComponentCheckbox,
      ComponentDialog,
      ComponentEditor,
      ComponentInfoIcon,
      ComponentInput,
      ComponentPatientReleaseConfidentiality,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
    },

    setup(props, {emit}) {
      const broadcast = inject("$broadcast");
      const pendingAjaxRequests = inject("$pendingAjaxRequests");

      const simulateResultConfig = inject("simulateResultConfig");
      const restoreResultConfig = inject("restoreResultConfig");
      const privacy = inject("$privacy");
      const {validatePrivacyObject} = usePrivacyGuard();

      const isLoading = ref(false);
      const refDialog = ref("null");
      const showMailForm = ref(false);
      const type = ref(null);
      const pageProps = usePage().props;

      const form = useForm({
        recipient_mail: null,
        sender_mail: pageProps.client?.email ?? null,
        carbon_copy: true,
        password: null,
        password_confirmation: null,

        subject: "Arzneimittel-Konsil",
        body: null,
        html: null,
      });

      const recipientName = computed(() => {
        let tmp;

        if (type.value === "patient") {
          if (props.patient.gender === "male") {
            tmp = "Sehr geehrter Herr ";
          } else if (props.patient.gender === "female") {
            tmp = "Sehr geehrte Frau ";
          } else {
            tmp = "Sehr geehrte/r Herr/Frau ";
          }

          return tmp + (props.patient.lastname ?? "");
        }

        return "Sehr geehrte(r) " + (props.record.doctor ?? "");
      });

      const patientName = computed(() => {
        if (props.patient.gender === "diverse") {
          return "Ihre in Behandlung befindende Person";
        } else if (props.patient.gender === "male") {
          return "Ihr Patient";
        }

        return "Ihre Patientin";
      });

      const senderName = computed(() => {
        return privacy.getCurrentUser()?.name ?? pageProps.client.first_name + " " + pageProps.client.last_name;
      });

      const senderCompany = computed(() => {
        return pageProps.client?.company ?? "";
      });

      const senderCity = computed(() => {
        return ((pageProps.client?.zip ?? "") + " " + (pageProps.client?.city ?? "")).trim();
      });

      watch(type, (newValue) => {
        switch (newValue) {
          case "doc":
            simulateResultConfig("showCommunicationDoc", true);
            simulateResultConfig("showCommunicationPatient", false);
            break;
          case "patient":
            simulateResultConfig("showCommunicationDoc", false);
            simulateResultConfig("showCommunicationPatient", true);
            break;
        }

        nextTick(() => {
          form.html = readDom(newValue);
        });
      });

      onMounted(() => {
        if (pageProps.client.company !== null) {
          form.subject += " von " + pageProps.client.company;
        } else if (pageProps.client.firstname !== null || pageProps.client.lastname !== null) {
          form.subject += " von " + pageProps.client.firstname + " " + pageProps.client.lastname; // user name
        }
      });

      function open() {
        refDialog.value.open();
      }

      function close() {
        form.reset();
        type.value = null;
        showMailForm.value = false;
        restoreResultConfig();
        refDialog.value.close();
      }

      function write() {
        showMailForm.value = true;
      }

      function handleMailBodyUpdate(e) {
        // the @input event is triggered from the trix editor itself
        // and still provides the full editor object
        form.body = typeof e === "string" ? e : e.target.innerHTML;
      }

      function send() {
        isLoading.value = true;

        form
          .transform((data) => ({
            ...data,
            ...{type: type.value},
          }))
          .post(route("records.send-as-email", {patient: props.patient.id, record: props.record.id}), {
            preserveScroll: true,
            onSuccess: () => {
              // force reload to trigger update of easyCom icon
              router.reload();
              // reload list of records
              broadcast.postMessage({action: "reload.records"});
              // reload specific record
              broadcast.record.postMessage(props.record.id, {action: "reload.record"});

              close();
            },
            onFinish: () => {
              isLoading.value = false;
            },
          });
      }

      function preview() {
        isLoading.value = true;

        axios
          .post(
            route("records.print", {patient: props.patient.id, record: props.record.id}),
            {
              type: type.value,
              html: form.html,
            },
            {
              responseType: "blob", // important
            },
          )
          .then((response) => {
            // handle pdf data as blob
            let pdfBlob = new Blob([response.data], {type: "application/pdf"});
            // create an object url representing the pdf file
            let pdfUrl = URL.createObjectURL(pdfBlob);

            // open new window with iframe containing the object url
            let pdfWindow = window.open("", "_blank");
            pdfWindow.document.write("<iframe width='100%' height='100%' src='" + pdfUrl + "'></iframe>");

            isLoading.value = false;
          })
          .catch((error) => {
            console.error(error.response);
            isLoading.value = false;
          });
      }

      const privacyErrors = computed(() => {
        return type.value === "patient" ? {} : validatePrivacyObject(form, {subject: "Betreff", body: "Text"});
      });

      return {
        isLoading,
        refDialog,
        showMailForm,
        form,
        open,
        close,
        write,
        send,
        preview,
        restoreResultConfig,
        type,
        recipientName,
        patientName,
        senderName,
        senderCompany,
        senderCity,
        print,
        privacyErrors,
        handleMailBodyUpdate,
        pendingAjaxRequests,
      };
    },
  };
</script>
