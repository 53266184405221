<template>
  <component-card
    id="patient-contacts-data"
    title="Kontakte"
    subtitle="Ärzte, Pflegedienste oder Heime"
    :class="{
      'rounded-lg border-2 border-gray-200': !withinDialog,
      '!shadow-none -m-4': withinDialog,
      '!animate-none': patient?.contacts?.length,
    }"
    :test-id="testId + '-patient-contact-data'"
  >
    <div class="flex justify-end items-baseline space-x-4">
      <component-button
        label="Hinzufügen"
        :test-id="testId + '-patient-contact-data-card-open-address-book'"
        @click="openAddressBook()"
      />
    </div>

    <ol v-if="patient?.contacts?.length || internalPendingContact" class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-8">
      <li v-if="internalPendingContact" class="relative bg-mcred-50">
        <div class="px-2 pt-2">
          <div class="flex items-center space-x-2">
            <h6 class="text-xs uppercase">
              <a
                href="#"
                class="text-gray-600 hover:underline"
                @click.prevent="openAddressBook(internalPendingContact)"
              >
                Kontakt aus WaWi
              </a>
            </h6>

            <component-info-icon icon-class="text-blue-600 text-base">
              <template #content>
                Der folgende Kontakt wurde aus der Warenwirtschaft übertragen und ist nur in dieser Analyse verfügbar.
                Sie können den Kontakt übergreifend zu Ihrem Patienten speichern.
              </template>
            </component-info-icon>
          </div>
        </div>

        <component-contact-card :contact="internalPendingContact" :small="true" />

        <component-icon-button
          class="absolute right-2 bottom-2 p4umc-primary text-lg !p-0 font-normal"
          icon="add"
          :test-id="testId + '-patient-sidebar-open-patient-address-book-dialog'"
          @click="openAddressBook(internalPendingContact)"
        />
      </li>
      <li v-for="contact in patient.contacts" :key="contact.id" class="relative group">
        <component-contact-card class="h-full min-h-44 hover:bg-gray-100" :contact="contact" />

        <div class="absolute flex right-2 bottom-2 space-x-4">
          <component-icon-button
            class="p4umc-primary"
            icon="edit"
            :test-id="testId + '-patient-contact-data-card-edit'"
            @click="edit(contact)"
          />

          <component-icon-button
            icon="delete"
            :test-id="testId + '-patient-contact-data-card-delete'"
            @click="confirmRemove(contact.id)"
          />
        </div>

        <div v-if="activeEditAddress === contact.id" class="p-2 absolute top-0 left-0 bg-white w-full h-full">
          <component-select
            v-model="editForm.role"
            class="mt-4"
            label="Funktion (optional)"
            :options="assignmentRole"
            :nullable="true"
            placeholder-text=""
            helper-text="Welche Rolle nimmt dieser Kontakt für den Patienten ein?"
            :test-id="testId + '-patient-contact-data-card-role'"
          />
          <div class="mt-4 flex flex-row-reverse space-x-reverse space-x-4">
            <component-button
              class="p4umc-primary"
              :disabled="editForm.processing"
              label="Speichern"
              :test-id="testId + '-patient-contact-data-card-save'"
              @click="update()"
            />

            <component-button
              label="Abbrechen"
              :test-id="testId + '-patient-contact-data-card-cancel'"
              @click="cancelEdit()"
            />
          </div>
        </div>
      </li>
    </ol>

    <component-empty-state v-else :test-id="testId + '-patient-contact-data'">
      Sie können bestehende Adressen von Ärzten, Pflegediensten oder Heimen aus Ihrem Adressbuch zu diesem Patienten
      hinzufügen.
    </component-empty-state>

    <component-patient-address-book-dialog
      ref="refPatientAddressBookDialog"
      :patient="patient"
      :test-id="testId + '-patient-contact-data'"
    />

    <component-confirmation-dialog
      ref="refConfirmationDialog"
      title="Kontakt entfernen?"
      content="Die Adresse bleibt unverändert in Ihrem Adressbuch."
      :test-id="testId + '-patient-contact-data'"
      @confirmed="(contactId) => remove(contactId)"
    />
  </component-card>
</template>

<script>
  import {inject, ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";

  import {assignmentRole} from "@pages/Contact/Enums/Enums.js";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import ComponentContactCard from "@components/Cards/ContactCard.vue";
  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentPatientAddressBookDialog from "@components/Dialogs/PatientAddressBookDialog.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";

  export default {
    name: "PatientsPatientContactsData",

    components: {
      ComponentInfoIcon,
      ComponentButton,
      ComponentCard,
      ComponentConfirmationDialog,
      ComponentContactCard,
      ComponentEmptyState,
      ComponentIconButton,
      ComponentPatientAddressBookDialog,
      ComponentSelect,
    },

    expose: ["openAddressBook"],

    props: {
      patient: {
        type: Object,
        required: true,
      },
      contacts: {
        type: Object,
        default: () => {},
      },
      withinDialog: {
        type: Boolean,
        default: false,
      },
      pendingContact: {
        type: Object,
        default: null,
      },
    },

    setup(props) {
      const broadcast = inject("$broadcast");

      const refPatientAddressBookDialog = ref(null);
      const refConfirmationDialog = ref(null);

      const internalPendingContact = ref(props.pendingContact);
      const activeEditAddress = ref();

      const form = useForm({
        role: null,
      });

      const editForm = useForm({
        role: null,
      });

      watch(
        () => props.pendingContact,
        (newValue) => {
          internalPendingContact.value = newValue;
        },
      );

      function confirmRemove(contactId) {
        refConfirmationDialog.value.open(contactId);
      }

      function remove(contactId) {
        form.delete(route("assignment.destroy", {patient: props.patient.id, contact: contactId}), {
          preserveScroll: true,
          onSuccess: () => {
            broadcast.patient.postMessage(props.patient.id, {action: "reload.patient"});
          },
        });
      }

      const openAddressBook = (search) => {
        refPatientAddressBookDialog.value.open(search);
      };

      function edit(contact) {
        editForm.role = contact.role;
        activeEditAddress.value = contact.id;
      }

      function cancelEdit() {
        activeEditAddress.value = null;
      }

      function update() {
        editForm.patch(route("assignment.update", {patient: props.patient.id, contact: activeEditAddress.value}), {
          preserveScroll: true,
          onSuccess: () => {
            activeEditAddress.value = null;
            broadcast.patient.postMessage(props.patient.id, {action: "reload.patient"});
          },
        });
      }

      return {
        /** enum */
        assignmentRole,

        /** ref */
        refPatientAddressBookDialog,
        refConfirmationDialog,

        /** const */
        activeEditAddress,
        editForm,
        internalPendingContact,

        /** function */
        edit,
        cancelEdit,
        openAddressBook,
        update,
        confirmRemove,
        remove,
      };
    },
  };
</script>
