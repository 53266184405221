<template>
  <component-dialog ref="bmpDialog" dialog-title="BMP-Manager" @dialog-closed="close">
    <template #info-icon>
      <component-info-icon>
        <template #content>
          <p>
            Im BMP-Manager können Sie den Medikationsplan finalisieren, indem Sie bspw. Rezeptur- oder Freitextzeilen
            hinzufügen oder Indikationen für Ihren Patienten als Laienbegriff eintragen.
          </p>
          <p class="mt-4">
            Der Medikationsplan basiert auf den Medikationsdaten der Eingabemaske. Der Mahlzeitenzusammenhang und die
            Medikationsart wird nicht automatisch übernommen, da diese individuell eingetragen werden sollten. Bitte
            verwenden Sie Hinweise zum Mahlzeitenbezug mit möglichst konkreten Zeitangaben und verzichten Sie
            grundsätzlich auf den Hinweis „unabhängig von der Mahlzeit“.
          </p>
          <p class="mt-4">Durch Aktivieren der Checkboxen können Sie diese Angaben dennoch automatisiert übernehmen.</p>
        </template>
      </component-info-icon>
    </template>
    <template #content>
      <template v-if="loading">
        <div class="text-center py-10"><component-spinner class="w-10" /></div>
      </template>

      <template v-else-if="error">
        <component-alert v-if="error === 'popup'" type="error">
          <p>Ihr Browser scheint Popups zu blockieren, weshalb der BMP-Manager nicht geöffnet werden konnte.</p>
          <p>Bitte prüfen Sie Ihre "Pop-up"-Browser-Einstellungen unter "Datenschutz und Sicherheit".</p>
        </component-alert>
        <component-alert v-else type="error">
          Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
        </component-alert>
      </template>

      <template v-else>
        <p class="text-sm">
          Möchten Sie folgende zusätzlichen Angaben aus der Eingabemaske für die Erstellung des BMP berücksichtigen?
        </p>

        <ul class="mt-4 space-y-4">
          <li>
            <component-checkbox v-model="splitSectionsBasedOnUsage" color="primary" class="!items-start">
              Generierung von Zwischenüberschriften und Sortierung der Artikel auf Basis der Medikationsart
            </component-checkbox>
          </li>
          <li>
            <component-checkbox v-model="prependMealToInfo" color="primary" class="!items-start">
              Angabe des Mahlzeitenzusammenhangs im Feld „Hinweise“
            </component-checkbox>
          </li>
        </ul>

        <p class="text-sm mt-4">
          Bitte beachten Sie, dass Änderungen im BMP-Manager bislang nicht in den MediCheck zurück übertragen werden.
          Sie sollten einen angepassten BMP separat speichern.
        </p>
      </template>
    </template>

    <template #actions>
      <component-button
        class="p4umc-primary"
        label="BMP-Manager öffnen"
        :disabled="loading"
        @click="pushToBmpManager"
      />

      <component-button label="Abbrechen" @click="close" />
    </template>
  </component-dialog>
</template>

<script>
  import {inject, onBeforeUnmount, ref, toValue} from "vue";

  import Window from "@utils/Helpers/Window.js";
  import {pdfRouter} from "@utils/pdfRouter/pdfRouter.js";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";

  export default {
    name: "ComponentBmpExportDialog",

    components: {
      ComponentCheckbox,
      ComponentInfoIcon,
      ComponentAlert,
      ComponentButton,
      ComponentDialog,
      ComponentSpinner,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      record: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const privacy = inject("$privacy");

      const /** @type {import("vue").Ref} */ bmpDialog = ref(null);

      const /** @type {import("vue").Ref<AbortController>} */ abortController = ref(null);

      const /** @type {import("vue").Ref<Boolean>} */ loading = ref(false);
      const /** @type {import("vue").Ref<String|Boolean>} */ error = ref(false);

      const prependMealToInfo = ref(null);
      const splitSectionsBasedOnUsage = ref(null);

      const open = () => {
        prependMealToInfo.value = null;
        splitSectionsBasedOnUsage.value = null;
        abortController.value = null;
        loading.value = false;
        error.value = null;

        bmpDialog.value.open();
      };

      const pushToBmpManager = async () => {
        if (abortController.value) {
          abortController.value.abort();
        }

        abortController.value = new AbortController();

        loading.value = true;
        error.value = false;

        const patient = await privacy.decryptPatient(props.patient);
        const record = await privacy.decryptRecord(props.record);

        if (record.checks) {
          delete record.checks;
        }

        if (record.metadata) {
          delete record.metadata;
        }

        if (record.patient) {
          delete record.patient;
        }

        pdfRouter.initializeBmpManager(
          {
            client: privacy.getClient(),
            patient: patient,
            record: record,
          },
          {
            onSuccess: (response) => {
              Window.open(response.data.url, "_blank")
                .then(() => close())
                .catch(() => {
                  showError("popup");
                });
            },
            onError: () => {
              showError("default");
            },
          },
          {
            signal: abortController.value.signal,
          },
          {
            prependMealToInfo: toValue(prependMealToInfo) ?? false,
            splitSectionsBasedOnUsage: toValue(splitSectionsBasedOnUsage) ?? false,
          },
        );
      };

      const showError = (errorType) => {
        error.value = errorType;
        loading.value = false;
      };

      const close = () => {
        if (abortController.value) {
          abortController.value.abort();
        }

        bmpDialog.value.close();
      };

      onBeforeUnmount(() => {
        close();
      });

      return {
        bmpDialog,
        loading,
        error,
        prependMealToInfo,
        splitSectionsBasedOnUsage,
        pushToBmpManager,
        open,
        close,
      };
    },
  };
</script>

<style scoped></style>
