<template>
  <form @submit.prevent="save">
    <component-card
      title="Stammdaten"
      class="rounded-lg border-2 border-gray-200"
      :test-id="testId + '-patient-master-data'"
    >
      <component-alert
        v-if="existingPatients.length"
        type="error"
        :allow-toggle="isUpdate"
        :allow-closing="true"
        :test-id="testId + '-patient-master-data-card'"
      >
        <template #title>
          <h2 class="text-xl">Eventuell existiert dieser Patient bereits?</h2>
        </template>

        <p class="mt-2">
          Sie möchten einen {{ isUpdate ? "bestehenden Patienten bearbeiten" : "neuen Patienten erstellen" }}, jedoch
          <template v-if="existingPatients.length === 1">existiert bereits ein Patient</template>
          <template v-if="existingPatients.length > 1">existieren bereits mehrere Patienten</template>
          mit gleichem
          <em>Namen</em>
          und
          <em>Geburtsdatum.</em>
          <br />
          <template v-if="isUpdate">Vermeiden Sie, Personen doppelt anzulegen.</template>

          <template v-else>
            Wechseln Sie ggf. zum bestehenden Patienten und vermeiden Sie, eine Person doppelt anzulegen.
          </template>
        </p>

        <table class="w-full mt-2">
          <thead>
            <tr class="border-b-2 border-b-gray-400 leading-tight">
              <th class="px-0.5 py-2 font-normal text-left align-top">
                <div class="">Name, Vorname</div>
                <div class="text-xs">Geburtsdatum, Versichertennummer</div>
              </th>

              <th class="px-0.5 py-2 font-normal text-left align-top">
                <div>Letzte Änderung</div>
              </th>

              <th><!-- action --></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="existingPatient in existingPatients" :key="existingPatient.id">
              <td>
                <div v-if="existingPatient.suffix || existingPatient.prefix" class="text-sm">
                  {{ existingPatient.suffix }} {{ existingPatient.prefix }}
                </div>

                <div class="text-gray-700 text-lg font-semibold">
                  {{ existingPatient.lastname }}, {{ existingPatient.firstname }}
                </div>

                <div class="text-sm">{{ existingPatient.birthdate }} {{ existingPatient.insurancenumber }}</div>
              </td>

              <td>
                <div>{{ existingPatient.updated_at }}</div>
                <div class="text-sm">{{ existingPatient.username }}</div>
              </td>

              <td class="text-right">
                <inertia-link
                  :href="$route('patients.show', {patient: existingPatient.id})"
                  :test-id="testId + '-patient-master-data-card-patient-show-inertia-link'"
                >
                  <component-icon class="inline-block hover:no-underline">launch</component-icon>
                  zum bestehenden Patienten wechseln
                </inertia-link>
              </td>
            </tr>
          </tbody>
        </table>
      </component-alert>

      <div class="grid grid-cols-4 gap-8">
        <component-select
          v-model="form.salutation"
          class=""
          label="Anrede"
          :validation="form.errors.salutation"
          :options="patientSalutation"
          :nullable="true"
          placeholder-text="keine"
          :test-id="testId + '-patient-master-data-card-salutation'"
        />

        <component-input
          v-model="form.title"
          class="col-start-1"
          label="Titel"
          :hide-clear="true"
          :validation="form.errors.title"
          maxlength="20"
          :test-id="testId + '-patient-master-data-card-title'"
        />

        <component-input
          v-model="form.firstname"
          class="col-span-2"
          label="Vorname*"
          :hide-clear="true"
          :encrypted="true"
          :validation="form.errors.firstname"
          maxlength="45"
          :test-id="testId + '-patient-master-data-card-firstname'"
          @decrypted.once="clip('firstname', $event)"
          @keyup="clip('firstname', $event.target.value)"
        />

        <component-input
          v-model="form.suffix"
          class=""
          label="Namenszusatz"
          :hide-clear="true"
          :encrypted="true"
          :validation="form.errors.suffix"
          autocomplete="off"
          :datalist="suffixAutocomplete"
          maxlength="20"
          :test-id="testId + '-patient-master-data-card-suffix'"
          @decrypted.once="clip('suffix', $event)"
          @keyup="clip('suffix', $event.target.value)"
        />

        <component-input
          v-model="form.prefix"
          class="col-start-1"
          label="Vorsatzwort"
          :hide-clear="true"
          :encrypted="true"
          :validation="form.errors.prefix"
          autocomplete="off"
          :datalist="prefixAutocomplete"
          maxlength="20"
          :test-id="testId + '-patient-master-data-card-prefix'"
          @decrypted.once="clip('prefix', $event)"
          @keyup="clip('prefix', $event.target.value)"
        />

        <component-input
          v-model="form.lastname"
          class="col-span-2"
          label="Nachname*"
          :hide-clear="true"
          :encrypted="true"
          :validation="form.errors.lastname"
          maxlength="45"
          :test-id="testId + '-patient-master-data-card-lastname'"
          @decrypted.once="clip('lastname', $event)"
          @keyup="clip('lastname', $event.target.value)"
        />

        <div class="relative">
          <component-birthdate
            v-model="form.birthdate"
            label="Geburtsdatum*"
            :validation="form.errors.birthdate"
            :test-id="testId + '-patient-master-data-card-birthdate'"
            @decrypted.once="clip('birthdate', $event)"
            @blur="clip('birthdate', $event.target.value)"
          />

          <component-info-icon
            v-if="patient?.is_fake"
            icon-class="text-blue-600 text-xl"
            class="absolute right-0 top-1"
            placement="left"
          >
            <template #content>
              <div class="w-44">
                Bei geteilten Patienten wird nicht das echte Geburtsdatum übertragen, sondern lediglich ein Datum, aus
                dem sich das korrekte Alter des Patienten ergibt.
              </div>
            </template>
          </component-info-icon>
        </div>

        <component-select
          v-model="form.gender"
          class="col-start-1"
          label="Geschlecht*"
          :validation="form.errors.gender"
          :options="patientGender"
          :test-id="testId + '-patient-master-data-card-gender'"
        />

        <component-input
          v-model="form.insurancenumber"
          class="col-span-2"
          label="Versichertennummer"
          :hide-clear="true"
          :encrypted="true"
          :validation="form.errors.insurancenumber"
          maxlength="20"
          :test-id="testId + '-patient-master-data-card-insurance-number'"
          @decrypted.once="clip('insurancenumber', $event)"
          @keyup="clip('insurancenumber', $event.target.value)"
        />

        <div v-if="isUpdate" class="col-span-4">
          <a href="#patient-attributes-data" class="hover:underline" @click.prevent="$emit('add:patient-address')">
            Patientenanschrift hinzufügen
          </a>
        </div>
        <div v-if="!isUpdate" class="col-span-4">
          <component-checkbox
            v-model="form.datasecurityconfirmed"
            value="1"
            color="red"
            :test-id="testId + '-patient-master-data-card-security-confirmed'"
          >
            Einwilligung zur Datenverarbeitung für die Medikationsanalyse liegt bereits vor
          </component-checkbox>
        </div>
      </div>

      <div class="text-sm text-right">* Pflichtangaben</div>

      <template #actions>
        <template v-if="continueWith === 'record'">
          <component-button
            class="p4umc-primary"
            label="Weiter"
            :disabled="form.processing"
            :test-id="testId + '-patient-master-data-card-create-record'"
            @click="redirectAfterCreate('records.create')"
          />
        </template>

        <template v-else>
          <component-button
            class="p4umc-primary"
            label="Speichern"
            :disabled="form.processing"
            :test-id="testId + '-patient-master-data-card-save-patient'"
            @click="redirectAfterCreate(null)"
          />

          <component-button
            v-if="!isUpdate"
            label="Weitere Informationen hinzufügen"
            :disabled="form.processing"
            :test-id="testId + '-patient-master-data-card-edit-patient'"
            @click="redirectAfterCreate('patients.edit')"
          />

          <component-unsaved-changes v-if="isUpdate" :form="form" />
        </template>
      </template>
    </component-card>
  </form>

  <component-patient-gender-diverse-dialog ref="refDiverseDialog" />
</template>

<script>
  import {inject, reactive, ref, watch} from "vue";
  import {useForm, Link as InertiaLink} from "@inertiajs/vue3";
  import {debounce} from "lodash";

  import filterExistingPatients from "@utils/Helpers/FilterExistingPatients.js";
  import {
    patientGender,
    patientSalutation,
    suffixAutocomplete,
    prefixAutocomplete,
  } from "@pages/Patients/Enums/Enums.js";

  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentBirthdate from "@components/Inputs/Birthdate.vue";
  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentCheckbox from "@components/Checkboxes/Checkbox.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentInfoIcon from "@components/Icons/InfoIcon.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSelect from "@components/Selects/Select.vue";
  import ComponentUnsavedChanges from "@components/Alerts/UnsavedChanges.vue";
  import ComponentPatientGenderDiverseDialog from "@components/Dialogs/PatientGenderDiverseDialog.vue";

  export default {
    name: "PatientsPatientMasterData",

    components: {
      ComponentPatientGenderDiverseDialog,
      ComponentAlert,
      ComponentBirthdate,
      ComponentButton,
      ComponentCard,
      ComponentCheckbox,
      ComponentIcon,
      ComponentInfoIcon,
      ComponentInput,
      ComponentSelect,
      ComponentUnsavedChanges,
      InertiaLink,
    },

    props: {
      patient: {
        type: Object,
        default: null,
      },
      continueWith: {
        type: String,
        default: null,
      },
    },

    setup(props) {
      const axios = inject("$axios");
      const privacy = inject("$privacy");
      const broadcast = inject("$broadcast");

      const refDiverseDialog = ref(null);

      let cancelToken;
      let redirectTo = null;

      const isUpdate = props.patient !== null;

      const form = useForm({
        salutation: props?.patient?.salutation ?? null,
        title: props?.patient?.title ?? null,
        firstname: props?.patient?.firstname ?? null,
        suffix: props?.patient?.suffix ?? null,
        prefix: props?.patient?.prefix ?? null,
        lastname: props?.patient?.lastname ?? null,
        birthdate: props?.patient?.birthdate ?? null,
        gender: props?.patient?.gender ?? null,
        insurancenumber: props?.patient?.insurancenumber ?? null,
        datasecurityconfirmed: null,
      });

      // separate clipped values from form, as they would
      // always falsely trigger form.isDirty state
      const clippedValues = reactive({
        firstname_clipped: null,
        suffix_clipped: null,
        prefix_clipped: null,
        lastname_clipped: null,
        birthdate_clipped: null,
        insurancenumber_clipped: null,
      });

      const existingPatients = ref([]);

      watch(
        () => form.gender,
        () => {
          if (form.gender === "diverse") {
            refDiverseDialog.value.open();
          }
          searchExistingPatients();
        },
      );

      watch(
        clippedValues,
        debounce(() => {
          searchExistingPatients();
        }, 500),
      );

      watch(
        () => form.isDirty,
        () => {
          // when updating, always search whenever form gets dirty
          if (isUpdate) {
            searchExistingPatients();
          }
        },
      );

      function searchExistingPatients() {
        // early exit if updating and form is not dirty
        if (isUpdate && !form.isDirty) {
          return;
        }

        // early exit if required fields are not set
        if (
          !clippedValues.firstname_clipped ||
          !clippedValues.lastname_clipped ||
          !clippedValues.birthdate_clipped ||
          !form.data().gender
        ) {
          return;
        }

        if (typeof cancelToken !== "undefined") {
          cancelToken.abort();
        }

        let controller = new AbortController();

        axios
          .post(route("api.patients.search"), clippedValues, {
            signal: controller.signal,
          })
          .then((response) => {
            Promise.all(response.data.data.map(async (patient) => await privacy.decryptPatient(patient))).then(
              (foundPatients) => {
                if (foundPatients.length > 0) {
                  privacy.decryptPatient(form.data()).then((currentPatient) => {
                    existingPatients.value = filterExistingPatients(
                      {id: props.patient?.id, ...currentPatient},
                      foundPatients,
                      ["firstname", "lastname", "gender", "birthdate", "insurancenumber"],
                    ).map((patient) => {
                      let d = new Date(patient.birthdate ?? null);
                      patient.birthdate = isNaN(d.getTime())
                        ? null
                        : d.toLocaleDateString("de-DE", {dateStyle: "medium"});
                      return patient;
                    });
                  });
                }
              },
            );
          })
          .catch((error) => {
            if (error.name === "AbortError") {
              // expected to happen
            }
          });

        cancelToken = controller;
      }

      function clip(field, value) {
        switch (field) {
          case "birthdate":
            if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(value)) {
              // YYYY-MM-DD
              clippedValues["birthdate_clipped"] = value.split("-")[0] ?? null;
            } else if (/^\d{1,2}.\d{1,2}.\d{4}$/.test(value)) {
              clippedValues["birthdate_clipped"] = value.split(".")[2] ?? null;
            } else {
              clippedValues["birthdate_clipped"] = null;
            }
            break;
          case "firstname":
          case "suffix":
          case "prefix":
          case "lastname":
          case "insurancenumber":
            clippedValues[field + "_clipped"] = (value ?? "").substring(0, 3) || null;
            break;
        }
      }

      function save() {
        form.errors = [];

        if (isUpdate) {
          form
            .transform((data) => ({
              ...data,
              ...clippedValues,
            }))
            .patch(route("patients.update", {id: props.patient.id}), {
              preserveScroll: true,
              onSuccess: () => {
                broadcast.patient.postMessage(props.patient.id, {action: "reload.patient"});
              },
            });
        } else {
          form
            .transform((data) => ({
              ...data,
              ...clippedValues,
            }))
            .post(route("patients.store", {redirect: redirectTo}), {
              preserveScroll: redirectTo === "patients.edit",
            });
        }
      }

      function redirectAfterCreate(value) {
        redirectTo = value;
      }

      return {
        /** refs */
        refDiverseDialog,

        /** enums */
        patientGender,
        patientSalutation,
        suffixAutocomplete,
        prefixAutocomplete,

        /** const */
        form,
        existingPatients,
        isUpdate,

        /** function */
        clip,
        save,
        redirectAfterCreate,
      };
    },
  };
</script>
