<template>
  <component-button
    class="p4umc-primary p4umc-flat"
    label="Medikationsplan erstellen / drucken"
    :icon-button="true"
    :disabled="disabled || loading"
    @click="openMedicationPlanExport()"
  >
    <component-spinner v-if="loading" class="size-4" />
    <component-icon v-else class="p4umc-primary text-xl">description</component-icon>
  </component-button>

  <component-bmp-export-dialog ref="bmpDialog" :patient="patientData" :record="recordData" />
</template>

<script>
  import {computed, ref} from "vue";
  import {router, usePage} from "@inertiajs/vue3";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentBmpExportDialog from "@components/Dialogs/BmpExportDialog.vue";
  import ComponentSpinner from "@components/Spinner.vue";

  export default {
    name: "MedicationComponentPlanPrint",

    components: {
      ComponentSpinner,
      ComponentButton,
      ComponentIcon,
      ComponentBmpExportDialog,
    },

    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      reloadRecord: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const page = usePage();

      const bmpDialog = ref(null);
      const loading = ref(false);

      const patientData = computed(() => page.props.patient ?? {});
      const recordData = computed(() => page.props.record ?? {});

      const openMedicationPlanExport = () => {
        if (props.reloadRecord) {
          // we have to reload the record page prop before
          // opening the bmp dialog (only necessary on
          // record.edit, because due to drag&drop
          // order might change without an
          // explicit reload of record.
          loading.value = true;
          router.reload({
            only: ["record"],
            onFinish: () => {
              // at least we do not care, if reloading the record was successful.
              // it's better to show an unsorted bmp instead of showing no
              // bmp manager at all.
              bmpDialog.value.open();
              loading.value = false;
            },
          });
        } else {
          // immediately open bmp dialog
          bmpDialog.value.open();
        }
      };

      return {
        bmpDialog,
        patientData,
        recordData,
        loading,
        openMedicationPlanExport,
      };
    },
  };
</script>
