<template>
  <section class="print:hidden">
    <h2 class="text-center text-2xl font-semibold print:font-normal border-b-1 border-gray-200 pb-6">
      Zusammenfassung der Analyse (optional)
    </h2>

    <div class="relative p-2 flex items-start space-x-8">
      <main class="flex-1">
        <nav>
          <ul class="flex flex-wrap border-b border-gray-200 space-x-2">
            <li>
              <button
                :class="{
                  'text-mcred bg-mcred-100/50 hover:bg-mcred-100 hover:text-mcred-700 border-mcred border-b-2':
                    activeTab === 'tab-doctor',
                }"
                class="text-gray-900 uppercase font-semibold rounded-t-md hover:bg-gray-100 p-2"
                @click="changeTab('tab-doctor')"
              >
                an Arzt
              </button>
            </li>

            <li>
              <button
                :class="{
                  'text-mcred bg-mcred-100/50 hover:bg-mcred-100 hover:text-mcred-700 border-mcred border-b-2':
                    activeTab === 'tab-patient',
                }"
                class="text-gray-900 uppercase font-semibold rounded-t-md hover:bg-gray-100 p-2"
                @click="changeTab('tab-patient')"
              >
                an Patient
              </button>
            </li>

            <li>
              <button
                :class="{
                  'text-mcred bg-mcred-100/50 hover:bg-mcred-100 hover:text-mcred-700 border-mcred border-b-2':
                    activeTab === 'tab-archive',
                }"
                class="text-gray-900 uppercase font-semibold rounded-t-md hover:bg-gray-100 p-2"
                @click="changeTab('tab-archive')"
              >
                Notizen
              </button>
            </li>
          </ul>
        </nav>

        <div class="mt-3">
          <div v-if="activeTab === 'tab-doctor'" role="tabpanel">
            <div class="flex flex-row justify-end">
              <component-tooltip
                v-if="hasThirdParty('p4u') && summaryConfidentialComments.length > 0"
                tooltip="Rückmeldung des Arztes"
                placement="left"
                :force-inline="false"
                class=""
              >
                <button class="text-sm" @click="openConfidentialCommentsDialog()">
                  <component-badge class="text-mcred">
                    <template #icon>{{ summaryConfidentialComments.length }}</template>
                    Arztrückmeldung
                  </component-badge>
                </button>
              </component-tooltip>
            </div>

            <component-editor
              key="doctorSummary"
              v-model="form.doctorsummary"
              class="mt-0 mb-0 pb-0"
              label="Ihre eigene Zusammenfassung an Fachkreise:"
              placeholder="Zusammenfassung für PDF an Arzt"
              :maxlength="50000"
              :privacy-guard="privacyErrors.doctorsummary"
              @blur="save()"
            />
          </div>

          <div v-if="activeTab === 'tab-patient'" role="tabpanel">
            <component-editor
              key="patientSummary"
              v-model="form.patientsummary"
              class="mt-0 mb-0 pb-0"
              label="Ihre eigene Zusammenfassung an den Patienten:"
              placeholder="Zusammenfassung für PDF an Patienten"
              :maxlength="50000"
              :privacy-guard="privacyErrors.patientsummary"
              @blur="save()"
            />
          </div>

          <div v-if="activeTab === 'tab-archive'" role="tabpanel">
            <component-editor
              key="archiveSummary"
              v-model="form.usersummary"
              class="mt-0 mb-0 pb-0"
              label="Notizen zur Analyse werden in das PDF für Ihre eigene Ablage/Archiv eingefügt:"
              placeholder="Interne Notizen"
              :maxlength="50000"
              :privacy-guard="privacyErrors.usersummary"
              @blur="save()"
            />
          </div>
        </div>
      </main>

      <aside class="w-1/4 min-w-[16rem] absolute right-0 shadow-lg bg-white pl-2 lg:pl-0 lg:shadow-none lg:relative">
        <nav>
          <ul class="flex flex-wrap border-b border-gray-200 space-x-2">
            <li class="flex-1" />

            <li>
              <button
                class="uppercase font-semibold inline-block rounded-t-md p-2 text-mcred bg-mcred-100/50 hover:bg-mcred-100 hover:text-mcred-700 border-mcred border-b-2"
                @click="changeInteractionTab('tab-chat')"
              >
                <component-badge v-if="summaryComments.length > 0">
                  <template #icon>{{ summaryComments.length }}</template>
                  Team
                </component-badge>
                <span v-else>Team</span>
              </button>
            </li>
          </ul>
        </nav>

        <div v-if="visibleTabPanels" class="mt-3">
          <div role="tabpanel">
            <pages-record-result-list-claim-interaction-chat
              :comments="summaryComments"
              comment-section="default"
              :patient-id="record.patient_id"
              :record-id="record.id"
              :hide-share="true"
              data-test="resultSummary-claimInteractionChat"
              @open:comments-dialog="openCommentsDialog()"
              @open:share-dialog="$emit('open:shareDialog')"
            />
          </div>
        </div>
      </aside>
    </div>

    <component-comments-dialog
      ref="refCommentsDialog"
      :record-id="record.id"
      :patient-id="record.patient_id"
      :confidential="false"
      :comments="summaryComments"
      data-test="resultSummary-commentsDialog-comments"
    />

    <component-comments-dialog
      ref="refConfidentialCommentsDialog"
      :record-id="record.id"
      :patient-id="record.patient_id"
      :confidential="true"
      :comments="summaryConfidentialComments"
      data-test="resultSummary-commentsDialog-confidentialComments"
    />
  </section>
</template>

<script>
  import {computed, inject, onMounted, onUnmounted, ref, watch} from "vue";
  import ComponentEditor from "@components/Editor/Editor.vue";
  import {useForm} from "@inertiajs/vue3";
  import ComponentBadge from "@components/Badges/Badge.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentCommentsDialog from "@components/Dialogs/CommentsDialog.vue";
  import PagesRecordResultListClaimInteractionChat from "@pages/Records/Components/Result/ResultList/ClaimInteractionChat.vue";
  import {usePrivacyGuard} from "@utils/Helpers/PrivacyGuard.js";

  export default {
    name: "PagesRecordResultSummary",
    components: {
      PagesRecordResultListClaimInteractionChat,
      ComponentCommentsDialog,
      ComponentTooltip,
      ComponentBadge,
      ComponentEditor,
    },
    props: {
      record: {
        type: Object,
        required: true,
      },
    },
    emits: ["open:shareDialog"],
    setup(props) {
      const hasThirdParty = inject("$hasThirdParty");
      const {validatePrivacyObject} = usePrivacyGuard();

      const refCommentsDialog = ref(null);
      const refConfidentialCommentsDialog = ref(null);
      const activeTab = ref("tab-doctor");

      const activeInteractionTab = ref("tab-chat");
      const isFloating = ref(false);
      const visibleTabPanels = ref(true);

      const comments = inject("comments");
      const summaryComments = computed(() => {
        return comments.value.filter((comment) => comment.section === "default" && comment.confidential === false);
      });
      const summaryConfidentialComments = computed(() => {
        return comments.value.filter((comment) => comment.section === "default" && comment.confidential === true);
      });

      const form = useForm({
        doctorsummary: props.record.doctorsummary,
        patientsummary: props.record.patientsummary,
        usersummary: props.record.usersummary,
      });

      function save() {
        form.patch(route("records.update", {patient: props.record.patient_id, record: props.record.id}), {
          preserveScroll: true,
        });
      }

      function changeTab(newTab) {
        activeTab.value = newTab;
      }

      const handleResizeBreakpoint = (event) => {
        isFloating.value =
          event.detail.breakpoint === null ||
          event.detail.breakpoint === "xs" ||
          event.detail.breakpoint === "sm" ||
          event.detail.breakpoint === "md";
        if (!isFloating.value) {
          visibleTabPanels.value = true;
        }
      };

      onMounted(() => {
        window.addEventListener("breakpoint", handleResizeBreakpoint);
      });

      onUnmounted(() => {
        window.removeEventListener("breakpoint", handleResizeBreakpoint);
      });

      function changeInteractionTab(newTab) {
        if (isFloating.value && activeInteractionTab.value === newTab) {
          visibleTabPanels.value = !visibleTabPanels.value;
        } else {
          visibleTabPanels.value = true;
          activeInteractionTab.value = newTab;
        }
      }

      function openCommentsDialog() {
        refCommentsDialog.value.open();
      }

      function openConfidentialCommentsDialog() {
        refConfidentialCommentsDialog.value.open();
      }

      const privacyErrors = computed(() => {
        return validatePrivacyObject(form, {
          doctorsummary: "Zusammenfassung an Arzt",
          patientsummary: "Zusammenfassung an Patient",
          usersummary: "Notizen zur Analyse",
        });
      });

      return {
        summaryComments,
        summaryConfidentialComments,
        visibleTabPanels,
        hasThirdParty,
        changeInteractionTab,
        isFloating,
        form,
        activeTab,
        changeTab,
        refCommentsDialog,
        refConfidentialCommentsDialog,
        openCommentsDialog,
        openConfidentialCommentsDialog,
        privacyErrors,
        save,
      };
    },
  };
</script>
