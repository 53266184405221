<template>
  <div v-if="symptoms.length" class="py-2 sm:grid sm:grid-cols-4 sm:gap-4">
    <dt class="font-semibold text-gray-900">Probleme / Symptome</dt>
    <dd class="mt-1 text-gray-700 sm:col-span-3 sm:mt-0">
      {{ symptoms.map((symptom) => symptom.symptom).join(", ") }}
    </dd>
  </div>
</template>

<script>
  export default {
    name: "PagesRecordResultPatientDataSymptoms",
    props: {
      symptoms: {
        type: Object,
        required: true,
      },
    },
  };
</script>
