<template>
  <tr class="border-b-2 border-gray-200 leading-tight" :data-test="testId + '-deleted-patient-row'">
    <!-- patient -->
    <td class="px-0.5 py-2">
      <component-tooltip v-if="patient.suffix || patient.prefix" tooltip="Nameszusatz" :force-inline="true">
        <span class="text-sm">{{ patient.suffix }} {{ patient.prefix }}</span>
      </component-tooltip>

      <div class="text-gray-700 font-semibold text-lg">{{ patient.lastname }}, {{ patient.firstname }}</div>

      <div class="flex items-center space-x-2">
        <component-tooltip tooltip="Geburtsdatum">
          <span class="text-xs" v-text="patient.birthdate" />
        </component-tooltip>

        <component-tooltip tooltip="Versichertennummer">
          <span class="text-xs" v-text="patient.insurancenumber" />
        </component-tooltip>
      </div>
    </td>

    <!-- status -->
    <td class="px-0.5 py-2">
      <patients-patient-information :patient="patient" :data-test="testId + '-deleted-patient-row'" />
    </td>

    <!-- last updates -->
    <td class="px-0.5 py-2">
      {{ patient.deleted_at }}
    </td>

    <!-- actions -->
    <td class="px-0.5 py-2">
      <div class="flex justify-end space-x-4">
        <component-tooltip v-if="isDeletable" tooltip="Wiederherstellen">
          <component-icon-button class="p4umc-primary" icon="sync" @click="confirmRecover" />
        </component-tooltip>

        <component-tooltip v-if="isDeletable" tooltip="Löschen">
          <component-icon-button icon="delete" @click="confirmForceDelete" />
        </component-tooltip>
      </div>
    </td>
  </tr>
</template>

<script>
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import PatientsPatientInformation from "@pages/Patients/Components/PatientInformation.vue";
  import {computed} from "vue";
  import {usePage} from "@inertiajs/vue3";

  export default {
    name: "PatientsDeletedPatientRow",

    components: {
      PatientsPatientInformation,
      ComponentIconButton,
      ComponentTooltip,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
    },

    emits: ["confirm:delete", "confirm:recover"],

    setup(props, {emit}) {
      const page = usePage();

      function confirmForceDelete() {
        emit("confirm:delete");
      }

      function confirmRecover() {
        emit("confirm:recover");
      }

      const isDeletable = computed(() => {
        return props.patient.from_wawi
          ? page.props.can.delete_wawi_patients
          : page.props.can.delete_patients;
      });


      return {
        /** computed */
        isDeletable,

        /** function */
        confirmForceDelete,
        confirmRecover,
      };
    },
  };
</script>
