<template>
  <component-dialog
    ref="refPatientAttributesDialog"
    dialog-width="large"
    dialog-type="abort"
    cancel-label="Schließen"
    :test-id="testId + '-patient-attributes'"
  >
    <template #content>
      <patients-patient-attributes-data within-dialog :patient="patient" :test-id="testId" />
    </template>
  </component-dialog>
</template>
<script>
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import {inject, ref} from "vue";
  import PatientsPatientAttributesData from "@pages/Patients/Components/PatientAttributesData.vue";

  export default {
    name: "ComponentPatientAttributesDialog",
    components: {
      PatientsPatientAttributesData,
      ComponentDialog,
    },
    expose: ["open"],
    props: {
      patient: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const refPatientAttributesDialog = ref(null);

      const open = () => {
        refPatientAttributesDialog.value.open();
      };

      const closeDialog = () => {
        refPatientAttributesDialog.value.close();
      };

      return {
        /** ref */
        refPatientAttributesDialog,

        /** function */
        closeDialog,
        open,
      };
    },
  };
</script>
