<template>
  <form :data-test="testId + '-patient-address-attribute-form'" @submit.prevent="save">
    <component-card
      class="relative group hover:bg-gray-100"
      :data-test="testId + '-patient-address-attribute-form-card'"
    >
      <div class="flex items-baseline justify-between">
        <h6 class="text-xl">Anschrift des Patienten</h6>

        <component-spinner
          v-if="form.processing"
          class="size-4"
          :data-test="testId + '-patient-address-attribute-form-card'"
        />

        <component-unsaved-changes v-else-if="form.isDirty || form.recentlySuccessful" :form="form" />
        <span v-else class="text-xs text-gray-500">Aktualisiert {{ attribute.updated_at }}</span>
      </div>

      <template v-if="activeEdit">
        <p v-if="hasThirdParty('p4u')" class="text-sm text-gray-600">
          Die Anschrift wird bei geteilten Patienten nicht übermittelt.
        </p>

        <div class="mt-4 grid grid-cols-4 gap-8">
          <component-input
            v-model="form.payload.supplement"
            :encrypted="true"
            class="col-span-4 md:col-span-2"
            label="Adresszusatz"
            helper-text="Bspw. c/o, wenn Patient bei dritter Person wohnhaft ist"
            :validation="form.errors['payload.supplement']"
            :data-test="testId + '-patient-address-attribute-form-card-supplement'"
          />

          <component-input
            v-model="form.payload.street"
            :encrypted="true"
            class="col-start-1 col-span-4 md:col-span-3"
            label="Straße, Hausnr."
            :validation="form.errors['payload.street']"
            :data-test="testId + '-patient-address-attribute-form-card-street'"
          />

          <component-input
            v-model="form.payload.zip"
            :encrypted="true"
            class="col-start-1 col-span-1"
            label="PLZ"
            :validation="form.errors['payload.zip']"
            :data-test="testId + '-patient-address-attribute-form-card-zip'"
          />

          <component-input
            v-model="form.payload.city"
            :encrypted="true"
            class="col-span-3 md:col-span-2"
            label="Ort"
            :validation="form.errors['payload.city']"
            :data-test="testId + '-patient-address-attribute-form-card-city'"
          />

          <component-input
            v-model="form.payload.email"
            :encrypted="true"
            class="col-start-1 col-span-4 md:col-span-2"
            label="E-Mail"
            :validation="form.errors['payload.email']"
            :data-test="testId + '-patient-address-attribute-form-card-email'"
          />

          <component-input
            v-model="form.payload.telephone"
            :encrypted="true"
            class="col-start-1 col-span-4 md:col-span-2"
            label="Telefon"
            :validation="form.errors['payload.telephone']"
            :data-test="testId + '-patient-address-attribute-form-card-telephone'"
          />
        </div>
      </template>

      <template v-else>
        <div class="mt-0.5 min-h-4">
          <template v-if="patient.is_fake">
            <p class="text-sm text-gray-600">Die Anschrift wird bei geteilten Patienten nicht übermittelt.</p>
          </template>

          <div :data-encrypted="attribute.payload?.supplement" />
          <div :data-encrypted="attribute.payload?.street" />

          <div>
            <span :data-encrypted="attribute.payload?.zip" />
            <span :data-encrypted="attribute.payload?.city" />
          </div>

          <div>
            <div v-if="attribute.payload?.email">
              E-Mail:
              <span :data-encrypted="attribute.payload?.email" />
            </div>

            <div v-if="attribute.payload?.telephone">
              Tel:
              <span :data-encrypted="attribute.payload?.telephone" />
            </div>
          </div>
        </div>

        <div class="absolute flex right-2 bottom-2 space-x-4">
          <component-icon-button
            type="button"
            class="p4umc-primary"
            icon="edit"
            :data-test="testId + '-patient-address-attribute-form-card-edit'"
            @click="activeEdit = true"
          />

          <component-icon-button
            type="button"
            icon="delete"
            :data-test="testId + '-patient-address-attribute-form-card-delete'"
            @click="confirmRemove()"
          />
        </div>
      </template>

      <template v-if="activeEdit" #actions>
        <component-button
          class="p4umc-primary"
          label="Speichern"
          :disabled="form.processing"
          :data-test="testId + '-patient-address-attribute-form-card-save'"
        />

        <component-button
          type="button"
          label="Abbrechen"
          :disabled="form.processing"
          :data-test="testId + '-patient-address-attribute-form-card-cancel-edit'"
          @click="cancelEdit()"
        />
      </template>
    </component-card>
  </form>

  <component-confirmation-dialog
    ref="refConfirmationDialog"
    title="Sind Sie sicher?"
    content="Die Angabe „Patientenanschrift“ wird gelöscht."
    :data-test="testId + '-patient-address-attribute'"
    @confirmed="remove()"
  />
</template>

<script>
  import {inject, ref, watch} from "vue";
  import {useForm} from "@inertiajs/vue3";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentInput from "@components/Inputs/Input.vue";
  import ComponentSpinner from "@components/Spinner.vue";
  import ComponentUnsavedChanges from "@components/Alerts/UnsavedChanges.vue";
  import hasThirdParty from "@utils/Helpers/HasThirdParty.js";

  export default {
    name: "PatientsPatientAttributesPatientAddressAttribute",

    components: {
      ComponentSpinner,
      ComponentIconButton,
      ComponentInput,
      ComponentConfirmationDialog,
      ComponentButton,
      ComponentUnsavedChanges,
      ComponentCard,
    },

    props: {
      attribute: {
        type: Object,
        default: () => ({}),
      },
      patient: {
        type: Object,
        default: () => ({}),
      },
      recentlyAdded: {
        type: Boolean,
        default: false,
      },
    },

    setup(props) {
      const broadcast = inject("$broadcast");

      const refConfirmationDialog = ref(null);

      const activeEdit = ref(props.recentlyAdded);
      const form = useForm({
        type: props.attribute.type,
        payload: {
          supplement: props.attribute?.payload?.supplement,
          street: props.attribute?.payload?.street,
          zip: props.attribute?.payload?.zip,
          city: props.attribute?.payload?.city,
          email: props.attribute?.payload?.email,
          telephone: props.attribute?.payload?.telephone,
        },
      });

      watch(
        () => props.recentlyAdded,
        (newValue) => {
          activeEdit.value = newValue;
        },
      );

      function cancelEdit() {
        // was recently added AND was NOT saved once
        if (props.recentlyAdded && !form.wasSuccessful) {
          remove();
        } else {
          form.reset();
          activeEdit.value = false;
        }
      }

      function save() {
        form.put(route("attributes.update", {patient: props.patient.id, attribute: props.attribute.id}), {
          preserveScroll: true,
          onSuccess: () => {
            activeEdit.value = false;
            broadcast.patient.postMessage(props.patient.id, {action: "reload.patient"});
          },
        });
      }

      function confirmRemove() {
        refConfirmationDialog.value.open();
      }

      function remove() {
        form.delete(route("attributes.destroy", {patient: props.patient.id, attribute: props.attribute.id}), {
          preserveScroll: true,
          onSuccess: () => {
            broadcast.patient.postMessage(props.patient.id, {action: "reload.patient"});
          },
        });
      }

      return {
        /** ref */
        refConfirmationDialog,

        /** const */
        form,
        activeEdit,

        /** function */
        hasThirdParty,
        cancelEdit,
        save,
        confirmRemove,
        remove,
      };
    },
  };
</script>
