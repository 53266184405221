<template>
  <div class="mb-3">
    <component-textarea
      v-model="text"
      name="specialInput"
      maxlength="80"
      rows="2"
      :validation="validation"
      :privacy-guard="privacyErrors.text ?? undefined"
      helper-text="Darf maximal einen Zeilenumbruch beinhalten."
    />
  </div>
</template>

<script>
  import {computed, onBeforeMount, ref, watch} from "vue";

  import ComponentTextarea from "@components/Textarea/Textarea.vue";
  import {usePrivacyGuard} from "@utils/Helpers/PrivacyGuard.js";

  export default {
    name: "MedicationComponentDetailDialogAlternativeDosageTextarea",

    components: {ComponentTextarea},

    props: {
      dataHashId: {
        type: String,
        required: true,
        default: "",
      },
      dosageForm: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    emits: ["isInvalid", "isValid", "change"],

    setup(props, {emit}) {
      const {validatePrivacy} = usePrivacyGuard();

      const validation = ref("");
      const text = ref(null);

      watch(
        () => text.value,
        (newValue) => {
          const emitId = `specialDosage${props.dataHashId}`;
          const chars = newValue ? newValue.length : 0;
          const linebreaks = newValue?.match(/\n/g || []);

          text.value = newValue;

          if (linebreaks?.length > 1) {
            validation.value = "Bitte maximal ein Zeilenumbruch.";
            emit("isInvalid", emitId);
          } else if (chars > 80) {
            validation.value = "Bitte weniger als 80 Zeichen eingeben.";
            emit("isInvalid", emitId);
          } else {
            validation.value = "";

            emit("isValid", emitId);
            emit("change", {
              text: text.value,
              newData: [
                {
                  dayofmonth: null,
                  month: null,
                  time: null,
                  value: 0,
                  value_as_fraction: false,
                  weekday: null,
                },
              ],
            });
          }
        },
      );

      onBeforeMount(() => {
        text.value = props.dosageForm.dosagealternative?.text ?? "";
      });

      const privacyErrors = computed(() => {
        return {
          text: validatePrivacy(text, "Sonderdosierung"),
        };
      });

      return {
        /** const */
        validation,
        text,
        privacyErrors,
      };
    },
  };
</script>
