<template>
  <component-dialog ref="refDetailDialog" dialog-width="large" :is-loading="isLoading">
    <template #content>
      <div class="flex flex-col space-y-3">
        <div class="sticky top-[-20px] z-20 bg-white py-3">
          <medication-component-detail-dialog-medication-data
            :is-editable="isEditable"
            :medication-form="medicationForm"
            @change-form="changeForm"
            @valid="handleValid"
            @invalid="handleInvalid"
            @reset-indications="resetMedicationIndication"
          />
        </div>

        <medication-component-detail-dialog-dosage-data
          :is-editable="isEditable"
          :medication-form="medicationForm"
          @change-form="changeForm"
          @valid="handleValid"
          @invalid="handleInvalid"
        />

        <medication-component-detail-dialog-additional-data
          :is-editable="isEditable"
          :medication-form="medicationForm"
          @change-form="changeForm"
          @valid="handleValid"
          @invalid="handleInvalid"
        />

        <medication-component-detail-dialog-non-compliance-data
          :is-editable="isEditable"
          :medication-form="medicationForm"
          @change-form="changeForm"
          @valid="handleValid"
          @invalid="handleInvalid"
        />
      </div>
    </template>

    <template #actions>
      <template v-if="isEditable">
        <component-button
          class="p4umc-primary"
          :disabled="primaryButtonDisabled"
          label="Speichern"
          @click="patchMedication"
        />

        <component-button label="Abbrechen" :disabled="isLoading" @click="close" />
      </template>

      <template v-else>
        <component-button label="Schließen" :disabled="isLoading" @click="close" />
      </template>
    </template>
  </component-dialog>
</template>

<script>
  import {computed, ref} from "vue";
  import {router, useForm, usePage} from "@inertiajs/vue3";
  import {cloneDeep, isEmpty, set} from "lodash";

  import deviantMedicationModel from "@models/deviantMedicationModel.js";
  import additionalInformationModel from "@models/additionalInformationModel.js";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";

  import MedicationComponentDetailDialogDosageData from "@pages/Records/Components/Sections/MedicationBar/Components/DetailDialog/DosageData.vue";
  import MedicationComponentDetailDialogAdditionalData from "@pages/Records/Components/Sections/MedicationBar/Components/DetailDialog/AdditionalData.vue";
  import MedicationComponentDetailDialogNonComplianceData from "@pages/Records/Components/Sections/MedicationBar/Components/DetailDialog/NonComplianceData.vue";
  import MedicationComponentDetailDialogMedicationData from "@pages/Records/Components/Sections/MedicationBar/Components/DetailDialog/MedicationData.vue";

  export default {
    name: "MedicationComponentDetailDialog",

    components: {
      ComponentButton,
      ComponentDialog,
      MedicationComponentDetailDialogAdditionalData,
      MedicationComponentDetailDialogDosageData,
      MedicationComponentDetailDialogMedicationData,
      MedicationComponentDetailDialogNonComplianceData,
    },

    props: {
      isEditable: {
        type: Boolean,
        required: true,
        default: false,
      },
    },

    setup() {
      const page = usePage();

      const refDetailDialog = ref(null);

      const isLoading = ref(false);
      const invalidFields = ref([]);

      const medicationForm = useForm({
        value: null,
      });

      const primaryButtonDisabled = computed(() => invalidFields.value.length > 0);

      const open = ({medication}) => {
        medicationForm.value = cloneDeep(medication);

        if (medicationForm.value.deviantmedication === null) {
          medicationForm.value.deviantmedication = cloneDeep(deviantMedicationModel);
        }

        if (medicationForm.value.additionalinformation === null) {
          medicationForm.value.additionalinformation = cloneDeep(additionalInformationModel);
        }

        refDetailDialog.value.open();
      };

      const close = () => {
        if (refDetailDialog.value) {
          refDetailDialog.value.close();
        }
      };

      const changeForm = ({key, newValue}) => {
        set(medicationForm.value, key, newValue);
      };

      const addSymptom = () => {
        const medicationData = page.props.medications?.data.find((pageItem) => pageItem.id === medicationForm.value.id);

        if (
          medicationForm.value.additionalinformation?.satisfactionSymptom &&
          // proxy object is not empty {}
          Object.keys(medicationForm.value.additionalinformation?.satisfactionSymptom).length > 0 &&
          medicationForm.value.additionalinformation?.satisfactionSymptom?.Name !==
            medicationData.additionalinformation?.satisfactioncomment
        ) {
          router.post(
            route("symptoms.store", {
              patient: page.props.patient.id,
              record: page.props.record.id,
            }),
            medicationForm.value.additionalinformation.satisfactionSymptom,
            {
              preserveScroll: true,
              only: ["flash", "errors", "symptoms"],
            },
          );
        }
      };

      const patchMedication = () => {
        medicationForm
          .transform((data) => ({
            ...data.value,
          }))
          .put(
            route("medications.update", {
              patient: page.props.patient.id,
              record: page.props.record.id,
              medication: medicationForm.value.id,
            }),
            {
              preserveScroll: true,
              only: ["flash", "errors", "medications"],
              onStart: () => {
                isLoading.value = true;
              },
              onSuccess: (response) => {
                if (!isEmpty(response.props.errors)) {
                  console.error(response.props.errors);
                }

                addSymptom();
                close();
              },
              onFinish: () => {
                isLoading.value = false;
              },
            },
          );
      };

      const handleValid = (errorId) => {
        invalidFields.value = invalidFields.value.filter((id) => {
          return id !== errorId;
        });
      };

      const handleInvalid = (errorId) => {
        if (invalidFields.value.indexOf(errorId) === -1) {
          invalidFields.value.push(errorId);
        }
      };

      const resetMedicationIndication = () => {
        const medication = cloneDeep(
          page.props.medications?.data.find((oldMedication) => oldMedication.id === medicationForm.value.id),
        );

        changeForm({key: "indications", newValue: medication.indications});
        changeForm({key: "indication_unknown", newValue: medication.indication_unknown});
      };

      return {
        /** ref */
        refDetailDialog,

        /** const */
        isLoading,
        medicationForm,

        /** computed */
        primaryButtonDisabled,

        /** function  */
        open,
        close,
        changeForm,
        patchMedication,
        handleValid,
        handleInvalid,
        resetMedicationIndication,
      };
    },
  };
</script>
