<template>
  <div class="flex items-center space-x-1">
    <!-- share status (not shown on deleted-patients tab due to missing shared_owners) -->
    <template v-if="patient.is_fake && patient.shared_owners">
      <component-tooltip placement="top-start">
        <a
          href="#"
          :data-test="testId + '-patient-information-open-ref-share-info-dialog-button'"
          @click.prevent="refShareInfoDialog.open()"
        >
          <component-category-badge class="!px-1 !py-0.5" :test-id="testId + '-patient-information'">
            <component-icon v-if="!patient.revoked" class="text-lg">call_received</component-icon>
            <component-icon v-if="patient.revoked" class="text-lg">person_add_disabled</component-icon>
          </component-category-badge>
        </a>

        <template #tooltip>
          <div class="flex space-x-2 items-baseline">
            <component-icon v-if="!patient.revoked">call_received</component-icon>
            <component-icon v-if="patient.revoked">person_add_disabled</component-icon>

            <div v-if="patient.shared_owners.length">
              <div v-if="!patient.revoked" class="!font-thin">
                {{ patient.shared_owners[0]?.created_diff }} für mich freigegeben von
              </div>

              <div v-if="patient.revoked" class="!font-thin">
                {{ patient.shared_owners[0]?.updated_diff }} widerrufen von
              </div>

              <div>
                {{ patient.shared_owners[0]?.company ?? patient.shared_owners[0]?.name }}

                <template v-if="patient.shared_owners[0]?.company && patient.shared_owners[0]?.name">
                  ({{ patient.shared_owners[0]?.name }})
                </template>
              </div>
            </div>
          </div>
        </template>
      </component-tooltip>
    </template>

    <!-- overall status -->
    <component-tooltip v-if="patient.records_count">
      <template #tooltip>
        {{ patient.records_count === 1 ? "1 Medikationsanalyse" : patient.records_count + " Medikationsanalysen" }}
      </template>

      <button
        :data-test="testId + '-patient-overall-record-status-' + patient.overall_record_status + '-button'"
        @click.prevent="openOverallRecordStatusDialog()"
      >
        <component-category-badge :color="overallRecordStatusColor" class="!px-1 !py-0.5 flex space-x-px items-center">
          <component-icon class="text-lg">data_saver_off</component-icon>
          <span class="tabular-nums">{{ patient.records_count }}</span>
        </component-category-badge>
      </button>
    </component-tooltip>

    <!-- status -->
    <component-tooltip v-if="patient.in_nursing_home" tooltip="Heimpatient">
      <component-category-badge class="!px-1 !py-0.5">
        <component-icon class="text-lg">local_hotel</component-icon>
      </component-category-badge>
    </component-tooltip>

    <component-tooltip v-if="patient.from_wawi" tooltip="aus Warenwirtschaft">
      <component-category-badge class="!px-1 !py-0.5">
        <component-icon class="text-lg">shopping_cart</component-icon>
      </component-category-badge>
    </component-tooltip>
  </div>

  <component-dialog
    ref="refOverallRecordStatusDialog"
    :dialog-title="overallRecordStatusDialogTitle"
    primary-label="Patient öffnen"
    :dialog-content="overallRecordStatusDialogText"
    :test-id="testId + '-patient-information-info-text'"
    @primary-button-clicked="openPatient(patient)"
  />

  <component-dialog
    ref="refShareInfoDialog"
    dialog-type="information"
    dialog-title="Fallfreigabe und Zusammenarbeit"
    dialog-width="medium"
    :test-id="testId + '-patient-information-share-info'"
  >
    <template #content>
      <div class="flow-root">
        <ul role="list" class="space-y-8">
          <li v-if="patient.revoked" class="relative [&:last-child>span]:hidden">
            <span class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

            <div class="relative flex space-x-3">
              <div>
                <span
                  class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                  :class="{
                    'bg-gray-400': true,
                  }"
                >
                  <component-icon class="text-white text-xl">person_add_disabled</component-icon>
                </span>
              </div>

              <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                  <p class="text-sm text-gray-700">Freigabe wurde widerrufen von</p>

                  <div>
                    <strong class="font-semibold">
                      {{ patient.shared_owners[0]?.company ?? patient.shared_owners[0]?.name }}
                    </strong>

                    <template v-if="patient.shared_owners[0]?.company && patient.shared_owners[0]?.name">
                      ({{ patient.shared_owners[0]?.name }})
                    </template>
                  </div>
                  <div class="mt-2"><!-- no button --></div>
                </div>

                <div class="text-right text-sm text-gray-500">
                  <component-tooltip tooltip="widerrufen am">
                    <time class="whitespace-nowrap">
                      {{ patient.shared_owners[0].updated_at }}
                    </time>
                  </component-tooltip>
                </div>
              </div>
            </div>
          </li>

          <li class="relative [&:last-child>span]:hidden">
            <span class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

            <div class="relative flex space-x-3">
              <div>
                <span
                  class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                  :class="{
                    'bg-gray-400': true,
                  }"
                >
                  <component-icon class="text-white text-xl">call_received</component-icon>
                </span>
              </div>

              <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                  <p class="text-sm text-gray-700">Dieser Patient wurde mit Ihnen geteilt von</p>

                  <div>
                    <strong class="font-semibold">
                      {{ patient.shared_owners[0]?.company ?? patient.shared_owners[0]?.name }}
                    </strong>

                    <template v-if="patient.shared_owners[0]?.company && patient.shared_owners[0]?.name">
                      ({{ patient.shared_owners[0]?.name }})
                    </template>
                  </div>

                  <div class="mt-2"><!-- no button --></div>
                </div>

                <div class="text-right text-sm text-gray-500">
                  <component-tooltip tooltip="freigegeben am">
                    <time class="whitespace-nowrap">
                      {{ patient.shared_owners[0].created_at }}
                    </time>
                  </component-tooltip>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </component-dialog>
</template>
<script>
  import {computed, ref} from "vue";
  import {router} from "@inertiajs/core";

  import ComponentCategoryBadge from "@components/Badges/CategoryBadge.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentTooltip from "@components/Tooltips/Tooltip.vue";

  export default {
    name: "PatientsPatientInformation",

    components: {ComponentDialog, ComponentIcon, ComponentCategoryBadge, ComponentTooltip},

    props: {
      patient: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const refOverallRecordStatusDialog = ref(null);
      const refShareInfoDialog = ref(null);

      const overallRecordStatusDialogTitle = ref("");
      const overallRecordStatusDialogText = ref("");

      function openOverallRecordStatusDialog() {
        switch (props.patient.overall_record_status) {
          case "serviceRecommended":
            overallRecordStatusDialogTitle.value = "Erneute Leistungserbringung möglich";
            overallRecordStatusDialogText.value = "Letzte abgeschlossene Medikationsanalyse vor über einem Jahr.";
            break;
          case "inProgress":
            overallRecordStatusDialogTitle.value = "In Bearbeitung";
            overallRecordStatusDialogText.value = "Patientenakte enthält zu bearbeitende Medikationsanalysen.";
            break;
          case "completed":
            overallRecordStatusDialogTitle.value = "Abgeschlossen";
            overallRecordStatusDialogText.value =
              "Patientenakte enthält nur abgeschlossene oder irrelevante Medikationsanalysen.";
            break;
          case "discarded":
            overallRecordStatusDialogTitle.value = "Als irrelevant markiert";
            overallRecordStatusDialogText.value =
              "Patientenakte enthält nur als irrelevant markierte Medikationsanalysen.";
            break;
        }

        refOverallRecordStatusDialog.value.open();
      }

      function openPatient(patient) {
        router.visit(route("patients.show", {patient: patient.id}));
      }

      const overallRecordStatusColor = computed(() => {
        switch (props.patient.overall_record_status) {
          case "serviceRecommended":
            return "blue";
          case "inProgress":
            return "orange";
          case "completed":
            return "green";
          case "discarded":
            return "gray";
        }
      });

      return {
        /** ref */
        refOverallRecordStatusDialog,
        refShareInfoDialog,

        /** const */
        overallRecordStatusDialogTitle,
        overallRecordStatusDialogText,
        overallRecordStatusColor,

        /** function */
        openOverallRecordStatusDialog,
        openPatient,
      };
    },
  };
</script>
