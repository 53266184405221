<template>
  <span
    class="inline-flex items-center rounded-md px-1.5 py-0.5 text-xs font-medium"
    :class="{
      // nested within a 'button' or an 'a', we change the bg color on hover
      '[button_&]:hover:bg-orange-200 [a_&]:hover:bg-orange-200 bg-orange-100 text-orange-600':
        color === 'yellow' || color === 'orange' || color === 'warning',
      '[button_&]:hover:bg-mcred-200 [a_&]:hover:bg-mcred-200 bg-mcred-100 text-mcred-600':
        color === 'red' || color === 'error',
      '[button_&]:hover:bg-mcblue-200 [a_&]:hover:bg-mcblue-200 bg-mcblue-100 text-mcblue-600':
        color === 'blue' || color === 'info',
      '[button_&]:hover:bg-mcgreen-200 [a_&]:hover:bg-mcgreen-200 bg-mcgreen-100 text-mcgreen-600':
        color === 'green' || color === 'success',
      '[button_&]:hover:bg-gray-200 [a_&]:hover:bg-gray-200 bg-gray-100 text-gray-600':
        color === 'gray' || color === null,
    }"
    :data-test="testId + '-category-badge'"
  >
    {{ label }}
    <slot />
  </span>
</template>

<script>
  export default {
    name: "ComponentCategoryBadge",

    props: {
      label: {
        type: [String, Number],
        default: null,
      },
      color: {
        type: String,
        default: null,
      },
    },
  };
</script>
