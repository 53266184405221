<template>
  <component-card
    id="patient-attributes-data"
    title="Individuelle Besonderheiten"
    subtitle="Patientenanschrift, zusätzliche Angaben, medizinische Besonderheiten"
    :class="{
      'rounded-lg border-2 border-gray-200': !withinDialog,
      '!shadow-none -m-4': withinDialog,
      '!animate-none': patient?.patient_attributes?.length,
    }"
    :test-id="testId + '-patient-attributes-data'"
  >
    <form @submit.prevent="add">
      <div class="flex justify-end items-baseline space-x-4">
        <component-select
          v-model="form.type"
          class="!w-52"
          :show-placeholder-option="false"
          :nullable="false"
          :dísabled="form.processing || form.type"
          :test-id="testId + '-patient-attributes-data-card-type'"
          @change="addWithDelay()"
        >
          <option value="">bitte auswählen</option>

          <option value="patientAddress">
            Patientenanschrift
            <template v-if="alreadyExists('patientAddress')">(bereits erfasst)</template>
          </option>

          <optgroup label="Zusätzliche Angaben">
            <option value="nursingHome">
              Heimpatient
              <template v-if="alreadyExists('nursingHome')">(bereits erfasst)</template>
            </option>

            <option value="degreeOfCare">
              Pflegestufe
              <template v-if="alreadyExists('degreeOfCare')">(bereits erfasst)</template>
            </option>

            <option value="palliativePatient">
              Palliativpatient
              <template v-if="alreadyExists('palliativePatient')">(bereits erfasst)</template>
            </option>

            <option value="mobility">
              Eingeschränkte Mobilität
              <template v-if="alreadyExists('mobility')">(bereits erfasst)</template>
            </option>
          </optgroup>

          <optgroup label="Medizinische Besonderheiten">
            <option value="tubePatient">
              Sonde
              <template v-if="alreadyExists('tubePatient')">(bereits erfasst)</template>
            </option>

            <option value="port">
              Port
              <template v-if="alreadyExists('port')">(bereits erfasst)</template>
            </option>

            <option value="insulinPump">
              Insulinpumpe
              <template v-if="alreadyExists('insulinPump')">(bereits erfasst)</template>
            </option>

            <option value="stoma">
              Stoma
              <template v-if="alreadyExists('stoma')">(bereits erfasst)</template>
            </option>
          </optgroup>
        </component-select>
      </div>
    </form>

    <ul v-if="patient?.patient_attributes?.length" class="mt-4 space-y-4">
      <li v-for="attribute in patient.patient_attributes" :id="'attribute-' + attribute.type" :key="attribute.id">
        <component
          :is="getComponentName(attribute.type)"
          :attribute="attribute"
          :patient="patient"
          :recently-added="recentlyAdded === attribute.type"
          :test-id="testId + '-patient-attributes-data'"
        />
      </li>
    </ul>

    <component-empty-state v-else :test-id="testId + '-patient-attributes-data-card-empty-state'">
      Sie können optional individuelle Besonderheiten zu diesem Patienten über das Auswahlfeld hinzufügen.
    </component-empty-state>
  </component-card>
</template>

<script>
  import {useForm} from "@inertiajs/vue3";
  import {inject, ref} from "vue";
  import {debounce} from "lodash";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentEmptyState from "@components/EmptyState/EmptyState.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentSelect from "@components/Selects/Select.vue";

  import PatientsPatientAttributesDegreeOfCareAttribute from "@pages/Patients/Components/PatientAttributes/DegreeOfCareAttribute.vue";
  import PatientsPatientAttributesMobilityAttribute from "@pages/Patients/Components/PatientAttributes/MobilityAttribute.vue";
  import PatientsPatientAttributesNursingHomeAttribute from "@pages/Patients/Components/PatientAttributes/NursingHomeAttribute.vue";
  import PatientsPatientAttributesPatientAddressAttribute from "@pages/Patients/Components/PatientAttributes/PatientAddressAttribute.vue";
  import PatientsPatientAttributesSimpleAttribute from "@pages/Patients/Components/PatientAttributes/SimpleAttribute.vue";
  import PatientsPatientAttributesTubePatientAttribute from "@pages/Patients/Components/PatientAttributes/TubePatientAttribute.vue";

  export default {
    name: "PatientsPatientAttributesData",

    components: {
      ComponentButton,
      ComponentCard,
      ComponentEmptyState,
      ComponentIcon,
      ComponentSelect,
      PatientsPatientAttributesDegreeOfCareAttribute,
      PatientsPatientAttributesMobilityAttribute,
      PatientsPatientAttributesNursingHomeAttribute,
      PatientsPatientAttributesPatientAddressAttribute,
      PatientsPatientAttributesSimpleAttribute,
      PatientsPatientAttributesTubePatientAttribute,
    },

    props: {
      patient: {
        type: Object,
        required: true,
      },
      withinDialog: {
        type: Boolean,
        default: false,
      },
    },

    expose: ["add"],

    setup(props) {
      const broadcast = inject("$broadcast");

      const recentlyAdded = ref(null);
      const form = useForm({
        type: null,
      });

      function getComponentName(type) {
        switch (type) {
          case "degreeOfCare":
            return "patients-patient-attributes-degree-of-care-attribute";
          case "mobility":
            return "patients-patient-attributes-mobility-attribute";
          case "nursingHome":
            return "patients-patient-attributes-nursing-home-attribute";
          case "patientAddress":
            return "patients-patient-attributes-patient-address-attribute";
          case "tubePatient":
            return "patients-patient-attributes-tube-patient-attribute";
          case "insulinPump":
          case "palliativePatient":
          case "port":
          case "stoma":
            return "patients-patient-attributes-simple-attribute";
        }
      }

      function alreadyExists(type) {
        return (
          props.patient.patient_attributes.filter((patient_attribute) => patient_attribute.type === type).length > 0
        );
      }

      function add(type) {
        // add() is an exposed function; in this case we allow a type parameter...
        if (typeof type !== "undefined") {
          form.type = type;
        }
        if (alreadyExists(form.type)) {
          const target = document.querySelector("#attribute-" + form.type + " section");
          if (!props.withinDialog) {
            target.scrollIntoView({behavior: "smooth", block: "center"});
          }
          setTimeout(() => {
            target.classList.add("animate-glow");
            setTimeout(() => {
              target.classList.remove("animate-glow");
            }, 2000);
          }, 200);
          form.type = null;
        } else {
          form.post(route("attributes.store", {patient: props.patient.id}), {
            preserveScroll: true,
            onSuccess: (res) => {
              setTimeout(() => {
                recentlyAdded.value = form.type;
                const target = document.querySelector("#attribute-" + form.type + " section");
                if (target) {
                  if (!props.withinDialog) {
                    target.scrollIntoView({behavior: "smooth", block: "center"});
                  }
                  setTimeout(() => {
                    target.classList.add("animate-glow");
                    setTimeout(() => {
                      target.classList.remove("animate-glow");
                    }, 2000);
                  }, 200);
                }

                form.type = null;
              }, 200);
              broadcast.patient.postMessage(props.patient.id, {action: "reload.patient"});
            },
          });
        }
      }

      const addWithDelay = debounce(() => {
        add();
      }, 500);

      return {
        /** const */
        form,
        recentlyAdded,

        /** function */
        add,
        addWithDelay,
        getComponentName,
        alreadyExists,
      };
    },
  };
</script>
