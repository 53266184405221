<template>
  <component-autocomplete
    ref="autocomplete"
    v-model="item"
    :label="label"
    :fetch-method="search"
    :fetch-pagination-method="loadNextPage"
    :pagination-links="paginationLinks"
    :clearable="clearable"
    :key-name="'Name'"
    :validation="validation"
    :helper-text="helperText"
    :placeholder="placeholder"
    :disabled="disabled"
    @cleared="handleClearedField"
    @closed="close"
    @selected="select"
  >
    <template #autocomplete-item="props">
      <div class="py-1">
        <div class="leading-tight">
          <span>{{ props.item.Name }}</span>
          <span v-if="props.item.icdcs && props.item.icdcs.length" class="text-gray-600">
            <span v-if="!props.item.Name">{{ props.item.icdcs[0].Key_ICDC }}</span>
            ({{ props.item.icdcs[0].Name }})
          </span>
        </div>
        <div class="leading-tight text-sm">
          <em v-if="props.item.Key_MIV && props.item.icdcs && props.item.icdcs.length">
            Hinweis: Die eingegebene ICD-10-codierte Erkrankung ist mit einer im MediCheck (gem. ABDATA) festgelegten
            (ähnlichen) Erkrankung verknüpft und wird durch diese ersetzt.
          </em>
          <em v-if="!props.item.Key_MIV">
            Hinweis: Diesem ICD-Code kann kein MediCheck-Code zugeordnet werden (Checks funktionieren tw. nicht). Wenn
            möglich, bitte anderen wählen.
          </em>
          <em v-if="props.item.Geschlecht === 1">männlicher Patient</em>
          <em v-else-if="props.item.Geschlecht === 2">weibliche Patientin</em>
          <em v-else-if="props.item.Geschlecht === 4">divers geschlechtliche(r) Patient(in)</em>
        </div>
      </div>
    </template>

    <template v-if="filter === 'diseases'" #autocomplete-recommendation="">
      <div v-if="recommendedCircumstances.length" class="p-3">
        <span class="text-gray-500">Ggf. passende Lebensumstände:</span>
        <ul class="flex flex-wrap">
          <li
            v-for="(recommendation, index) in recommendedCircumstances"
            :key="index"
            class="mr-2 after:content-[','] last:after:content-[]"
          >
            <a href="#" class="text-mcred" @click.prevent="select(recommendation)">{{ recommendation.Name }}</a>
          </li>
        </ul>
      </div>
    </template>

    <template #autocomplete-empty="props">
      <div v-if="props.term.length > 0" class="flex items-stretch w-96 h-12">
        <div class="w-full text-center self-center">Keine Einträge gefunden!</div>
      </div>
      <div v-else class="flex items-stretch w-96 h-12">
        <div class="w-full text-center self-center">Bitte einen Suchbegriff eingeben</div>
      </div>
    </template>
  </component-autocomplete>
</template>

<script>
  import {computed, inject, onBeforeMount, onBeforeUnmount, ref} from "vue";
  import {usePage} from "@inertiajs/vue3";

  import {coreRouter} from "@utils/coreRouter/coreRouter.js";
  import {getAge} from "@utils/Helpers/AgeCalulator.js";

  import ComponentAutocomplete from "@components/Autocomplete/Autocomplete.vue";

  import {circumstance} from "@pages/Records/Components/Sections/MedicationBar/Components/Indications/enum.js";

  export default {
    name: "RecordComponentMinCSearch",

    components: {ComponentAutocomplete},

    props: {
      value: {
        type: String,
        default: "",
      },
      label: {
        type: String,
        default: "",
      },
      rawValue: {
        type: Object,
        default: () => {
          return {};
        },
      },
      resetOnSelect: {
        type: Boolean,
        default: false,
      },
      filter: {
        type: String,
        default: null,
      },
      customStyle: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: "",
      },
      filterList: {
        type: Boolean,
        default: false,
      },
    },

    emits: ["input", "cleared"],

    setup(props, {emit}) {
      const page = usePage();

      const privacy = inject("$privacy");

      const autocomplete = ref(null);

      const isDirty = ref(false);
      const item = ref(null);
      const message = ref(null);
      const paginationLinks = ref(null);
      const rawCircumstances = ref([]);
      const patientAge = ref(null);

      const validation = computed(() => {
        if (isDirty.value || message.value) {
          return "Unbekannte Eingabe. Bitte eine Auswahl treffen.";
        } else {
          return "";
        }
      });

      const helperText = computed(() => {
        if (props.rawValue && !props.rawValue.abdata_key_miv && props.rawValue.abdata_key_icdc) {
          return "Diesem ICD-Code kann kein MediCheck-Code zugeordnet werden (Checks funktionieren tw. nicht). Wenn möglich, bitte anderen wählen.";
        }

        return "";
      });

      const recommendedCircumstances = computed(() => {
        return rawCircumstances.value.filter((mapCircumstance) => {
          // hide when already added
          if (Object.values(page.props.circumstances ?? {}).indexOf(mapCircumstance.Key_MIV) !== -1) {
            return false;
          }

          if (
            patientAge.value >= 65 &&
            patientAge.value < 75 &&
            mapCircumstance.Key_MIV === circumstance.OLDER_PATIENT
          ) {
            return true;
          } else if (patientAge.value >= 75 && mapCircumstance.Key_MIV === circumstance.GERIATRIC_PATIENT) {
            return true;
          } else if (
            [circumstance.OLDER_PATIENT, circumstance.GERIATRIC_PATIENT].indexOf(mapCircumstance.Key_MIV) !== -1
          ) {
            return false;
          }

          // male => hide all female specific circumstances
          if (
            page.props.patient.gender === "male" &&
            [
              circumstance.CLIMACTERIC,
              circumstance.POST_CLIMACTERIC,
              circumstance.CHILDBEARING_AGED_WOMAN,
              circumstance.PREGNANT_WOMAN,
              circumstance.LACTATING_WOMAN,
            ].indexOf(mapCircumstance.Key_MIV) !== -1
          ) {
            return false;
          }
          // female
          else if (page.props.patient.gender === "female") {
            // => hide all male specific circumstances
            if ([circumstance.PROCREATIVE_MAN].indexOf(mapCircumstance.Key_MIV) !== -1) {
              return false;
            }

            // when age is <= 45, hide climacteric and post climacteric
            if (
              patientAge.value <= 45 &&
              [circumstance.CLIMACTERIC, circumstance.POST_CLIMACTERIC].indexOf(mapCircumstance.Key_MIV) !== -1
            ) {
              return false;
            }
            // when age is > 45, hide childbearing aged woman, pregnant woman and lactating woman
            else if (
              patientAge.value > 45 &&
              [circumstance.CHILDBEARING_AGED_WOMAN, circumstance.PREGNANT_WOMAN, circumstance.LACTATING_WOMAN].indexOf(
                mapCircumstance.Key_MIV,
              ) !== -1
            ) {
              return false;
            }
          }

          if (page.props.circumstances === undefined) {
            return true;
          }

          // climacteric selected => hide post climacteric, childbearing aged woman, pregnant woman and lactating woman
          if (
            Object.values(page.props.circumstances).indexOf(circumstance.CLIMACTERIC) !== -1 &&
            [
              circumstance.POST_CLIMACTERIC,
              circumstance.CHILDBEARING_AGED_WOMAN,
              circumstance.PREGNANT_WOMAN,
              circumstance.LACTATING_WOMAN,
            ].indexOf(mapCircumstance.Key_MIV) !== -1
          ) {
            return false;
          }

          // post climacteric selected => hide climacteric, childbearing aged woman, pregnant woman and lactating woman
          if (
            Object.values(page.props.circumstances).indexOf(circumstance.POST_CLIMACTERIC) !== -1 &&
            [
              circumstance.CLIMACTERIC,
              circumstance.CHILDBEARING_AGED_WOMAN,
              circumstance.PREGNANT_WOMAN,
              circumstance.LACTATING_WOMAN,
            ].indexOf(mapCircumstance.Key_MIV) !== -1
          ) {
            return false;
          }

          // childbearing aged woman selected => hide climacteric and post climacteric
          if (
            Object.values(page.props.circumstances).indexOf(circumstance.CHILDBEARING_AGED_WOMAN) !== -1 &&
            [circumstance.CLIMACTERIC, circumstance.POST_CLIMACTERIC].indexOf(mapCircumstance.Key_MIV) !== -1
          ) {
            return false;
          }

          // pregnant woman selected => hide climacteric and post climacteric
          if (
            Object.values(page.props.circumstances).indexOf(circumstance.PREGNANT_WOMAN) !== -1 &&
            [circumstance.CLIMACTERIC, circumstance.POST_CLIMACTERIC].indexOf(mapCircumstance.Key_MIV) !== -1
          ) {
            return false;
          }

          return true;
        });
      });

      onBeforeMount(() => {
        privacy.decryptValue(usePage().props.patient.birthdate).then((encryptedValue) => {
          patientAge.value = getAge(encryptedValue);
        });

        item.value = props.value;

        if (props.rawValue && props.rawValue.name_icdc) {
          if (!item.value) {
            item.value = {
              Name: props.rawValue.abdata_key_icdc,
            };
          }

          item.value = item.value.Name + " (" + props.rawValue.name_icdc + ") ";
        }

        // we have to preload the circumstances and decide,
        // which of them we have to provide as autocomplete
        // recommendations depending on gender, age and
        // already selected circumstances

        if (props.value === null || (props.value === "" && props.filter === "diseases")) {
          coreRouter.loadMinCData(
            {filter: "diseases", "q[key_miv]": Object.values(circumstance)},
            {
              onSuccess: (response) => {
                rawCircumstances.value = response.data;
              },
            },
          );
        }
      });

      onBeforeUnmount(() => {
        reset();
      });

      const search = (searchTerm) => {
        let searchQuery = "?page=1";

        if (searchTerm.match(/^[A-Za-z]{1}[0-9]{2}[.\-0-9A-Z]{0,3}$/)) {
          searchQuery += "&q[key_icdc]=" + searchTerm;
        } else {
          searchQuery += "&q[name]=" + searchTerm;
        }

        if (page.props.patient.gender) {
          searchQuery += "&q[gender]=" + page.props.patient.gender;
        }

        if (props.filter) {
          searchQuery += "&filter=" + props.filter;
        }

        return new Promise((resolve) => {
          coreRouter.searchIndicationByName(searchQuery, {
            onSuccess: (response) => {
              if (props.filterList) {
                filterOptionList(response.data).then((filteredList) => {
                  resolve(filteredList);
                });
              } else {
                resolve(response.data);
              }
            },
            onError: () => {
              resolve(false);
            },
          });
        });
      };

      const filterOptionList = (list) => {
        return new Promise((resolve) => {
          const filteredList = list.filter((item) => {
            const exists = page.props[props.filter].data.some(
              (recordItem) => recordItem.abdata_key_miv === item.Key_MIV,
            );

            return !exists;
          });

          resolve(filteredList);
        });
      };

      const loadNextPage = (next) => {
        return new Promise((resolve) => {
          coreRouter.searchIndicationByName(decodeURI(next), {
            onSuccess: (response) => {
              if (!response || !response.data) return false;

              paginationLinks.value = response.links || {};
              resolve(response.data);
            },
            onError: () => {
              resolve(false);
            },
          });
        });
      };

      const select = (selectedItem) => {
        item.value = selectedItem;
        isDirty.value = false;

        if (!props.resetOnSelect && selectedItem) {
          if (item.value.indication_leads_to_disease && item.value.indication_leads_to_disease.length) {
            item.value.Name += " (" + item.value.indication_leads_to_disease[0].Name + ")";
          }
        }

        if (props.resetOnSelect) {
          reset();
        }

        emit("input", item.value);
      };

      const reset = () => {
        isDirty.value = false;
        autocomplete.value?.reset();
      };

      const close = () => {
        window.setTimeout(() => {
          if (
            item.value !== null &&
            item.value.Name &&
            item.value.Name.length > 0 &&
            item.value.Name !== props.value && // key miv name
            item.value.Name !== props.rawValue.abdata_key_icdc + " (" + props.rawValue.name_icdc + ")" // key miv name (icdc name)
          ) {
            isDirty.value = true;
          } else if (item.value === null && props.value && props.value !== "") {
            item.value = {
              Name: props.value,
            };
          }
        }, 200);
      };

      const handleClearedField = (clearedItem) => {
        emit("cleared", clearedItem);
      };

      const focus = () => {
        if (autocomplete.value) {
          autocomplete.value.focus();
        }
      };

      return {
        autocomplete,
        item,
        paginationLinks,
        validation,
        helperText,
        recommendedCircumstances,
        search,
        loadNextPage,
        select,
        close,
        handleClearedField,
        focus,
      };
    },
  };
</script>
