<template>
  <div class="flex items-center px-2 pb-4 space-x-2">
    <h5 class="text-2xl">Erkrankungen / Allergien / Lebensumstände</h5>

    <template v-if="!isEditable">
      <component-button class="p4umc-primary" label="Bearbeiten" @click="changeIsEditable" />
    </template>
  </div>

  <!-- Alert for not loadable diseases -->
  <component-alert
    v-if="
      (unloadableDiseasesUnsolved && unloadableDiseasesUnsolved.length > 0) ||
        (unloadableDiseasesSolved && unloadableDiseasesSolved.length > 0)
    "
    type="warning"
  >
    <h4 class="font-bold">
      In diesem Bereich befinden sich Erkrankungen, die in der aktualisierten Datenbank ersetzt wurden.
    </h4>

    <p class="mt-2">
      Bitte tauschen Sie folgende Erkrankungen / Allergien / Lebensumstände durch geeignete Neue aus, damit sie im Check
      erkannt und berücksichtigt werden:
    </p>

    <ul class="mt-2 ml-8 list-disc">
      <template v-if="unloadableDiseasesUnsolved?.length > 0">
        <li v-for="(item, index) in unloadableDiseasesUnsolved" :key="'unsolved-' + index">
          <em>{{ item.disease }}</em>
        </li>
      </template>

      <template v-if="unloadableDiseasesSolved?.length > 0">
        <!-- prettier-ignore -->
        <li v-for="(item, index) in unloadableDiseasesSolved" :key="'solved-' + index">
          <em>{{ item.old.disease }}</em>
          (kann ggf. ersetzt werden durch <em>{{ item.new.Name }}</em>)
        </li>
      </template>
    </ul>
  </component-alert>

  <!-- Alert Banner -->
  <component-alert v-if="circumstanceErrors && circumstanceErrors.length > 0" type="warning">
    <h4 class="font-bold">Es wurden widersprüchliche Angaben gemacht:</h4>

    <ul class="ml-8 list-disc">
      <li v-for="(circumstanceError, index) in circumstanceErrors" :key="index" v-text="circumstanceError" />
    </ul>
  </component-alert>

  <!-- Table -->
  <diseases-table :is-loading="isLoading" :record-data="recordData" :is-editable="isEditable" />

  <!-- MinC Search -->
  <div v-if="isEditable" class="flex my-5">
    <component-icon
      class="text-mcred text-2xl cursor-pointer mt-1.5"
      :class="{'!text-gray-400 !pointer-events-none': isMassUpdateProcessing}"
      @click="focusSearch"
    >
      add
    </component-icon>

    <record-component-min-c-search
      ref="refSearch"
      placeholder="Erkrankung / ICD-10 / Allergie / Lebensumstand eingeben ..."
      class="ml-1 w-96"
      filter="diseases"
      :reset-on-select="true"
      :filter-list="true"
      :disabled="isMassUpdateProcessing"
      @input="addDiseaseToRecord"
    />
  </div>
</template>

<script>
  import {computed, inject, onBeforeMount, ref} from "vue";
  import {usePage} from "@inertiajs/vue3";

  import {getAge} from "@utils/Helpers/AgeCalulator.js";

  import ComponentButton from "@components/Buttons/Button.vue";
  import ComponentAlert from "@components/Alerts/Alert.vue";
  import ComponentIcon from "@components/Icons/Icon.vue";

  import {addDisease} from "@pages/Records/Components/Sections/DiseaseBar/Utils/diseaseHelper.js";
  import {circumstance} from "@pages/Records/Components/Sections/MedicationBar/Components/Indications/enum.js";

  import RecordComponentMinCSearch from "@pages/Records/Components/MinCSearch.vue";
  import DiseasesTable from "@pages/Records/Components/Sections/DiseaseBar/Components/Table.vue";

  export default {
    name: "RecordComponentSectionDiseaseBar",

    components: {
      ComponentAlert,
      ComponentButton,
      ComponentIcon,
      DiseasesTable,
      RecordComponentMinCSearch,
    },

    props: {
      isEditable: {
        type: Boolean,
        required: true,
      },
      changeIsEditable: {
        type: Function,
        required: true,
      },
      recordData: {
        type: Object,
        required: true,
      },
      unloadableDiseasesUnsolved: {
        type: Object,
        required: true,
      },
      unloadableDiseasesSolved: {
        type: Object,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    setup() {
      const page = usePage();

      const privacy = inject("$privacy");
      const getIsMassUpdateProcessing = inject("getIsMassUpdateProcessing");

      const refSearch = ref(null);

      const patientAge = ref(null);

      const isMassUpdateProcessing = computed(() => getIsMassUpdateProcessing("disease"));

      const addDiseaseToRecord = (disease) => {
        addDisease(page.props.patient.id, page.props.record.id, disease);
      };

      const circumstanceErrors = computed(() => {
        let errors = [];

        const hasOlderPatient = page.props.diseases?.data.some(
          (disease) => disease.abdata_key_miv === circumstance.OLDER_PATIENT,
        );

        const hasGeriatricPatient = page.props.diseases?.data.some(
          (disease) => disease.abdata_key_miv === circumstance.GERIATRIC_PATIENT,
        );

        if (hasOlderPatient && (patientAge.value < 65 || patientAge.value > 75)) {
          errors.push(
            `Lebensumstand „Patient (älter, ab 65 Jahre)“ ggf. unpassend, Patient ist ${patientAge.value} Jahre alt`,
          );
        }

        if (hasGeriatricPatient && patientAge.value < 75) {
          errors.push(
            `Lebensumstand „Patient (geriatrisch, ab 75 Jahre)“ ggf. unpassend, Patient ist ${patientAge.value} Jahre alt`,
          );
        }

        if (page.props.circumstances === undefined) {
          return errors;
        }

        if (
          Object.values(page.props.circumstances).indexOf(circumstance.OLDER_PATIENT) !== -1 &&
          Object.values(page.props.circumstances).indexOf(circumstance.GERIATRIC_PATIENT) !== -1
        ) {
          errors.push("Älterer Patient und gleichzeitig geriatrischer Patient");
        }

        if (
          Object.values(page.props.circumstances).indexOf(circumstance.CLIMACTERIC) !== -1 &&
          Object.values(page.props.circumstances).indexOf(circumstance.POST_CLIMACTERIC) !== -1
        ) {
          errors.push("Klimakterium und gleichzeitig Postklimakterium");
        }

        if (
          Object.values(page.props.circumstances).indexOf(circumstance.CLIMACTERIC) !== -1 &&
          Object.values(page.props.circumstances).indexOf(circumstance.CHILDBEARING_AGED_WOMAN) !== -1
        ) {
          errors.push("Klimakterium und gleichzeitig im gebährfähigen Alter");
        }

        if (
          Object.values(page.props.circumstances).indexOf(circumstance.POST_CLIMACTERIC) !== -1 &&
          Object.values(page.props.circumstances).indexOf(circumstance.CHILDBEARING_AGED_WOMAN) !== -1
        ) {
          errors.push("Postklimakterium und gleichzeitig im gebährfähigen Alter");
        }

        if (
          Object.values(page.props.circumstances).indexOf(circumstance.CLIMACTERIC) !== -1 &&
          Object.values(page.props.circumstances).indexOf(circumstance.PREGNANT_WOMAN) !== -1
        ) {
          errors.push("Klimakterium und gleichzeitig Schwangerschaft");
        }

        if (
          Object.values(page.props.circumstances).indexOf(circumstance.POST_CLIMACTERIC) !== -1 &&
          Object.values(page.props.circumstances).indexOf(circumstance.PREGNANT_WOMAN) !== -1
        ) {
          errors.push("Postklimakterium und gleichzeitig Schwangerschaft");
        }

        if (
          Object.values(page.props.circumstances).indexOf(circumstance.CLIMACTERIC) !== -1 &&
          Object.values(page.props.circumstances).indexOf(circumstance.LACTATING_WOMAN) !== -1
        ) {
          errors.push("Klimakterium und gleichzeitig stillend");
        }

        if (
          Object.values(page.props.circumstances).indexOf(circumstance.POST_CLIMACTERIC) !== -1 &&
          Object.values(page.props.circumstances).indexOf(circumstance.LACTATING_WOMAN) !== -1
        ) {
          errors.push("Postklimakterium und gleichzeitig stillend");
        }

        return errors;
      });

      onBeforeMount(() => {
        privacy.decryptValue(page.props.patient.birthdate).then((birthDate) => {
          patientAge.value = getAge(birthDate);
        });
      });

      const focusSearch = () => {
        if (refSearch.value) {
          refSearch.value.focus();
        }
      };

      return {
        /** ref */
        refSearch,

        /** computed */
        isMassUpdateProcessing,

        /** function */
        circumstanceErrors,
        addDiseaseToRecord,
        focusSearch,
      };
    },
  };
</script>
