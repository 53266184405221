<template>
  <component-card class="relative group hover:bg-gray-100" :test-id="testId + '-simple-attribute'">
    <div class="flex items-baseline justify-between">
      <h6 class="text-xl">{{ attributeTitle }}</h6>
      <span class="text-xs text-gray-500">Hinzugefügt {{ attribute.updated_at }}</span>
    </div>

    <div class="mt-0.5">{{ attributeText }}</div>

    <div class="absolute flex right-2 bottom-2 space-x-4">
      <component-icon-button
        type="button"
        icon="delete"
        :test-id="testId + '-simple-attribute-card-delete'"
        @click="confirmRemove()"
      />
    </div>
  </component-card>

  <component-confirmation-dialog
    ref="refConfirmationDialog"
    title="Sind Sie sicher?"
    :content="`Die Angabe „${attributeTitle}“ wird gelöscht.`"
    :test-id="testId + '-simple-attribute'"
    @confirmed="remove()"
  />
</template>

<script>
  import {computed, inject, ref} from "vue";
  import {router} from "@inertiajs/vue3";

  import {simpleAttributeTitle, simpleAttributeText} from "./Enums.js";

  import ComponentCard from "@components/Cards/Card.vue";
  import ComponentIconButton from "@components/Buttons/IconButton.vue";
  import ComponentConfirmationDialog from "@components/Dialogs/ConfirmationDialog.vue";

  export default {
    name: "PatientsPatientAttributesSimpleAttribute",

    components: {
      ComponentCard,
      ComponentIconButton,
      ComponentConfirmationDialog,
    },

    props: {
      attribute: {
        type: Object,
        required: true,
      },
      patient: {
        type: Object,
        required: true,
      },
    },

    setup(props) {
      const broadcast = inject("$broadcast");

      const refConfirmationDialog = ref(null);

      const attributeTitle = computed(() => simpleAttributeTitle[props.attribute.type] ?? null);
      const attributeText = computed(() => simpleAttributeText[props.attribute.type] ?? null);

      function confirmRemove() {
        refConfirmationDialog.value.open();
      }

      function remove() {
        router.delete(route("attributes.destroy", {patient: props.patient.id, attribute: props.attribute.id}), {
          preserveScroll: true,
          onSuccess: () => {
            broadcast.patient.postMessage(props.patient.id, {action: "reload.patient"});
          },
        });
      }

      return {
        /** ref */
        refConfirmationDialog,

        /** const */
        attributeTitle,
        attributeText,

        /** function */
        confirmRemove,
        remove,
      };
    },
  };
</script>
