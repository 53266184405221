<template>
  <li class="grid grid-cols-12 gap-x-2 border-gray-300 animate-pulse py-2">
    <skeleton-loader class-names="m-1 col-span-4 h-[28px]" />
    <skeleton-loader class-names="m-1 col-span-2 h-[28px]" />
    <skeleton-loader class-names="m-1 col-span-2 h-[28px]" />
    <skeleton-loader class-names="m-1 col-span-3 h-[28px]" />
    <skeleton-loader class-names="m-1 col-span-1 h-[28px]" />
  </li>
</template>

<script>
  import SkeletonLoader from "@components/SkeletonLoader.vue";

  export default {
    name: "SymptomSkeletonRow",

    components: {SkeletonLoader: SkeletonLoader},

    setup() {
      const skeletonClass = "m-1 bg-gray-300 rounded-full";

      return {skeletonClass};
    },
  };
</script>
