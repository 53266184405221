<template>
  <div :id="stepId">
    <div
      class="ml-8"
      :class="{
        'border-l-2 border-mcred': isActive && !lastStep,
        'border-l-2 border-white': lastStep,
        'border-l-2 border-gray-200': !isActive && !lastStep,
      }"
    >
      <div class="-ml-9">
        <a
          v-if="disabledStepper"
          href="#"
          class="!no-underline !text-gray-700 relative flex items-start hover:bg-gray-100/75 -top-8 min-h-[4.5rem]"
          @click.prevent="activateStep"
        >
          <span class="h-9 flex items-center">
            <component-icon v-if="warning" class="ml-6 mt-8 text-2xl bg-white rounded-full text-red-500">
              warning
            </component-icon>

            <span
              v-else
              class="ml-6 mt-10 text-white relative w-6 h-6 flex items-center justify-center bg-gray-400 rounded-full"
            >
              {{ stepNumber }}
            </span>
          </span>

          <div class="ml-4 min-w-0 mb-4" :class="{'mt-4': isStepDescription, 'mt-6': !isStepDescription}">
            <div class="text-md text-gray-400 tracking-wide">
              {{ stepLabel }}
              <span class="text-md text-mcred">aktivieren</span>
            </div>

            <span v-if="warning" class="text-xs text-gray-500">Dieser Bereich erfordert Ihre Aufmerksamkeit</span>

            <template v-else>
              <template v-if="isDefer">
                <inertia-deferred :data="deferPropName">
                  <template #fallback>
                    <skeleton-loader class-names="h-3.5 w-[600px]" />

                    <div class="w-full text-xs bg-gray-300 animate-pulse" />
                  </template>

                  <span class="text-xs text-gray-700" v-html="stepDescription" />
                </inertia-deferred>
              </template>

              <span v-else class="text-xs text-gray-700" v-html="stepDescription" />
            </template>
          </div>
        </a>

        <a
          v-else
          href="#"
          class="!no-underline !text-gray-700 relative flex items-start hover:bg-gray-100 -top-8 min-h-[4.5rem]"
          @click.prevent="goToNextStep"
        >
          <span class="h-9 flex items-center">
            <component-icon v-if="warning" class="ml-6 mt-8 text-2xl bg-white rounded-full text-red-500">
              warning
            </component-icon>

            <span
              v-else
              :class="{'bg-mcred': isActive, 'bg-gray-400': !isActive}"
              class="ml-6 mt-10 text-white relative w-6 h-6 flex items-center justify-center rounded-full"
            >
              {{ stepNumber }}
            </span>
          </span>

          <div class="ml-4 min-w-0 mb-4" :class="{'mt-4': isStepDescription, 'mt-6': !isStepDescription}">
            <div class="text-md black-800 tracking-wide">
              <span :class="{'font-semibold': isActive}">{{ stepLabel }}</span>
              <span v-if="optionalStepper" class="text-sm text-gray-500">(optional)</span>
            </div>

            <span v-if="warning" class="text-xs text-gray-700">Dieser Bereich erfordert Ihre Aufmerksamkeit</span>
            <template v-else>
              <template v-if="isDefer">
                <inertia-deferred :data="deferPropName">
                  <template #fallback>
                    <skeleton-loader class-names="h-3.5 w-[600px]" />

                    <div class="w-full text-xs bg-gray-300 animate-pulse" />
                  </template>

                  <span class="text-xs text-gray-700" v-html="stepDescription" />
                </inertia-deferred>
              </template>

              <span v-else class="text-xs text-gray-700" v-html="stepDescription" />
            </template>
          </div>
        </a>

        <div v-if="isActive" :id="stepId + '-content'" class="ml-14 -mt-6 pb-6 pr-6">
          <slot name="content" />

          <div class="mt-5 mb-4">
            <slot name="actions" />
          </div>
        </div>
      </div>
    </div>

    <component-dialog
      ref="confirmDialog"
      dialog-content="Möchten Sie den Step aktivieren?"
      dialog-type="confirm"
      primary-label="ok"
      @primary-button-clicked="dialogConfirm"
    />
  </div>
</template>

<script>
  import {computed, ref} from "vue";
  import {Deferred as InertiaDeferred} from "@inertiajs/vue3";

  import ComponentIcon from "@components/Icons/Icon.vue";
  import ComponentDialog from "@components/Dialogs/Dialog.vue";
  import SkeletonLoader from "@components/SkeletonLoader.vue";

  export default {
    name: "ComponentStepper",

    components: {SkeletonLoader: SkeletonLoader, InertiaDeferred, ComponentDialog, ComponentIcon},

    props: {
      stepNumber: {
        type: String,
        required: true,
      },
      stepLabel: {
        type: String,
        default: null,
      },
      stepDescription: {
        type: String,
        default: null,
      },
      stepId: {
        type: String,
        default: null,
      },
      active: {
        type: String,
        default: null,
      },
      warning: {
        type: Boolean,
        default: false,
      },
      disabledStepper: {
        type: Boolean,
        default: false,
      },
      lastStep: {
        type: Boolean,
        default: false,
      },
      stepWithItsOwnConfirmDialog: {
        type: Boolean,
        default: false,
      },
      optionalStepper: {
        type: Boolean,
        default: false,
      },
      isDefer: {
        type: Boolean,
        default: false,
      },
      deferPropName: {
        type: String,
        default: "",
      },
    },

    emits: ["click", "activateStep"],

    setup(props, {emit}) {
      const confirmDialog = ref();

      const isActive = computed(() => props.active === props.stepId);
      const isStepDescription = computed(() => props.stepDescription && props.stepDescription.length > 0);

      const goToNextStep = () => {
        emit("click", props.stepId);
      };

      const activeStep = () => {
        if (this.stepWithItsOwnConfirmDialog) {
          dialogConfirm();
        } else {
          confirmDialog.value.open();
        }
      };

      const dialogConfirm = () => {
        emit("activateStep", props.stepId);
      };

      return {isActive, isStepDescription, goToNextStep, activeStep, dialogConfirm};
    },
  };
</script>
